import React from "react";
import { useSetSecurityFunctions } from "../../ToolflowAPI/security";
import { DndProvider } from "react-dnd";
import { Provider } from "react-redux";
import AuthStoreWrapper from "../../features/auth/AuthStoreWrapper";
import store from "../../stores/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { WebsocketContextComponent } from "../../ToolflowAPI/websocket/contexts/WebsocketContext";
import appTheme from "../../globalTheme/muiUtils/appTheme";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import router from "../../features/navigation/router";

export const Providers = () => {
  useSetSecurityFunctions();

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <AuthStoreWrapper>
          <WebsocketContextComponent>
            <ThemeProvider theme={appTheme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </WebsocketContextComponent>
        </AuthStoreWrapper>
      </Provider>
    </DndProvider>
  );
};
