import React from "react";
import DeleteAssetButton from "./DeleteAssetButton";
import useGetCurrentAsset from "../../../hooks/assets/useGetCurrentAsset";

const DeleteCurrentAssetButton = ({
  className = ""
}: {
  className?: string;
}) => {
  const input = useGetCurrentAsset();
  if (!input) {
    return null;
  }

  return <DeleteAssetButton assetId={input.id} className={className} />;
};

export default DeleteCurrentAssetButton;
