import React from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import { TextField } from "@mui/material";
import type { TextFieldVariants } from "@mui/material/TextField";
import type { TMarginTypes, TextFieldSizeTypes } from "@toolflow/shared";

function UpdateToolNameTextField({
  label = "Name",
  variant = "outlined",
  size = "small",
  margin = "normal",
  fullWidth = true
}: {
  label?: string;
  variant?: TextFieldVariants;
  size?: TextFieldSizeTypes;
  margin?: TMarginTypes;
  fullWidth?: boolean;
}) {
  const { state, dispatch: tDispatch } = useToolbuilderContext();
  const { currentState } = state;
  const { toolName } = currentState;

  const handleUpdateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    tDispatch({ type: "UPDATE_TOOL_NAME", toolName: e.target.value });
  };

  return (
    <TextField
      label={label}
      variant={variant}
      size={size}
      value={toolName}
      margin={margin}
      fullWidth={fullWidth}
      onChange={handleUpdateName}
    />
  );
}

export default UpdateToolNameTextField;
