import { Avatar, Box } from "@mui/material";
import React, { FC } from "react";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";
import SettingsDropdownMenuButton from "../../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import BaseSettingsDropdown from "../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import LogoutMenuItem from "./LogoutMenuItem";
import NavigateToBillingMenuItem from "./NavigateToBillingMenuItem";
import NavigateToProfileMenuItem from "./NavigateToProfileMenuItem";
import NavigateToSettingsMenuItem from "./NavigateToSettingsMenuItem";

interface IProfileDropdownMenuProps {
  size?: number;
}
const ProfileDropdownMenu: FC<IProfileDropdownMenuProps> = (props) => {
  const user = useGetCurrentUser();
  const picture = user?.picture;
  const menuItems = [
    <NavigateToProfileMenuItem key="profile" />,
    <NavigateToSettingsMenuItem key="settings" />,
    <NavigateToBillingMenuItem key="billing" />,
    <LogoutMenuItem key="logout" />
  ];
  const { size = 40 } = props;

  return (
    <Box alignItems="center" className="flex">
      <BaseSettingsDropdown>
        <SettingsDropdownMenuButton
          menuItems={menuItems}
          icon={<Avatar src={picture} sx={{ height: size, width: size }} />}
          MenuButtonProps={{
            tooltipLabel: "Account settings"
          }}
        />
      </BaseSettingsDropdown>
    </Box>
  );
};

export default ProfileDropdownMenu;
