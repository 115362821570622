import type { OpenAITextModels, TAnthropicModels } from "@toolflow/shared";

type TOpenAIPriceDictType = {
  [key in OpenAITextModels]: {
    tokenCount: number;
  };
};
type TAnthropicPriceDictType = {
  [key in TAnthropicModels]: {
    tokenCount: number;
    label: string;
  };
};

// amount is input token max count , also refered in backend in getTokens
export const openAIPriceDict: TOpenAIPriceDictType = {
  "gpt-3.5-turbo": {
    tokenCount: 16000
  },
  "gpt-4": {
    tokenCount: 8000
  },
  "gpt-4-turbo-preview": {
    tokenCount: 128000
  },
  "gpt-4o": {
    tokenCount: 128000
  }
};
export const anthropicPriceDict: TAnthropicPriceDictType = {
  "claude-3-opus-20240229": {
    tokenCount: 200000,
    label: "Claude 3 Opus"
  },
  "claude-3-sonnet-20240229": {
    tokenCount: 200000,
    label: "Claude 3 Sonnet"
  }
};
