import { AbortIcon } from "../../../../../../globalTheme/icons/icons";
import { useWebsocketContext } from "../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";
import useSendInput from "../../hooks/useSendInputAndAbort";
import useToolStillLoading from "../../hooks/useToolStillLoading";

const useRunButton = () => {
  const { sendInput, abort } = useSendInput();
  const { showReconnectButton, reconnect } = useWebsocketContext();
  const toolStillLoading = useToolStillLoading();

  if (showReconnectButton) {
    return {
      action: reconnect,
      text: "Reconnect"
    };
  }

  if (toolStillLoading) {
    return {
      action: abort,
      endIcon: <AbortIcon />,
      text: "Generating"
    };
  }

  return {
    action: sendInput,
    text: "Run"
  };
};

export default useRunButton;
