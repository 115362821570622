import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material";
import type {
  TSerpBlockAllowedOptionsValues,
  TSerpOutputAllowedValuesDict,
  TSerpResultOptions
} from "@toolflow/shared";
import React, { FC } from "react";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import useUpdateField from "../../useUpdateField";
import {
  resultOptionsLabelsAndValues,
  serpAllowedOutputLabelsAndValues
} from "./utils";

interface IModelOutputSettingsProps {
  id: string;
  allowedOutputs: TSerpOutputAllowedValuesDict;
}
const ModelOutputSettings: FC<IModelOutputSettingsProps> = (props) => {
  const {
    id,
    allowedOutputs = {
      peopleAlsoAsk: true,
      relatedQueries: true,
      organicResults: true,
      paidResults: true,
      resultOptions: true
    }
  } = props;
  const updateField = useUpdateField(id);
  const allowedOutputsKeys = Object.keys(allowedOutputs).filter(
    (output) => output !== "resultOptions"
  ) as TSerpBlockAllowedOptionsValues[];
  const allowedOutputsValuesAndLabels = allowedOutputsKeys.map((key) => ({
    value: key,
    label: serpAllowedOutputLabelsAndValues[key].label,
    checked: allowedOutputs[key]
  }));

  const resultOptions = allowedOutputs.resultOptions as TSerpResultOptions;

  const allowedResultOptions = Object.keys(resultOptions).map((option) => ({
    value: option,
    label:
      resultOptionsLabelsAndValues[option as keyof TSerpResultOptions].label,
    checked: resultOptions[option as keyof TSerpResultOptions]
  }));
  const onChangeHandler = (checked: boolean, fieldToUpdate: string) => {
    updateField(checked, fieldToUpdate);
  };

  const isAnyResultOptionChecked =
    allowedOutputs.organicResults || allowedOutputs.paidResults;
  return (
    <AccordionWrapper
      title=""
      customTitle={
        <Typography variant="body2">Model output Options</Typography>
      }
      className="width100Percent"
      startsExpanded={false}
      elevation={0}
    >
      <Grid container spacing={1}>
        {allowedOutputsValuesAndLabels.map((allowedOutput) => (
          <Grid item xs={6} key={allowedOutput.value}>
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!allowedOutput.checked}
                    onChange={(event, checked) =>
                      onChangeHandler(
                        checked,
                        `settings.allowedOutputs.${allowedOutput.value}`
                      )
                    }
                    className="capitalize nowheel nodrag nopan"
                    value={allowedOutput.value}
                  />
                }
                label={allowedOutput.label}
              />
            </FormControl>
          </Grid>
        ))}
        {isAnyResultOptionChecked && (
          <>
            <Divider
              className="margin8"
              sx={{ width: "100%" }}
              orientation="horizontal"
            />
            <Typography
              sx={{
                fontWeight: 500,
                color: (theme) => theme.palette.text.secondary
              }}
              variant="caption"
              className="margin8"
            >
              Further options for results (paid and organic)
            </Typography>
            {allowedResultOptions.map((allowedResultOption) => (
              <Grid item xs={6} key={allowedResultOption.value}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!allowedResultOption.checked}
                        onChange={(event, checked) =>
                          onChangeHandler(
                            checked,
                            `settings.allowedOutputs.resultOptions.${allowedResultOption.value}`
                          )
                        }
                        className="capitalize nowheel nodrag nopan"
                        value={allowedResultOption.value}
                      />
                    }
                    label={allowedResultOption.label}
                  />
                </FormControl>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </AccordionWrapper>
  );
};

export default ModelOutputSettings;
