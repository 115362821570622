import { ReactNode } from "react";
import { DrawerEnum } from "../store/toolBuilderSlice";
import ToolInputDrawer from "../ToolInputDrawer";
import ToolOutputDrawer from "../ToolOutputDrawer";
import { RootState } from "../../../../../stores/store";
import { useSelector } from "react-redux";
import ToolInfoContent from "./ToolInfoContent";

// Drawer component map
const drawerComponents: Record<DrawerEnum, ReactNode> = {
  [DrawerEnum.toolInfo]: <ToolInfoContent />,
  [DrawerEnum.inputs]: <ToolInputDrawer />,
  [DrawerEnum.outputs]: <ToolOutputDrawer />,
  [DrawerEnum.node]: null
  // Add other drawer types here
};

const DrawerContent = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  return drawer ? drawerComponents[drawer.type] : null;
};

export default DrawerContent;
