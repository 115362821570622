import { MoreInfoCard } from "../../../../tools/components/MoreInfoCard";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import useCloseDrawer from "../store/hooks/useCloseDrawer";
import ToolAnalyticsCard from "../ToolAnalyticsCard";

const ToolInfoContent = () => {
  const { state: tState } = useToolbuilderContext();
  const { toolId } = tState;
  const { toolName: name } = tState.currentState;
  const closeDrawer = useCloseDrawer();
  return (
    <MoreInfoCard name={name} close={closeDrawer}>
      {toolId && (
        <>
          <ToolAnalyticsCard />
        </>
      )}
    </MoreInfoCard>
  );
};

export default ToolInfoContent;
