import { useCanRunAssistantQuery } from "../../../../../../../ToolflowAPI/rtkRoutes/assistantApi";
import { useAuthStore } from "../../../../../../auth/hooks/useAuthStore";

export const useCanRunAssistant = () => {
  const { isAuthenticated } = useAuthStore();
  const { data, isFetching } = useCanRunAssistantQuery(undefined, {
    skip: !isAuthenticated
  });
  return {
    canRunAssistant: data?.result?.canRunAssistant ?? true,
    isFetching
  };
};
