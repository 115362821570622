import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const useUpdateSearchPrompt = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { disableInputs } = muiTipTapProps;
  function updateSearchPrompt(): string {
    const baseString = "Search for a";
    const options = [
      { value: "snippet" },
      { flag: disableInputs, value: "input" }
    ].filter((option) => !option.flag); // Filter out disabled options

    const optionsList = options.map((option) => option.value);

    if (optionsList.length === 0) return "Search functionality is disabled.";

    // Join with correct grammar
    const resultString =
      baseString +
      " " +
      optionsList.join(", ").replace(/, ([^,]*)$/, ", or $1");
    return resultString;
  }
  return updateSearchPrompt;
};

export default useUpdateSearchPrompt;
