import React from "react";
import PromptBlockInner from "./PromptBlockInner";
import BlockWrapper from "../BlockWrapper";
import type { PromptBlockProps } from "@toolflow/shared";
import useCurrentAndPreviousBlocksSync from "../../hooks/useCurrentAndPreviousBlocksSync";

const PromptBlock: React.FC<PromptBlockProps> = ({ data, selected, id }) => {
  useCurrentAndPreviousBlocksSync();
  return (
    <BlockWrapper id={id} data={data} selected={selected} noPadding>
      <PromptBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default PromptBlock;
