import type {
  CustomToolOutputField,
  RowUserInputDictType,
  UserInputDictType
} from "@toolflow/shared";
import type { MRT_Cell } from "material-react-table";
import CellWrapper from "../CellWrapper";
import { RenderOutputField } from "../../../../../../../../builder/toolBuilder/OutputField";
import React from "react";
import type { JSONContent } from "@tiptap/core";
import setFormState from "../functions/setFormState";
import { CELL_WIDTH } from "../sheetConstants";
import { useSheetContext } from "../../SheetContext";

const useGetToolOutputFieldColumn = () => {
  const { sheetDataRef, setSheetData } = useSheetContext();

  const getToolOutputFieldColumn = (toolOutputField: CustomToolOutputField) => {
    return {
      header: toolOutputField.name,
      minSize: CELL_WIDTH,
      size: CELL_WIDTH,
      grow: false,
      muiTableBodyCellProps: () => ({ className: "p-0px" }),
      meta: { value: toolOutputField, type: "output" },
      accessorKey: toolOutputField.name,
      Cell: ({ cell }: { cell: MRT_Cell<RowUserInputDictType> }) => {
        const formState: UserInputDictType = {};
        Object.entries(cell.row.original).map(([k, v]) => {
          if (k === toolOutputField.name) {
            formState[k] = v;
          }
        });
        return (
          <CellWrapper cell={cell}>
            <RenderOutputField
              toolOutput={formState}
              toolOutputField={toolOutputField}
              isEditable
              onChange={(e: string | JSONContent) => {
                setFormState(
                  cell.row.original.componentId,
                  toolOutputField.name,
                  e,
                  sheetDataRef,
                  setSheetData
                );
              }}
            />
          </CellWrapper>
        );
      }
    };
  };
  return getToolOutputFieldColumn;
};

export default useGetToolOutputFieldColumn;
