import { useEffect } from "react";
import { useFormContext, type FieldErrors } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../../../stores/actions";
import type { TWorkflowBuilderForm } from "@toolflow/shared";

const findFirstError = (
  errorObj: FieldErrors<TWorkflowBuilderForm>
): string | null | undefined => {
  if (!errorObj || typeof errorObj !== "object") return null;

  for (const key in errorObj) {
    if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
      const error = errorObj[key as keyof typeof errorObj];
      if (error && typeof error === "object" && "message" in error) {
        return error.message;
      }
      const nestedError = findFirstError(
        error as FieldErrors<TWorkflowBuilderForm>
      );
      if (nestedError) return nestedError;
    }
  }
  return null;
};

const useShowValidationErrors = () => {
  const dispatch = useDispatch();
  const {
    formState: { errors }
  } = useFormContext<TWorkflowBuilderForm>();
  useEffect(() => {
    if (errors) {
      const error = findFirstError(errors);
      if (error) {
        dispatch(setErrorMessage(error));
      }
    }
  }, [errors]);
};

export default useShowValidationErrors;
