import useResetToolOutput from "./useResetToolOutput";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import useValidateUserInput from "../../../../../utilities/components/textFields/workspace/hooks/useValidateUserInput";
import { useDispatch } from "react-redux";
import useHandleThrownError from "./useHandleThrownError";
import { setErrorMessage } from "../../../../../stores/actions";
import useSetResponse from "./useSetResponse";
import { useToolContext } from "../../../contexts/ToolContext";
import {
  useAbortComponentMutation,
  useRunToolMutation
} from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import useStartCheckingFile from "./useStartCheckingFile";
import useSetInputLoadingFalse from "./useSetInputLoadingFalse";
import useCurrentInputsRef from "./useCurrentInputsRef";
import useHandleWebsocketMessageByComponentId from "./useHandleWebsocketMessageByComponentId";
import { setDisableTabChange } from "../../../../pages/workstation/state/workspaceSlice";
import useSetToolStillLoading from "./useSetToolStillLoading";
import useUpdatePercentCompleted from "./useUpdatePercentCompleted";
import { clearAlert } from "../../../../layout/alertMessageSnackbar/alertMessageSlice";

const useSendInput = () => {
  const resetToolOutput = useResetToolOutput();
  const { componentId, userInput, handleClose, setTVRId } =
    useEditorToolCardContext();
  const validate = useValidateUserInput();
  const dispatch = useDispatch();

  const handleThrownError = useHandleThrownError();
  const setResponse = useSetResponse();
  const [runTool] = useRunToolMutation();
  const { tool } = useToolContext();
  const { _id: toolId, main } = tool;
  const { toolInputFields } = main;
  const { startCheckingFile, clear } = useStartCheckingFile();
  const setToolStillLoading = useSetToolStillLoading();
  const updatePercentCompleted = useUpdatePercentCompleted();

  const sendInput = async () => {
    dispatch(clearAlert()); // we need to clear the alert, because it will set update perceont 100 if there is a message
    updatePercentCompleted(0);
    resetToolOutput(null);
    setToolStillLoading();

    const { error, newUserInput } = validate(userInput, toolInputFields);
    const hasError = !!error;
    if (hasError) {
      dispatch(setErrorMessage(error));
      return;
    }

    try {
      await runTool({
        toolData: { userInput: newUserInput, componentId },
        toolId
      })
        .unwrap()
        .then((resp) => {
          // I don't think we need setToolOutputState here, messes up very quick responses like quick 3 constatn blocks
          // setToolOutputState(resp?.output);
          setResponse(resp.toolVersionResponse);
          // Start checking the file status periodically
          startCheckingFile(resp.toolVersionResponse._id);
        });
    } catch (err) {
      handleThrownError(err);
      updatePercentCompleted(100);
      dispatch(setDisableTabChange(false));
    }
  };
  const [abortComponent] = useAbortComponentMutation();
  const setInputLoadingFalse = useSetInputLoadingFalse();
  const currentInputs = useCurrentInputsRef();

  const abort = () => {
    dispatch(setDisableTabChange(false));
    abortComponent(componentId);
    setInputLoadingFalse(currentInputs.current);
    resetToolOutput(null);
    setTVRId("");
    clear();
    // we need this because otherwise the stop generating / doesn't abort properly for 0 input fields tools
    if (toolInputFields.length === 0 && handleClose) {
      handleClose();
    }
  };

  // useRunToolWhenNoInputIsRequired(sendInput);
  useHandleWebsocketMessageByComponentId(clear);

  return { sendInput, abort };
};

export default useSendInput;
