import React, { FC } from "react";
import { WORKFLOW } from "../../../utilities/constants/constants";
import NavigateToToolBuilderButton from "../builder/toolBuilder/NavigateToToolBuilderButton";
import NavigateToWorkflowBuilderButton from "../builder/workflowBuilder/NavigateToWorkflowBuilderButton";

interface INavigateToBuilderMenuItemProps {
  entityId: string;
  type: "workflow" | "tool";
}

const NavigateToBuilderMenuItem: FC<INavigateToBuilderMenuItemProps> = ({
  entityId,
  type
}) => {
  if (type === WORKFLOW) {
    return <NavigateToWorkflowBuilderButton workflowId={entityId} />;
  } else {
    return <NavigateToToolBuilderButton toolId={entityId} />;
  }
};

export default NavigateToBuilderMenuItem;
