import React from "react";
import BlockWrapper from "../BlockWrapper";
import type { DeepgramBlockProps } from "@toolflow/shared";
import DeepgramBlockInner from "./DeepgramBlockInner";
import useCurrentAndPreviousBlocksSync from "../../hooks/useCurrentAndPreviousBlocksSync";

const DeepgramBlockDrawer: React.FC<DeepgramBlockProps> = ({
  data,
  selected,
  id
}) => {
  useCurrentAndPreviousBlocksSync();
  return (
    <BlockWrapper id={id} data={data} selected={selected} noPadding>
      <DeepgramBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default DeepgramBlockDrawer;
