import React, { useCallback } from "react";
import type { Workspace } from "@toolflow/shared";
import { Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScroll } from "../../tools/components/searchToolsContainer/useInfiniteScroll";

export const SessionHistoryList = ({
  SessionElement,
  className = "",
  workspaces,
  groupedWorkspaces
}: {
  SessionElement: React.FC<{ workspace: Workspace; className?: string }>;
  className?: string;
  workspaces: Workspace[];
  groupedWorkspaces: Record<string, Workspace[]>;
}) => {
  const {
    visibleEntities: visibleWorkspaces,
    loadMoreEntities,
    hasMore,
    scrollHeight,
    scrollRef
  } = useInfiniteScroll<Workspace>(workspaces, 20);

  const renderGroup = useCallback(
    (group: string, ws: Workspace[]) => (
      <React.Fragment key={group}>
        <Typography
          variant="caption"
          className="m-t-32px m-b-8px"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {group}
        </Typography>
        {ws.map((workspace) => (
          <SessionElement
            key={workspace._id}
            workspace={workspace}
            className="m-r-8px"
          />
        ))}
      </React.Fragment>
    ),
    [SessionElement]
  );

  return (
    <div ref={scrollRef} className={className}>
      <InfiniteScroll
        dataLength={visibleWorkspaces.length}
        next={loadMoreEntities}
        hasMore={hasMore}
        loader={<div className="m-t-16px"> </div>}
        height={scrollHeight}
        scrollableTarget="main-area"
      >
        {Object.entries(groupedWorkspaces).map(([group, groupWorkspaces]) => {
          const visibleGroupWorkspaces = groupWorkspaces.filter((w) =>
            visibleWorkspaces.includes(w)
          );
          return visibleGroupWorkspaces.length > 0
            ? renderGroup(group, visibleGroupWorkspaces)
            : null;
        })}
      </InfiniteScroll>
    </div>
  );
};
