import type { TEntityTypes } from "@toolflow/shared";
import React from "react";
import { useDuplicateObject } from "../../../../utilities/hooks/useDuplicateObject";
import { WORKFLOW } from "../../../../utilities/constants/constants";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { ForkIcon } from "../../../../globalTheme/icons/icons";

const DuplicateEntity = ({
  entityId,
  entityType
}: {
  entityId: string;
  entityType: TEntityTypes;
}) => {
  const { duplicateObject } = useDuplicateObject();

  if (entityType === WORKFLOW) return null;

  const duplicateEntity = (e: React.MouseEvent) => {
    e.stopPropagation();
    duplicateObject(entityId, entityType);
  };

  return (
    <RemixButton
      text="Create your own editable copy of this template"
      icon={ForkIcon}
      onClick={duplicateEntity}
    />
  );
};

export default DuplicateEntity;
