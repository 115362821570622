import { TOOL, WORKFLOW } from "../../../../utilities/constants/constants";
import convertExternalToolFEToMarketplaceEntity from "../../../tools/functions/convertExternalToolFEToMarketplaceEntity";
import convertProfileWorkflowToMarketplaceEntity from "../../../workflow/functions/convertProfileWorkflowToMarketplaceEntity";
import { useGetSelectedEntityType } from "./useGetSelectedEntityType";
import useGetSelectedWorkspaceTool from "./useGetSelectedWorkspaceTool";
import useGetSelectedWorkspaceWorkflow from "./useGetSelectedWorkspaceWorkflow";

const useGetSelectedEntity = () => {
  const selectedWorkflow = useGetSelectedWorkspaceWorkflow();
  const selectedTool = useGetSelectedWorkspaceTool();
  const type = useGetSelectedEntityType();
  if (type === TOOL && selectedTool) {
    return convertExternalToolFEToMarketplaceEntity(selectedTool);
  }
  if (type === WORKFLOW && selectedWorkflow) {
    return convertProfileWorkflowToMarketplaceEntity(selectedWorkflow);
  }
  return null;
};

export default useGetSelectedEntity;
