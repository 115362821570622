import React from "react";

import { v4 } from "uuid";
import type { TCategory } from "@toolflow/shared";
import AssignCategories from "./AssignCategories";
import {
  useGetWorkflowQuery,
  useUpdateWorkflowMutation
} from "../../../../../ToolflowAPI/rtkRoutes/workflowApi";

const AssignCategoriesWorkflowContainer = ({
  workflowId,
  className = ""
}: {
  workflowId: string;
  className?: string;
}) => {
  const { data, isLoading } = useGetWorkflowQuery(workflowId, {
    refetchOnMountOrArgChange: true
  });
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const fetchedCategoriesTags =
    data?.workflow?.tag?.categories?.map((category) => ({
      id: v4(),
      selected: true,
      value: category
    })) || [];

  const handleSaveWorkflow = (categories: TCategory[]) => {
    updateWorkflow({
      workflowId,
      body: {
        tag: { categories, useCases: data?.workflow?.tag?.useCases || [] }
      }
    });
  };

  return (
    <AssignCategories
      disabled={isLoading}
      handleSave={handleSaveWorkflow}
      fetchedCategoriesTags={fetchedCategoriesTags}
      type="workflow"
      className={className}
    />
  );
};

export default AssignCategoriesWorkflowContainer;
