import {
  combineReducers,
  combineSlices,
  configureStore
} from "@reduxjs/toolkit";
import homeReducer, {
  HOME_REDUCER_NAME
} from "../features/pages/home/slice/homePageSlice";
import sidebarReducer, {
  SIDEBAR_REDUCER_NAME
} from "../features/layout/sidebar/sidebarSlice";
import alertMessageReducer, {
  ALERT_MESSAGE_REDUCER_NAME
} from "../features/layout/alertMessageSnackbar/alertMessageSlice";
import { auth0API } from "../infrastructure/auth0/auth0API";
import {
  rtkQueryErrorLogger,
  rtkQueryErrorNavigation
} from "./rtkQueryErrorLogger";
import authReducer, { AUTH_REDUCER_NAME } from "../features/auth/authSlice";
import { authenticatedApi } from "../ToolflowAPI/authenticatedAPI";
import { externalApi } from "../ToolflowAPI/externalAPI";
import toolInfoDrawerReducer, {
  TOOL_INFO_DRAWER_REDUCER_NAME
} from "../features/tools/components/toolInfoDrawer/toolInfoDrawerSlice";
import appWarningReducer, {
  APP_WARNING_REDUCER_NAME
} from "../features/layout/sidebar/appWarningSlice";
import workflowBuilderReducer, {
  WORKFLOW_BUILDER_REDUCER_NAME
} from "../features/pages/builder/workflowBuilder/workflowBuilderSlice";

import navigationReducer from "../features/navigation/navigationSlice";
import workspaceReducer, {
  WORKSPACE_REDUCER_NAME
} from "../features/pages/workstation/state/workspaceSlice";
import chatReducer, {
  CHAT_REDUCER_NAME
} from "../features/pages/workstation/components/chat/chatbox/chatSlice";
import searchToolsReducer, {
  SEARCH_TOOLS_REDUCER_NAME
} from "../features/tools/components/searchToolsContainer/slice/searchToolsSlice";
import knowledgeReducer, {
  KNOWLEDGE_REDUCER_NAME
} from "../features/pages/knowledge/slice/knowledgeSlice";
import snippetReducer, {
  SNIPPET_REDUCER_NAME
} from "../features/snippets/slice/snippetSlice";
import toolBuilderReducer, {
  TOOLBUILDER_REDUCER_NAME
} from "../features/pages/builder/toolBuilder/store/toolBuilderSlice";

const layoutReducer = combineSlices({
  [SIDEBAR_REDUCER_NAME]: sidebarReducer,
  [ALERT_MESSAGE_REDUCER_NAME]: alertMessageReducer,
  [APP_WARNING_REDUCER_NAME]: appWarningReducer
});

export const rootReducer = combineReducers({
  [HOME_REDUCER_NAME]: homeReducer,
  [WORKFLOW_BUILDER_REDUCER_NAME]: workflowBuilderReducer,
  [WORKSPACE_REDUCER_NAME]: workspaceReducer,
  [AUTH_REDUCER_NAME]: authReducer,
  [TOOL_INFO_DRAWER_REDUCER_NAME]: toolInfoDrawerReducer,
  [auth0API.reducerPath]: auth0API.reducer,
  [authenticatedApi.reducerPath]: authenticatedApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [CHAT_REDUCER_NAME]: chatReducer,
  [SEARCH_TOOLS_REDUCER_NAME]: searchToolsReducer,
  [KNOWLEDGE_REDUCER_NAME]: knowledgeReducer,
  [SNIPPET_REDUCER_NAME]: snippetReducer,
  [TOOLBUILDER_REDUCER_NAME]: toolBuilderReducer,
  layout: layoutReducer,
  navigation: navigationReducer
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        auth0API.middleware,
        authenticatedApi.middleware,
        externalApi.middleware,
        rtkQueryErrorLogger,
        rtkQueryErrorNavigation
      ),
    preloadedState
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export default setupStore();
