import useGetAssetId from "../../../../../hooks/assets/useGetAssetId";
import useIsLastAsset from "../../hooks/useIsLastAsset";

const useDot = (assetId: string) => {
  const selectedAssetId = useGetAssetId();
  const isLast = useIsLastAsset(assetId);
  return {
    selected: selectedAssetId === assetId,
    isLastDot: isLast
  };
};

export default useDot;
