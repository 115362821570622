import React from "react";
import BlockPaperHeader from "./BlockPaperHeader";
import BlockDrawer from "./BlockDrawer";
import type { BlockDataTypes } from "@toolflow/shared";
import { BlockContextProvider } from "../BlockContext";
import SelectedResizer from "./SelectedResizer";
import OutHandle from "./OutHandle";
import InHandle from "./InHandle";

type BlockProps = {
  children: React.ReactNode;
  data: BlockDataTypes;
  selected: boolean;
  id: string;
  noPadding?: boolean;
};

const BlockWrapper: React.FC<BlockProps> = ({
  children,
  data,
  selected,
  id,
  noPadding
}) => {
  return (
    <BlockContextProvider id={id} data={data} selected={selected}>
      <div>
        <InHandle />
        <BlockPaperHeader />
        <BlockDrawer noPadding={noPadding}>{children}</BlockDrawer>
        <OutHandle />
      </div>
      <SelectedResizer />
    </BlockContextProvider>
  );
};

export default BlockWrapper;
