import { Drawer } from "@mui/material";
import React from "react";
import {
  DEFAULT_TOOLFLOW_CONSTANTS_FE,
  useGetToolflowConstantsQuery
} from "../../../../ToolflowAPI/rtkRoutes/toolflowConstantsApi";

// For the toolbuilder
// Refactor with ClippedDrawer
function ToolbuilderDrawerToRefactor({
  open,
  large,
  noPadding,
  children
}: {
  open: boolean;
  large?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
}) {
  const {
    data: toolflowConstantData = {
      toolflowConstants: DEFAULT_TOOLFLOW_CONSTANTS_FE
    }
  } = useGetToolflowConstantsQuery();
  const toolflowConstants = toolflowConstantData.toolflowConstants;
  return (
    <Drawer
      open={open}
      anchor="right"
      hideBackdrop
      //   variant="permanent"
      disableEnforceFocus // necessary to let textfields work on input layer
      sx={{
        display: "block",
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: large ? "100%" : 500,
          transition: (theme) =>
            theme.transitions.create(["width", "right"], {
              // include "left" here
              easing: theme.transitions.easing.sharp,
              duration: !open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen
            })
        }
      }}
      PaperProps={{
        className: noPadding ? "" : "p-16px",
        variant: "outlined",
        elevation: 0,
        style: {
          top: `calc(${large ? "0px" : "var(--no-tool-header-height)"} + ${
            toolflowConstants.appWarning ? 83 : 0
          }px)`,
          position: "absolute",
          pointerEvents: "auto",
          boxShadow: "none",
          overflow: "auto",
          height: `calc(100vh - ${
            large ? "0px" : "var(--no-tool-header-height)"
          } - ${toolflowConstants.appWarning ? 83 : 0}px)`
        }
      }}
      ModalProps={{
        style: { pointerEvents: "none" },
        BackdropProps: { style: { pointerEvents: "none" } }
      }}
    >
      {children}
    </Drawer>
  );
}

export default ToolbuilderDrawerToRefactor;
