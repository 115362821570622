import React from "react";
import Box from "@mui/material/Box";
import { StyledSecondaryBuilderDrawer } from "../SyledSecondaryBuilderDrawer";
import { RootState } from "../../../../../stores/store";
import { useSelector } from "react-redux";
import DrawerContent from "./DrawerContent";
import { InputBox } from "../inputsOutputs/InputBox";
import { OutputBox } from "../inputsOutputs/OutputBox";
import DraggableBlockContainer from "../blocks/DraggableBlockContainer";
import { Block } from "@toolflow/shared";

const DIVIDER = "divider";

export default function BuilderDrawer({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  const blocks: (Block | typeof DIVIDER)[] = [
    Block.textGeneration,
    Block.imageGeneration,
    Block.speechToText,
    Block.scraper,
    DIVIDER,
    Block.serp,
    Block.logic,
    DIVIDER,
    Block.constant,
    Block.embedded
  ];

  return (
    <Box sx={{ display: "flex", position: "relative", left: "1px" }}>
      <InputBox />
      <OutputBox />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "16px",
          zIndex: 1000,
          backgroundColor: "white",
          transform: "translateY(-50%)",
          borderRadius: "16px",
          boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.06)",
          display: "inline-flex",
          padding: "10px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px"
        }}
      >
        {blocks.map((block, idx) =>
          block === DIVIDER ? (
            <div
              style={{
                borderRadius: "1px",
                background: "#E4DFD4",
                width: "100%",
                height: "1px",
                margin: "10px 0"
              }}
              key={idx}
            />
          ) : (
            <DraggableBlockContainer key={block} type={block} />
          )
        )}
      </div>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      <StyledSecondaryBuilderDrawer
        open={!!drawer}
        anchor="right"
        variant="permanent"
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute"
          },
          "& .MuiPaper-root.MuiDrawer-paper": {
            position: "absolute",
            padding: "0px",
            right: 0
          },
          "&.customDrawer > .MuiDrawer-paper": {
            borderRadius: 0
          }
        }}
      >
        <DrawerContent />
      </StyledSecondaryBuilderDrawer>
    </Box>
  );
}
