import React from "react";
import { CardHeader, IconButton, CardContent, Container } from "@mui/material";
import { CloseIcon } from "../../../globalTheme/icons/icons";

export function MoreInfoCard({
  name,
  close,
  children
}: Readonly<{
  name: string;
  close?: () => void;
  children?: React.ReactNode;
}>) {
  return (
    <Container maxWidth={"md"} disableGutters>
      <CardHeader
        title={name}
        action={
          close && (
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Container>
  );
}
