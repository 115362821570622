import React from "react";
import { Box } from "@mui/material";
import UpdateLabelTextField from "../UpdateLabelTextField";
import BlockIcon from "./BlockIcon";

function BlockDrawerHeader({
  actionNode,
  className = ""
}: {
  actionNode: React.ReactNode;
  className?: string;
}) {
  return (
    <Box className={`flex align-i-center justify-space-between ${className}`}>
      <div
        className="align-i-center flex w-100-percent"
        style={{ maxWidth: "88%" }}
      >
        <BlockIcon />
        <UpdateLabelTextField className="m-l-8px" />
      </div>
      {actionNode}
    </Box>
  );
}

export default BlockDrawerHeader;
