import React from "react";
import { useDuplicateObject } from "../../../../../../utilities/hooks/useDuplicateObject";
import { TOOL } from "../../../../../../utilities/constants/constants";
import SettingsMenuItemWithClose from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { ForkIcon } from "../../../../../../globalTheme/icons/icons";

function DuplicateToolMenuItem({ id }: { id: string }) {
  const { loading, duplicateObject } = useDuplicateObject();

  const duplicate = () => {
    duplicateObject(id, TOOL);
  };

  return (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      hotkey="Meta+D"
      text={"Duplicate tool"}
      icon={<ForkIcon fontSize="small" />}
    />
  );
}

export default DuplicateToolMenuItem;
