import { useEffect } from "react";
import type { TAvailableTiers, TUser } from "@toolflow/shared";

const useIntercom = (
  user: TUser | null,
  isAuthenticated: boolean,
  activeTier: TAvailableTiers
) => {
  const doNotLoadIntercom =
    !window.Intercom ||
    // process.env.environment === "development" ||
    !user?.email;
  useEffect(() => {
    if (doNotLoadIntercom) return; // Ensure Intercom is loaded
    try {
      if (isAuthenticated) {
        window.Intercom("boot", {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: user?.name || "Anonymous Intercom", // Full name
          email: user?.email || "", // Email address
          created_at: user?.createdAt, // Signup date as a Unix timestamp
          plan_type: activeTier
        });
      } else {
        window.Intercom("boot", {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_APP_ID
        });
      }
    } catch {
      console.log("Intercom not loaded");
    }
  }, [user?._id, isAuthenticated, user?.name, user?.email]);
};

export default useIntercom;
