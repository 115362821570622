import type { WorkflowInput } from "@toolflow/shared";
import { generateHTML } from "@tiptap/core";
import useConvertStringToTipTap from "../../../../formatters/strings/tiptap/hooks/useConvertStringToJSON";
import useExtensions from "./useExtension";

const useGetInputHtml = () => {
  const convertTextToTipTap = useConvertStringToTipTap();
  const extensions = useExtensions();
  const getInputHtml = (input: WorkflowInput | null) => {
    let jsonToConvert;
    if (!input) return "";
    if (typeof input.value === "string") {
      jsonToConvert = convertTextToTipTap(input.value);
    } else {
      jsonToConvert = input.value;
    }
    return generateHTML(jsonToConvert, extensions);
  };
  return getInputHtml;
};

export default useGetInputHtml;
