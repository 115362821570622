import { type Content } from "@tiptap/core";
import React, { FC } from "react";
import { GenericRichContent } from "../../../../../../utilities/components/textFields/workspace/GenericRichContent";
import styles from "./assistantMessage.module.css";
import threadStyles from "./threadMessage.module.css";
import ThreadActions from "./threadActions/ThreadActions";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
interface IAssistantMessageProps {
  messageContent: Content;
}

const cx = parsedClassNames.bind(threadStyles);

const AssistantMessage: FC<IAssistantMessageProps> = (props) => {
  const { messageContent } = props;
  return (
    <div className="w-100-percent pos-relative parent-hover">
      <GenericRichContent
        content={messageContent}
        className={styles["assistant-message"]}
        rerender={true}
        key="assistant-message"
      />
      <ThreadActions
        content={messageContent}
        className={cx(
          "m-t-8px pos-absolute show-on-hover",
          threadStyles["thread-actions"]
        )}
      />
    </div>
  );
};

export default AssistantMessage;
