import React, { useState } from "react";
import type { Workspace } from "@toolflow/shared";
import {
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material";
import { useAuthStore } from "../../auth/hooks/useAuthStore";
import {
  toolflowAppBackground,
  toolflowPrimaryColor
} from "../../../globalTheme/muiUtils/appTheme";
import { CloseIcon, HistoryIcon } from "../../../globalTheme/icons/icons";
import { SessionElementCard } from "./SessionElementCard";
import { SessionHistoryListContainer } from "./SessionHistoryListContainer";

export const useSessionHistoryDialog = () => {
  const [open, setOpen] = useState(false);
  const { isLoading } = useAuthStore();

  const onClickEntityWithClose = () => {
    setOpen(false);
  };

  const SessionElementWithOnClick = React.useCallback(
    ({
      workspace,
      className
    }: {
      workspace: Workspace;
      className?: string;
    }) => (
      <SessionElementCard
        workspace={workspace}
        className={className}
        onClick={onClickEntityWithClose}
      />
    ),
    [onClickEntityWithClose]
  );

  return {
    open,
    setOpen,
    SessionElementWithOnClick,
    onClickEntityWithClose,
    disabled: isLoading
  };
};

export const SessionHistoryDialogButton = ({
  button
}: {
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
}) => {
  const { open, SessionElementWithOnClick, setOpen, disabled } =
    useSessionHistoryDialog();
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        maxWidth="md"
        PaperProps={{
          className: "border-radius-16px",
          sx: { backgroundColor: toolflowAppBackground }
        }}
      >
        <div className="pos-relative">
          <IconButton
            onClick={() => setOpen(false)}
            className="pos-absolute"
            sx={{ top: 24, right: 24 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Container maxWidth="md" className="m-t-32px">
              <div className="flex align-i-center w-100-percent flex-wrap">
                <HistoryIcon
                  size={20}
                  color={toolflowPrimaryColor}
                  className="m-r-8px"
                />
                <Typography variant="h7">Recent Sessions</Typography>
              </div>
              <SessionHistoryListContainer
                SessionElement={SessionElementWithOnClick}
                className="m-t-32px"
              />
            </Container>
          </DialogContent>
        </div>
      </Dialog>
      {React.cloneElement(button as React.ReactElement, {
        onClick: (e: React.MouseEvent) => {
          setOpen(true);
          const originalOnClick = (button as React.ReactElement).props.onClick;
          if (originalOnClick) {
            originalOnClick(e);
          }
        },
        disabled
      })}
    </>
  );
};
