import { useAuth0 } from "@auth0/auth0-react";

export const useLoginBackToPage = () => {
  const { loginWithRedirect } = useAuth0();
  return {
    loginBackToPage: () => {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname }
      });
    }
  };
};
