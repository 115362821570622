import type { Snippet, ToolAutocomplete } from "@toolflow/shared";

function formatSnippetsForAutocomplete(
  snippets: Snippet[]
): ToolAutocomplete[] {
  return snippets.map((snippet) => {
    return {
      primary: snippet.name,
      id: snippet._id,
      secondary: snippet.snippetValue,
      group: "Snippet"
    };
  });
}

export default formatSnippetsForAutocomplete;
