import React from "react";
import { useThreadMessageContext } from "../../../contexts/ThreadMessageContext";
import AssistantMessage from "./AssistantMessage";
import UserMessage from "./UserMessage";
import { USER_MESSAGE } from "./utils/constants";

const ThreadMessage = () => {
  const { threadMessage } = useThreadMessageContext();
  const { role, content } = threadMessage;
  const isUserMessage = role === USER_MESSAGE;

  if (!content) return null;

  if (isUserMessage) {
    return <UserMessage messageContent={content} />;
  }
  return <AssistantMessage messageContent={content} />;
};

export default ThreadMessage;
