import { useCallback } from "react";
import { getInputNodes, getSnippetNodes } from "../../functions/getToolNodes";
import useInsertText from "./useInsertText"; // Import the new hook
import { useRichTextEditorContext } from "mui-tiptap";
import type { Content } from "@tiptap/core";

const useEditorInsertion = () => {
  const editor = useRichTextEditorContext();
  const insertText = useInsertText();

  return useCallback(
    (
      id: string,
      type: "input" | "output" | "snippet",
      renderedWithSlash?: boolean
    ) => {
      if (editor) {
        let focus = false;
        let newContent: Content = [];
        if (type === "input") {
          newContent = getInputNodes(id);
          focus = true;
        } else if (type === "snippet") {
          newContent = getSnippetNodes(id);
          focus = true;
        }
        insertText(newContent, renderedWithSlash, focus);
      }
    },
    [editor, insertText]
  );
};

export default useEditorInsertion;
