import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";
import React from "react";
import { LabelAndInfo } from "../../../../../utilities/components/typographies/LabelAndInfo";
import AddKeywordsSettings from "../../../../pages/builder/toolBuilder/settings/deepgram/AddKeywordsSettings";

export const InputTagsBase = ({
  toolInputField,
  handleChangeTags,
  tags = [],
  disableLabelAndInfo
}: {
  toolInputField: CustomToolInputField;
  tags: string[];
  disableLabelAndInfo?: boolean;
  handleChangeTags: (tags: string[]) => void;
}) => {
  return (
    <>
      {!disableLabelAndInfo && (
        <LabelAndInfo
          name={toolInputField.name}
          info={toolInputField.description}
        />
      )}
      <AddKeywordsSettings
        tags={tags}
        handleChange={handleChangeTags}
        disableHelperText={disableLabelAndInfo}
      />
    </>
  );
};
export const InputTags = ({
  toolInputField,
  setFormState,
  formState,
  disableLabelAndInfo,
  useIds
}: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const handleChangeTags = (tags: string[]) => {
    const uniqueSavedTags = [...new Set(tags)].filter(
      (tag) => !tag.includes("unsaved:")
    );
    setFormState({
      ...formState,
      [useIds ? toolInputField.id : (toolInputField.name as string)]:
        uniqueSavedTags
    });
  };
  return (
    <InputTagsBase
      toolInputField={toolInputField}
      handleChangeTags={handleChangeTags}
      tags={formState[toolInputField.name] as string[]}
      disableLabelAndInfo={disableLabelAndInfo}
    />
  );
};
