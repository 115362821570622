import type {
  CustomToolOutputFieldTypes,
  ValidatedInputTypes
} from "@toolflow/shared";
import {
  BLOCK_OUTPUT,
  CHECKBOX,
  CSV_FILE_UPLOAD,
  DOUBLE_TEXTFIELD,
  FILE_UPLOAD,
  LARGE_TEXTFIELD,
  MULTI_SELECT,
  SELECT,
  TAGS_INPUT,
  TEXTFIELD
} from "../../features/tools/components/editorToolCard/inputs/helpers/inputConstants";

const iconDict: { [K in ValidatedInputTypes]: string } = {
  [FILE_UPLOAD]: "📎",
  [SELECT]: "🔽",
  [CHECKBOX]: "✅",
  [TEXTFIELD]: "📝",
  [LARGE_TEXTFIELD]: "📝",
  [CSV_FILE_UPLOAD]: "📎",
  [MULTI_SELECT]: "🔽",
  [BLOCK_OUTPUT]: "📝",
  [TAGS_INPUT]: "️📋",
  [DOUBLE_TEXTFIELD]: "👫"
};

export const labelDict: { [K in ValidatedInputTypes]: string } = {
  [FILE_UPLOAD]: "File upload",
  [SELECT]: "Dropdown selector",
  [CHECKBOX]: "Checkbox",
  [TEXTFIELD]: "Text",
  [LARGE_TEXTFIELD]: "Text",
  [CSV_FILE_UPLOAD]: "CSV file Upload",
  [MULTI_SELECT]: "Multiselect dropdown",
  [BLOCK_OUTPUT]: "Block output",
  [TAGS_INPUT]: "List",
  [DOUBLE_TEXTFIELD]: "Text pairs"
};

export const outputIconDict: {
  [key in CustomToolOutputFieldTypes]: string;
} = {
  copyableImage: "🖼️",
  copyableLargeTextField: "📝"
};

export default iconDict;
