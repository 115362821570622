import type { TEntityTypes } from "@toolflow/shared";
import useSelectEntityCard from "../../../../entities/toolPaper/hooks/useSelectEntityCard";
import useWorkspaceId from "../../../../../hooks/useWorkspaceId";

const useFindToolButton = () => {
  const workspaceId = useWorkspaceId();
  const disableSaveSession = !workspaceId;
  const { handleSelectEntity } = useSelectEntityCard(disableSaveSession);

  const selectTool = (id: string, type: TEntityTypes) => {
    handleSelectEntity(id, type);
  };

  return {
    selectTool
  };
};

export default useFindToolButton;
