import { type Content, generateHTML } from "@tiptap/core";
import useExtensions from "../../../../components/textFields/workspace/hooks/useExtension";

const useConvertContentToHtml = () => {
  const extensions = useExtensions();
  const convertContentToHtml = (content: Content) => {
    if (!content) return "";
    return typeof content === "string"
      ? content
      : generateHTML(content, extensions);
  };
  return convertContentToHtml;
};

export default useConvertContentToHtml;
