import type { TScraperSettings } from "@toolflow/shared";

export const scraperSettingsInitialState: {
  settings: TScraperSettings;
} = {
  settings: {
    inputType: "",
    urlFieldInputKey: "",
    preferredScraperResultType: "markdown"
  }
};
