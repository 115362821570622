import React from "react";
import { SnippetIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import { type Content } from "@tiptap/core";
import { useDispatch } from "react-redux";
import useGetThreadContentAsMarkdown from "../../../utilities/formatters/strings/tiptap/hooks/useGetThreadContentAsMarkdown";
import { openSnippetFormDialog } from "../slice/snippetSlice";

const useAddSnippetFormDialogButton = (content: Content) => {
  const markdownString = useGetThreadContentAsMarkdown(content);

  const dispatch = useDispatch();

  const setOpen = () => {
    dispatch(
      openSnippetFormDialog({
        initialName: "New Snippet",
        initialValue: markdownString
      })
    );
  };

  return setOpen;
};
const AddSnippetFromContentButton = ({ content }: { content: Content }) => {
  const setOpen = useAddSnippetFormDialogButton(content);
  if (!content) return null;
  return (
    <RemixButton text="Add as snippet" icon={SnippetIcon} onClick={setOpen} />
  );
};

export default AddSnippetFromContentButton;
