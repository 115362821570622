import React, { createContext, useContext, ReactNode } from "react";
import type { WorkflowInput } from "@toolflow/shared";

interface InputContextType<T extends WorkflowInput> {
  input: T;
}

const InputContext = createContext<InputContextType<WorkflowInput> | undefined>(
  undefined
);

interface InputContextComponentProps<T extends WorkflowInput> {
  children: ReactNode;
  input?: T;
}

export function InputContextComponent<T extends WorkflowInput>({
  children,
  input
}: Readonly<InputContextComponentProps<T>>) {
  if (!input) {
    return null;
  }
  return (
    <InputContext.Provider value={{ input }}>{children}</InputContext.Provider>
  );
}

export const useInputContext = <T extends WorkflowInput>() => {
  const context = useContext(InputContext) as InputContextType<T> | undefined;
  if (context === undefined) {
    throw new Error(
      "useInputContext must be used within a InputContextComponent"
    );
  }
  return context;
};

export default InputContext;
