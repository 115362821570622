import type {
  CompatibilityOption,
  CompatibilityOptionWithOptions,
  CustomToolInputField,
  CustomToolInputFieldTypes,
  TBaseInput,
  TCSVFileUploadInput,
  TCheckboxInput,
  TDoubleTextfieldInput,
  TFileUploadInput,
  TMultiSelectInput,
  TSelectInput,
  TTagsInputInput,
  TTextFieldInput,
  ValidatedInput
} from "@toolflow/shared";
import {
  CHECKBOX,
  CSV_FILE_UPLOAD,
  DOUBLE_TEXTFIELD,
  FILE_UPLOAD,
  LARGE_TEXTFIELD,
  MULTI_SELECT,
  SELECT,
  TAGS_INPUT,
  TEXTFIELD
} from "./inputConstants";
import { v4 as uuidv4 } from "uuid";

export const firstOutputLabel = "Text Output Name";
export const firstOutputName = `${firstOutputLabel} 1`;
export const firstInputName = "User Input";

export const CUSTOM_INPUT = "custom-input";

export const ABOUT_CHARACTER_LENGTH = 140;

export const inputTypeArray: CustomToolInputFieldTypes[] = [
  CHECKBOX,
  TEXTFIELD,
  FILE_UPLOAD,
  SELECT,
  TAGS_INPUT,
  DOUBLE_TEXTFIELD
];

export const commonFieldTypes: CustomToolInputFieldTypes[] = [
  FILE_UPLOAD,
  DOUBLE_TEXTFIELD,
  TAGS_INPUT,
  CHECKBOX,
  TEXTFIELD,
  LARGE_TEXTFIELD
];

export function isInputWithOptions(
  option: CompatibilityOption | ValidatedInput | null
): option is CompatibilityOptionWithOptions {
  if (!option) return false;
  return "options" in option && Array.isArray(option.options);
}

function getDefaultInputBase<T>(
  baseObject: TBaseInput,
  oldObject: CustomToolInputField
): T {
  return {
    ...baseObject,
    name: oldObject.name,
    description: oldObject.description,
    id: uuidv4()
  } as T;
}

const DEFAULT_SELECT = {
  type: SELECT,
  options: []
};

const DEFAULT_CHECKBOX = {
  type: CHECKBOX,
  options: []
};

const DEFAULT_TEXTFIELD = {
  type: TEXTFIELD
};

const DEFAULT_CSV_FILE_UPLOAD = {
  type: CSV_FILE_UPLOAD
};

const DEFAULT_FILE_UPLOAD = {
  type: FILE_UPLOAD
};

const DEFAULT_MULTI_SELECT = {
  type: MULTI_SELECT,
  options: []
};
const DEFAULT_TAGS_INPUT = {
  type: TAGS_INPUT,
  settings: {
    tags: []
  }
};
const DEFAULT_DOUBLE_TEXTFIELD = {
  type: DOUBLE_TEXTFIELD
};

export const updateInputType = (
  type: CustomToolInputFieldTypes,
  oldField: CustomToolInputField
) => {
  function getInput<T>(baseObject: TBaseInput) {
    return getDefaultInputBase<T>(baseObject, oldField);
  }
  switch (type) {
    case SELECT:
      return getInput<TSelectInput>(DEFAULT_SELECT);
    case CHECKBOX:
      return getInput<TCheckboxInput>(DEFAULT_CHECKBOX);
    case TEXTFIELD:
      return getInput<TTextFieldInput>(DEFAULT_TEXTFIELD);
    case CSV_FILE_UPLOAD:
      return getInput<TCSVFileUploadInput>(DEFAULT_CSV_FILE_UPLOAD);
    case FILE_UPLOAD:
      return getInput<TFileUploadInput>(DEFAULT_FILE_UPLOAD);
    case MULTI_SELECT:
      return getInput<TMultiSelectInput>(DEFAULT_MULTI_SELECT);
    case TAGS_INPUT:
      return getInput<TTagsInputInput>(DEFAULT_TAGS_INPUT);
    case DOUBLE_TEXTFIELD:
      return getInput<TDoubleTextfieldInput>(DEFAULT_DOUBLE_TEXTFIELD);
    default:
      return getInput<TTextFieldInput>(DEFAULT_TEXTFIELD);
  }
};

export const CREATE_USER_INPUT_LABEL = "Create user input";
