import React from "react";
import { Icon } from "@mui/material";
import Logo from "../../../globalTheme/icons/images/openai-white-logomark.svg";

function OpenAILogo({
  className = ""
}: {
  width?: number;
  className?: string;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        background: "#6FA99B",
        borderRadius: 3,
        padding: 1,
        height: 16,
        width: 16
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        <img src={Logo} height={14} />
      </Icon>
    </div>
  );
}

export default OpenAILogo;
