import type { EditorView } from "@tiptap/pm/view";
import executeBackspace from "../../keyboardEvents/executeBackspace";
import executeEnter from "../../keyboardEvents/executeEnter";
import invariant from "tiny-invariant";
import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import type { RichTextEditorRef } from "mui-tiptap";
import useHandleKeysForBubble from "./useHandleKeysForBubble";

export function useHandleKeydown({
  overrideKeydown,
  rteRef
}: {
  overrideKeydown: ((event: KeyboardEvent, e?: Editor) => void) | undefined;
  rteRef: React.RefObject<RichTextEditorRef>;
}) {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { preventDefaultEnter, disableBubble } = muiTipTapProps;
  const handleKeysForBubble = useHandleKeysForBubble(rteRef);
  return useCallback(
    (view: EditorView, event: KeyboardEvent) => {
      if (overrideKeydown && event.key === "Enter") {
        invariant(rteRef.current?.editor);
        overrideKeydown(event, rteRef.current?.editor);
        return false;
      }
      if (event.key === "Backspace") {
        executeBackspace(view, event);
      }
      // on inputs, we want to press enter and focus outside the node,
      // we have a event handler helping to focus it in the textoutputfield
      if (preventDefaultEnter && event.key === "Enter") {
        executeEnter(view, event);
      }

      if (!disableBubble) {
        handleKeysForBubble({
          view,
          key: event.key
        });
      }
      return false;
    },
    [rteRef, overrideKeydown]
  );
}
