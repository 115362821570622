import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_TYPE_FIELD_LABEL,
  WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL
} from "../helpers/workspaceConstants";
import { v4 as uuidv4 } from "uuid";
import { WORKFLOW } from "../../../../utilities/constants/constants";

const useSetSelectedWorkspaceWorkflow = (disableSave = false) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const setSelectedWorkflow = (workflowId: string) => {
    setValue(WORKSPACE_ENTITY_ID_FIELD_LABEL, workflowId, {
      shouldDirty: !disableSave,
      shouldTouch: !disableSave,
      shouldValidate: !disableSave
    });
    setValue(WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL, uuidv4(), {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: !disableSave,
      shouldValidate: !disableSave
    });
    setValue(WORKSPACE_ENTITY_TYPE_FIELD_LABEL, WORKFLOW, {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: !disableSave,
      shouldValidate: !disableSave
    });
    setValue(
      WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL,
      {},
      {
        // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
        shouldDirty: !disableSave,
        shouldTouch: !disableSave,
        shouldValidate: !disableSave
      }
    );
  };
  return setSelectedWorkflow;
};

export default useSetSelectedWorkspaceWorkflow;
