import { useDispatch } from "react-redux";
import { DrawerEnum, setDrawer } from "../toolBuilderSlice";

const useOpenOutputs = () => {
  const dispatch = useDispatch();
  const openOutputs = () => {
    dispatch(setDrawer({ type: DrawerEnum.outputs }));
  };
  return openOutputs;
};

export default useOpenOutputs;
