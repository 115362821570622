import type {
  TSetToolOutput,
  UserInputDictType,
  WorkflowInput
} from "@toolflow/shared";
import React, { createContext, useContext, useState } from "react";
import useToolOutputState from "./hooks/useToolOutputState";
import useCurrentInputsRef from "./hooks/useCurrentInputsRef";
import useGetDefaultUI from "./hooks/useGetDefaultUI";

type TToolOutputState = UserInputDictType | null;

interface EditorToolCardContextType {
  toolOutputState: TToolOutputState;
  setToolOutputState: React.Dispatch<React.SetStateAction<TToolOutputState>>;
  currentInputs: React.MutableRefObject<WorkflowInput[]>;
  showUpgrade: boolean;
  setShowUpgrade: React.Dispatch<React.SetStateAction<boolean>>;
  userInput: UserInputDictType;
  highlightedText: string;
  componentId: string;
  tvrId: string;
  percentCompleted: number;
  toolOutput: UserInputDictType | null;
  hasLoaded: boolean;
  handleClose: () => void;
  setToolOutput: TSetToolOutput;
  setPercentCompleted: (percent: number) => void;
  setHighlightedText: (s: string) => void;
  setHasLoaded: (v: boolean) => void;
  setUserInput: (uI: UserInputDictType) => void;
  setTVRId: (id: string) => void;
  defaultUI: UserInputDictType;
  additionalActions?: React.ReactNode;
  fieldsToHide?: string[];
  hideEntityHeader?: boolean;
}

const EditorToolCardContext = createContext<
  EditorToolCardContextType | undefined
>(undefined);

export function EditorToolCardContextComponent({
  children,
  userInput,
  highlightedText = "",
  tvrId = "",
  componentId,
  percentCompleted,
  toolOutput,
  hasLoaded,
  handleClose,
  setToolOutput,
  setPercentCompleted,
  setHighlightedText,
  setHasLoaded,
  setUserInput,
  setTVRId,
  additionalActions,
  fieldsToHide = [],
  hideEntityHeader = false
}: Readonly<{
  children: React.ReactNode;
  userInput: UserInputDictType;
  highlightedText?: string;
  componentId: string;
  tvrId: string;
  percentCompleted: number;
  toolOutput: UserInputDictType | null;
  hasLoaded: boolean;
  handleClose: () => void;
  setToolOutput: TSetToolOutput;
  setPercentCompleted: (percent: number) => void;
  setHighlightedText: (s: string) => void;
  setHasLoaded: (v: boolean) => void;
  setUserInput: (uI: UserInputDictType) => void;
  setTVRId: (id: string) => void;
  additionalActions?: React.ReactNode;
  fieldsToHide?: string[];
  hideEntityHeader?: boolean;
}>) {
  const currentInputs = useCurrentInputsRef();
  const { toolOutputState, setToolOutputState } = useToolOutputState(
    toolOutput,
    setToolOutput
  );
  const [showUpgrade, setShowUpgrade] = useState(false);
  const defaultUI = useGetDefaultUI({ userInput, highlightedText });

  return (
    <EditorToolCardContext.Provider
      value={{
        toolOutputState,
        setToolOutputState,
        currentInputs,
        showUpgrade,
        setShowUpgrade,
        userInput,
        highlightedText,
        componentId,
        tvrId,
        percentCompleted,
        toolOutput,
        hasLoaded,
        handleClose,
        setToolOutput,
        setPercentCompleted,
        setHighlightedText,
        setHasLoaded,
        setUserInput,
        setTVRId,
        defaultUI,
        additionalActions,
        fieldsToHide,
        hideEntityHeader
      }}
    >
      {children}
    </EditorToolCardContext.Provider>
  );
}

export const useEditorToolCardContext = () => {
  const context = useContext(EditorToolCardContext);
  if (context === undefined) {
    throw new Error(
      "useEditorToolCardContext must be used within a EditorToolCardProvider"
    );
  }
  return context;
};

export default EditorToolCardContext;
