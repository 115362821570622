import React, { ReactNode, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@mui/material";
import type { Blocker } from "react-router-dom";
import useBlockerFunctions from "../hooks/useBlockerFunction";
import { CloseIcon } from "../../../globalTheme/icons/icons";

export type BlockerTypes = "tool" | "workspace" | "workflow";

interface ConfirmNavigationDialogBaseProps {
  blocker: Blocker;
  type: BlockerTypes;
  children: (props: {
    continueNavigating: () => void;
    resetBlocker: () => void;
  }) => ReactNode;
}

function ConfirmNavigationDialogBase({
  open,
  handleClose,
  children,
  type
}: {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
  type: BlockerTypes;
}) {
  const title = `Unsaved ${type} changes`;
  const content = `Your ${type} has unsaved changes. Would you like to save your changes before navigating?`;

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        className="flex justify-space-between align-i-center"
      >
        {title}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>{children}</DialogActions>
    </Dialog>
  );
}

export function ConfirmNavigationDialogBaseWithBlocker({
  blocker,
  children,
  type
}: ConfirmNavigationDialogBaseProps) {
  const [isOpen, setIsOpen] = useState(blocker.state === "blocked");

  const setOpen = () => {
    setIsOpen(true);
  };

  const setClose = () => {
    setIsOpen(false);
  };

  const { resetBlocker, continueNavigating } = useBlockerFunctions({
    setOpen,
    setClose,
    blocker
  });

  return (
    <ConfirmNavigationDialogBase
      open={isOpen}
      handleClose={resetBlocker}
      type={type}
    >
      {children({ continueNavigating, resetBlocker })}
    </ConfirmNavigationDialogBase>
  );
}
export default ConfirmNavigationDialogBase;
