import { useMemo } from "react";
import useFilteredInputsForAutocomplete from "./useFilteredInputsForAutocomplete";
import useAutocompleteSnippets from "./useAutocompleteSnippets";

const useFilteredOptions = () => {
  const filteredInputs = useFilteredInputsForAutocomplete();
  const autocompleteSnippets = useAutocompleteSnippets();

  return useMemo(() => {
    const options = [...filteredInputs, ...autocompleteSnippets];
    return options;
  }, [autocompleteSnippets, filteredInputs]);
};

export default useFilteredOptions;
