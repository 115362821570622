import React from "react";
import { BillingPortalCard } from "./BillingPortalCard";
import {
  HYPERLINE_CUSTOMER_ORGANIZATION,
  HYPERLINE_CUSTOMER_USER
} from "../../../../billing/helpers/billingConstants";
import useSubscriptionDict from "./hooks/useSubscriptionDict";
import { Grid, Typography } from "@mui/material";
import type { TAvailableTiers } from "@toolflow/shared";
import SubscriptionTierCard from "./SubscriptionTierCard";

const PricingSection = ({ className }: { className?: string }) => {
  const subscriptionDict = useSubscriptionDict();
  return (
    <div className={className}>
      <Typography variant="h7">Subscription</Typography>
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_ORGANIZATION} />
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_USER} />
      <Grid container columnSpacing={3} rowSpacing={2} className="m-t-12px">
        {(Object.keys(subscriptionDict) as TAvailableTiers[]).map((tier) => (
          <Grid xs={12} md={6} lg={4} item key={tier}>
            <SubscriptionTierCard tier={tier} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PricingSection;
