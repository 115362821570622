import { useEffect } from "react";
import { useBlockContext } from "../BlockContext";
import useUpdateField from "../useUpdateField";
import getDependencyArray from "./getDependencyArray";
import setBlockMessage from "../setBlockMessage";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import { type Edge } from "reactflow";

const useSetBlockMessage = () => {
  const { blockData: data, id } = useBlockContext();
  const { state } = useToolbuilderContext();
  const { blocks, edges } = state.currentState;

  const updateField = useUpdateField(id);
  const currentBlock = blocks.filter((block) => block.id === id)[0];
  /* tslint:disable-next-line */
  const dependencyArray = getDependencyArray(data, edges as Edge[]);

  useEffect(() => {
    const newBlockMessage = setBlockMessage(currentBlock, edges);
    if (newBlockMessage !== data.blockMessage) {
      updateField(newBlockMessage, "blockMessage");
    }
  }, dependencyArray);
};

export default useSetBlockMessage;
