import type { TMarketplaceEntity } from "@toolflow/shared";
import React, { FC } from "react";
import { Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScroll } from "./useInfiniteScroll";

export type TEntityElement = React.ComponentType<{
  entity: TMarketplaceEntity;
}>;

interface IElementGridProps {
  marketplaceEntities: TMarketplaceEntity[];
  EntityElement: TEntityElement;
}
const ElementGrid: FC<IElementGridProps> = ({
  marketplaceEntities,
  EntityElement
}) => {
  const {
    visibleEntities,
    loadMoreEntities,
    hasMore,
    scrollHeight,
    scrollRef
  } = useInfiniteScroll<TMarketplaceEntity>(marketplaceEntities, 20);
  return (
    <div ref={scrollRef}>
      <InfiniteScroll
        dataLength={visibleEntities.length}
        next={loadMoreEntities}
        hasMore={hasMore}
        loader={<div className="m-t-16px"> </div>}
        height={scrollHeight}
        scrollableTarget="main-area"
      >
        <Grid container spacing={3} className="m-b-32px">
          {visibleEntities.map((entity) => (
            <Grid item key={entity.id}>
              <EntityElement entity={entity} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default ElementGrid;
