import { Button } from "@mui/material";
import useRunButton from "./hooks/useRunButton";

const RunButton = () => {
  const { action, endIcon, text } = useRunButton();
  return (
    <Button
      onClick={action}
      variant="contained"
      size="small"
      endIcon={endIcon}
      disableRipple
    >
      {text}
    </Button>
  );
};

export default RunButton;
