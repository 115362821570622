import React from "react";
import RemixButton from "../../../../../../utilities/components/icons/RemixButton";
import { ResetThreadIcon } from "../../../../../../globalTheme/icons/icons";
import useResetThread from "../chatbox/hooks/useResetThread";
import useThreadMessages from "../chatbox/hooks/useThreadMessages";
import styles from "./resetThreadButton.module.css";

function ResetThreadButton() {
  const resetThread = useResetThread();
  const messages = useThreadMessages();

  if (messages.length === 0) {
    return <div className={styles["remix-height"]} />;
  }

  return <RemixButton onClick={resetThread} icon={ResetThreadIcon} />;
}

export default ResetThreadButton;
