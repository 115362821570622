import { Button } from "@mui/material";
import React from "react";
import useNagivateToNewSnippet from "../hooks/useNavigateToNewSnippet";
import { AddIcon } from "../../../../globalTheme/icons/icons";

function AddSnippetButton() {
  const navigateToNewSnippet = useNagivateToNewSnippet();
  return (
    <Button
      variant="contained"
      startIcon={<AddIcon size={18} />}
      size="small"
      onClick={navigateToNewSnippet}
    >
      Add snippet
    </Button>
  );
}

export default AddSnippetButton;
