import type { ToolAutocomplete } from "@toolflow/shared";
import { useRichTextEditorContext } from "mui-tiptap";
import useEditorInsertion from "./useEditorInsertion";
import useInsertText from "./useInsertText";
import { getHtmlFromText } from "../../../../../formatters/strings/sanitizeHtml";
import useAddNewStringAsset from "../../../../../../features/pages/workstation/hooks/assets/useAddNewStringAsset";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";

const useHandleInsertInputs = ({
  slashHasRendered
}: {
  slashHasRendered: boolean;
}) => {
  const { setShouldRenderBubble, muiTipTapProps } = useMuiTipTapContext();
  const { inputType } = muiTipTapProps;
  const editor = useRichTextEditorContext();
  const insert = useEditorInsertion();
  const insertText = useInsertText();
  const handleInsert = useAddNewStringAsset();
  const handleInsertInput = (option: ToolAutocomplete) => {
    if (!option.id || setShouldRenderBubble) {
      setShouldRenderBubble(false);
    }
    if (editor && option.id) {
      if (inputType === "chip") {
        // we want to add a chip when its a textfield
        // note, in inputs, you can't call other inputs
        insert(option.id, "input", slashHasRendered);
      } else if (inputType === "text") {
        insertText(getHtmlFromText(option.secondary), slashHasRendered, true);
      } else {
        // if its in a workspace, we want to add the actual text
        // option.secondary is html, but we can paste html directly into workspace
        // we need to get htmlfromtext in case they do it in markdown
        insert(option.id, "output", slashHasRendered);
      }
    }
  };

  const insertNewInput = () => {
    const id = handleInsert();
    insert(id, "output", slashHasRendered);
    setShouldRenderBubble(false);
  };
  return { handleInsertInput, insertNewInput };
};

export default useHandleInsertInputs;
