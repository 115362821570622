import useDeleteInput from "../useDeleteInput";
import useGetWorkspaceInputIndexById from "../useGetWorkspaceInputIndexById";
import useWorkspaceInputs from "../useWorkspaceInputs";
import useSetVisibleAsset from "../useSetVisibleAsset";

const useDeleteAsset = () => {
  const executeDelete = useDeleteInput();
  const setVisibleAsset = useSetVisibleAsset();
  const getInputIndex = useGetWorkspaceInputIndexById();
  const inputs = useWorkspaceInputs();
  const deleteAsset = (assetId: string) => {
    const currentIndex = getInputIndex(assetId);
    if (currentIndex === -1) {
      return;
    } else {
      // We queue this microtask, because execute delete is based on index
      // and if we don't have this, settingVisibleAsset will not work correctly
      queueMicrotask(() => {
        if (currentIndex === 0) {
          if (inputs.length > 1) {
            setVisibleAsset(inputs[currentIndex + 1].id);
          } else {
            setVisibleAsset("");
          }
        } else {
          setVisibleAsset(inputs[currentIndex - 1].id);
        }
      });
    }
    executeDelete(assetId);
  };
  return deleteAsset;
};

export default useDeleteAsset;
