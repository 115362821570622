import type { ThemeOptions } from "@mui/material";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    smallButton: true;
    chipLabel: true;
    h32Medium: true;
    h8: true;
    badgeLabel: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    smallButton: React.CSSProperties;
    chipLabel: React.CSSProperties;
    h32Medium: React.CSSProperties;
    h8: React.CSSProperties;
    badgeLabel: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7: React.CSSProperties;
    h32Medium: React.CSSProperties;
    smallButton: React.CSSProperties;
    chipLabel: React.CSSProperties;
    h8: React.CSSProperties;
    badgeLabel: React.CSSProperties;
  }
}

const toolflowTypography: ThemeOptions["typography"] = {
  fontFamily: "Konnect",
  badgeLabel: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    fontFeatureSettings: "'liga' off, 'clig' off"
  },
  chipLabel: {
    fontFamily: "Konnect",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
    letterSpacing: "0.16px",
    fontFeatureSettings: "'liga' off, 'clig' off"
  },
  h32Medium: {
    fontFamily: "Konnect",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%"
  },
  h8: {
    fontFamily: "Sora",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%"
  },

  h7: {
    fontFamily: "Sora",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  h6: {
    fontFamily: "Sora",
    fontWeight: 500
  },
  h5: {
    fontFamily: "Sora",
    fontWeight: 600
  },
  h4: {
    fontFamily: "Sora"
  },
  h3: {
    fontFamily: "Sora"
  },
  h2: {
    fontFamily: "Sora"
  },
  h1: {
    fontFamily: "Sora"
  },
  smallButton: {
    fontFamily: "Konnect",
    color: "var(--text-secondary, rgba(18, 23, 41, 0.60))",
    /* button/small */
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px" /* 169.231% */,
    letterSpacing: "0.46px"
  }
};

export default toolflowTypography;
