import React from "react";
import { ToolsLoading } from "../../../../utilities/components/loading/ToolflowSkeleton";
import ToolflowHeader from "../../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import ToolTabs from "./ToolTabs";
import { useAllMarketplaceEntitiesWithLoading } from "../../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";
import EntityCardContainer from "../../../entities/components/entityCard/EntityCardContainer";
import { SearchToolsContextComponent } from "./context/SearchToolsContext";

function SearchToolsLoadingSplitter({ action }: { action?: React.ReactNode }) {
  const { isLoading } = useAllMarketplaceEntitiesWithLoading();

  return (
    <ToolflowHeader title="Tools" action={action}>
      {isLoading ? (
        <ToolsLoading />
      ) : (
        <SearchToolsContextComponent EntityElement={EntityCardContainer}>
          <ToolTabs className="p-h-16px m-t-16px" />
        </SearchToolsContextComponent>
      )}
    </ToolflowHeader>
  );
}

export default SearchToolsLoadingSplitter;
