import { Drawer, styled } from "@mui/material";
import {
  CLOSED_DRAWER_WIDTH,
  SMALL_DRAWER_WIDTH
} from "../../../utilities/constants/widths";
import { sidebarClosedMixin, sidebarOpenedMixin } from "./sidebarDrawerStyles";

const StyledSidebarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ open }) => {
  return {
    width: open ? SMALL_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...sidebarOpenedMixin(),
      "& .MuiDrawer-paper": sidebarOpenedMixin()
    }),
    ...(!open && {
      ...sidebarClosedMixin(),
      "& .MuiDrawer-paper": sidebarClosedMixin()
    }),
    "& .MuiPaper-root": {
      borderRight: "1px solid #E4DFD4"
    }
  };
});

export default StyledSidebarDrawer;
