import { useDispatch } from "react-redux";
import { DrawerEnum, setDrawer } from "../toolBuilderSlice";

const useOpenInputs = () => {
  const dispatch = useDispatch();
  const openInputs = () => {
    dispatch(setDrawer({ type: DrawerEnum.inputs }));
  };
  return openInputs;
};

export default useOpenInputs;
