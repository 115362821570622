import type { UserInputResponseWithHandledError } from "@toolflow/shared";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../layout/alertMessageSnackbar/alertMessageSlice";
import { globalEventEmitterFE } from "../../../../../utilities/eventEmitter/globalEventEmitterFE";
import { isHandledChatStackError } from "../typeCheckers";

const useHandleSocketMessage = () => {
  const [output, setOutput] =
    useState<UserInputResponseWithHandledError | null>(null);
  const [loading, setLoading] = useState(false);
  const reduxDispatch = useDispatch();
  useEffect(() => {
    const handleWsMessage = (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.type === "updateToolOutput") {
        const value = Object.values(
          emittedMessage.response
        )[0] as UserInputResponseWithHandledError;
        setOutput(value);
        if (isHandledChatStackError(value)) {
          // be careful -> "aborting" is a custom error that happens when we decide to abort it ourselves -> we don't actually want to dispatch the error in that case
          // if you change the message name, change it here too
          if (value.data.message === "Aborting") {
            setOutput(null);
          } else {
            reduxDispatch(setErrorMessage(value.data.message));
          }
          setLoading(false);
        }
      }
      if (emittedMessage.type === "blockFinished") {
        setLoading(false);
      }
      if (emittedMessage.type === "error") {
        reduxDispatch(setErrorMessage(emittedMessage.data));
        setLoading(false);
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, []);
  return { output, loading, setLoading };
};

export default useHandleSocketMessage;
