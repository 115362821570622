import type { TMarketplaceEntity } from "@toolflow/shared";
import React, { FC } from "react";
import EntityCard from "./EntityCard";
import useNavigateToToolById from "../../../pages/workstation/hooks/useNavigateToToolById";
import useNavigateToWorkflowById from "../../../pages/workstation/hooks/useNavigateToWorkflowById";

interface IEntityCardProps {
  entity: TMarketplaceEntity;
}
const EntityCardContainer: FC<IEntityCardProps> = ({ entity }) => {
  const toolClick = useNavigateToToolById(entity.id);
  const workflowClick = useNavigateToWorkflowById(entity.id);
  return (
    <EntityCard
      entity={entity}
      onClick={entity.type === "tool" ? toolClick : workflowClick}
    />
  );
};

export default EntityCardContainer;
