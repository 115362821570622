import React from "react";
import SettingsDropdown from "../../../../../utilities/components/dropdowns/generic/SettingsDropdown";
import CopyToolLinkMenuItem from "../../../../tools/components/editorToolCard/menuItems/CopyToolLinkMenuItem";
import UpdateToolVisibilityMenuItem from "../../../../tools/components/editorToolCard/menuItems/UpdateToolVisibilityMenuItem";
import UpdateVersionMenuItem from "./menuItems/UpdateVersionMenuItem";
import ResetToolMenuItem from "./menuItems/ResetToolMenuItem";
import DeleteMenuItem from "../../../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import DuplicateToolMenuItemToolbuilder from "./menuItems/DuplicateToolMenuItemToolbuilder";
import AddToolCategoriesMenuItem from "./menuItems/AddToolCategoriesMenuItem";
import { MoreIcon } from "../../../../../globalTheme/icons/icons";
import { ToolbuilderMoreInfoMenuItem } from "./menuItems/ToolbuilderMoreInfoMenuItem";

function ToolBuilderHeaderSettingsDropdown() {
  const { state } = useToolbuilderContext();
  const { toolId, currentState } = state;
  const { visibility: toolVisibility, toolName } = currentState;

  const menuItems = [
    <AddToolCategoriesMenuItem
      key="addToolCategories"
      toolId={toolId}
      toolBuilder={true}
    />,
    <ToolbuilderMoreInfoMenuItem key="editMoreToolInfo" />,
    <CopyToolLinkMenuItem key="copyToolLink" toolId={toolId} />,
    <DuplicateToolMenuItemToolbuilder
      key="duplicateToo"
      id={toolId}
      useLastSaveText
    />,
    <UpdateToolVisibilityMenuItem
      key="updateToolVisibility"
      toolVisibility={toolVisibility}
      toolId={toolId}
      toolBuilder
      toolName={toolName}
    />,
    toolId ? <UpdateVersionMenuItem key="updateVersion" /> : null,
    <ResetToolMenuItem key="resetTool" />,
    <DeleteMenuItem id={toolId} shouldNavigateHome type="tool" key="delete" />
  ];

  return <SettingsDropdown menuItems={menuItems} icon={<MoreIcon />} />;
}

export default ToolBuilderHeaderSettingsDropdown;
