import { styled, TableCell, TableRow, TableSortLabel } from "@mui/material";
import { toolflowTextSecondaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import type { TableRowProps } from "@mui/material";

export const StyledTableCell = styled(TableCell)`
  fontweight: 400;
`;

interface StyledTableRowProps extends TableRowProps {
  removeLastRowBorder?: boolean;
}
export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "removeLastRowBorder"
})<StyledTableRowProps>(({ removeLastRowBorder = false }) => ({
  "&:last-of-type td, &:last-of-type th": {
    border: removeLastRowBorder ? 0 : undefined
  }
}));

export const BoldTableCell = styled(StyledTableCell)`
  font-weight: 500;
`;

export const StyledDisabledTableCell = styled(StyledTableCell)(({ theme }) => ({
  color: theme.palette.text.disabled,
  lineHeight: "8px"
}));
export const StyledHeaderCell = styled(TableCell)({
  color: toolflowTextSecondaryColor
});

export const StyledTableSortLabel = styled(TableSortLabel)({
  "&.MuiTableSortLabel-root": {
    fontSize: "13px",
    color: toolflowTextSecondaryColor,
    fontWeight: 500
  },
  "&.MuiTableSortLabel-root.Mui-active": {
    backgroundColor: "#FAF8F7",
    border: `1px solid #EEE9E4`,
    borderRadius: "8px",
    padding: "2px 8px"
  }
});
