import type {
  MarketplaceEntityAutocomplete,
  TMarketplaceEntity,
  ToolAutocomplete
} from "@toolflow/shared";
import {
  MARKETPLACE_GROUP_TOOL,
  MARKETPLACE_GROUP_WORKFLOW
} from "../constants/autocompleteConstants";

function formatEntitiesForAutocomplete(
  tools: TMarketplaceEntity[]
): ToolAutocomplete[] {
  return tools.map((ft) => {
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: (ft.type[0].toUpperCase() + ft.type.slice(1)) as
        | "Tool"
        | "Workflow",
      favoriteType: "star"
    };
  });
}

export function formatMarketplaceEntitiesForAutocomplete(
  tools: TMarketplaceEntity[]
): MarketplaceEntityAutocomplete[] {
  return tools.map((ft) => {
    let entityType = "";
    if (ft.type === "tool") {
      entityType = MARKETPLACE_GROUP_TOOL;
    } else if (ft.type === "workflow") {
      entityType = MARKETPLACE_GROUP_WORKFLOW;
    }
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: entityType as "Tools" | "Workflows" | "Use Cases",
      favoriteType: "star",
      useCases: ft?.tag?.useCases as string[]
    };
  });
}

export function formatUseCasesForAutocomplete(
  tools: { name: string; id: string; description: string }[]
): MarketplaceEntityAutocomplete[] {
  return tools.map((ft) => {
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: "Use Cases",
      favoriteType: "star",
      useCases: [ft.name]
    };
  });
}
export default formatEntitiesForAutocomplete;
