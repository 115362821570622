import InOutNodeHandle from "./inOutNodeHandle/InOutNodeHandle";
import { useBlockContext } from "../BlockContext";
import React from "react";
import { ArrowDownIcon } from "../../../../../globalTheme/icons/icons";

const InHandle = () => {
  const { id } = useBlockContext();
  return (
    <InOutNodeHandle icon={<ArrowDownIcon />} handleType="target" id={id} />
  );
};

export default InHandle;
