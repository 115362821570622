import { IconButton } from "@mui/material";
import React from "react";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { CloseIcon } from "../../../../../../globalTheme/icons/icons";

function DeleteOptionButton({ optionIndex }: { optionIndex: number }) {
  const input = useGetCurrentToolInputField();
  const { deleteOption } = useToolInputFieldsContext();
  return (
    <IconButton onClick={() => deleteOption(input, optionIndex)}>
      <CloseIcon />
    </IconButton>
  );
}

export default DeleteOptionButton;
