import type { RunToolStep, UserInputDictType } from "@toolflow/shared";
import useSetWorkflowProgress from "../components/entities/toolPaper/hooks/useSetWorkflowProgress";
import useGetWorkflowProgress from "../components/entities/toolPaper/hooks/useGetWorkflowProgress";
import { useEffect, useMemo } from "react";
import useGetToolFromQuery from "../../../tools/hooks/useGetToolFromQuery";
import useWorkspaceInputs from "./useWorkspaceInputs";
import useConvertStringToTipTap from "../../../../utilities/formatters/strings/tiptap/hooks/useConvertStringToJSON";

const useGetUserInputForWorkflow = (step: RunToolStep) => {
  const workflowProgress = useGetWorkflowProgress();
  const setWorkflowProgress = useSetWorkflowProgress(step.id);
  const tool = useGetToolFromQuery(step.settings.toolId);
  const inputs = useWorkspaceInputs();
  const convertTextToTipTap = useConvertStringToTipTap();

  const setUserInput = (uI: UserInputDictType) => {
    setWorkflowProgress({ ...workflowProgress[step.id], inputs: uI });
  };

  const defaultUserInputWithIds = useMemo(() => {
    const defaultUI: UserInputDictType = {};

    Object.entries(step.settings.mappedInputs).forEach(([key, value]) => {
      if (value.type === "mapToPreviousField" && value.previousField) {
        if (value.previousField.type === "input") {
          defaultUI[key] =
            workflowProgress[value.previousField.stepId]?.inputs?.[
              value.previousField.fieldId
            ] || "";
        } else {
          const inputToGet =
            workflowProgress[value.previousField.stepId]?.outputs?.[
              value.previousField.fieldId
            ];
          defaultUI[key] = convertTextToTipTap(
            (inputs.filter(
              (i) => i.id === inputToGet && i.valueType === "string"
            )[0]?.value as string) || ""
          );
        }
      } else if (value.type === "hardCode" && value.partialFormState) {
        defaultUI[key] = Object.values(value.partialFormState)[0]; // this probably should have a different type when saving;
      }
    });

    return defaultUI;
  }, [JSON.stringify(inputs)]);

  useEffect(() => {
    const defaultUserInput: UserInputDictType = {};
    tool?.main.toolInputFields.map((field) => {
      defaultUserInput[field.name] = defaultUserInputWithIds[field.id];
    });
    setUserInput(defaultUserInput);
  }, [defaultUserInputWithIds]);

  return {
    userInput: workflowProgress[step.id]?.inputs || {},
    setUserInput,
    fieldsToHide: Object.keys(defaultUserInputWithIds)
  };
};

export default useGetUserInputForWorkflow;
