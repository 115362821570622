import React from "react";
import Grid from "@mui/material/Grid";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import ToolInputFieldDragHandle from "./ToolInputFieldDragHandle";
import DeleteInputButton from "./DeleteInputButton";
import GridXs6 from "./GridXs6";
import ChangeInputFieldNameTextField from "./ChangeInputFieldNameTextField";
import InputTypeSelectInDraggable from "./InputTypeSelectInDraggable";
import ToolInputOptions from "./ToolInputOptions";
import ChangeInputFieldDescriptionTextField from "./ChangeInputFieldDescriptionTextField";
import DraggableToolInputFieldWrapper from "./DraggableToolInputFieldWrapper";
import {
  CSV_FILE_UPLOAD,
  MULTI_SELECT,
  SELECT
} from "../helpers/inputConstants";
import { Chip } from "@mui/material";
import { useCustomToolInputFieldContext } from "../../../../../../utilities/contexts/CustomToolInputFieldContext";
import useIsDragging from "../../../../../../utilities/components/draggables/hooks/useIsDragging";
import DraggingWrapper from "../../../../../../utilities/components/draggables/DraggingWrapper";

const DraggableToolInputFieldInner = () => {
  const { input: toolInputField } = useCustomToolInputFieldContext();
  const needsOptions = (
    [SELECT, MULTI_SELECT, CSV_FILE_UPLOAD] as CustomToolInputFieldTypes[]
  ).includes(toolInputField.type);
  const isDragging = useIsDragging();
  return (
    <DraggingWrapper
      gap="16px"
      preview={
        <Chip
          label={toolInputField.name || "Input"}
          variant="outlined"
          size="small"
        />
      }
    >
      <Grid className="m-v-16px" container spacing={3} alignItems="center">
        <Grid item xs={1}>
          <ToolInputFieldDragHandle />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <GridXs6>
              <ChangeInputFieldNameTextField disabled={isDragging} />
            </GridXs6>
            <GridXs6>
              <InputTypeSelectInDraggable disabled={isDragging} />
            </GridXs6>
            <Grid item xs={12}>
              <ChangeInputFieldDescriptionTextField disabled={isDragging} />
            </Grid>
            {needsOptions && (
              <Grid item xs={12} className="flex flex-column">
                <ToolInputOptions disabled={isDragging} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} className="flex justify-flex-end">
          {/* <div> formControls need to be wrapped in a div wihtin a grid it seems or the icon wont show */}
          <div>
            <DeleteInputButton disabled={isDragging} />
          </div>
        </Grid>
      </Grid>
    </DraggingWrapper>
  );
};

const DraggableToolInputField = ({
  toolInputField,
  index
}: {
  toolInputField: CustomToolInputField;
  index: number;
}) => {
  return (
    <DraggableToolInputFieldWrapper
      index={index}
      toolInputField={toolInputField}
    >
      <DraggableToolInputFieldInner />
    </DraggableToolInputFieldWrapper>
  );
};

export default DraggableToolInputField;
