import type { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { authenticatedApi } from "../../authenticatedAPI";
import { RootState } from "../../../stores/store";
import getInvalidateUserTag from "./getInvalidateUserTag";
import type { TInvalidatedTagType } from "@toolflow/shared";

const invalidateTags = (
  dispatch: ThunkDispatch<$TSAllowedAny, $TSAllowedAny, UnknownAction>,
  state: RootState,
  tagType: TInvalidatedTagType
) => {
  const userTag = getInvalidateUserTag(state, tagType);
  dispatch(authenticatedApi.util.invalidateTags([userTag]));
};

export default invalidateTags;
