import type {
  FavoriteEntityResponse,
  GetFavoriteEntityResponse
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import {
  FAVORITE_TAG_TYPE,
  MARKETPLACE_TAG_TYPE,
  TOOL_DETAIL_TAG_TYPE,
  TOOL_TAG_TYPE,
  WORKFLOW_TAG_TYPE
} from "../cacheTagConstants";
import { marketplaceEntityAdapter } from "../../stores/adapters/marketplaceEntityAdapter";

export type TAvailableTags =
  | typeof WORKFLOW_TAG_TYPE
  | typeof TOOL_TAG_TYPE
  | typeof FAVORITE_TAG_TYPE;
export type TInvalidateTag = (
  | typeof MARKETPLACE_TAG_TYPE
  | {
      type: TAvailableTags;
      id: string;
    }
)[];
export type FavoriteParams = {
  id: string;
  type: "tool" | "workflow";
  invalidateCacheId: TInvalidateTag;
};

export const favoritesApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileFavoriteEntities: builder.query({
      query: (profileId: string) => ({
        url: `favoriteTool/p/${profileId}`,
        method: "GET"
      }),
      providesTags: (result, error, args) => {
        const profileId = args;
        return [{ type: FAVORITE_TAG_TYPE, id: profileId }];
      },
      transformResponse: (response: GetFavoriteEntityResponse) => {
        return marketplaceEntityAdapter.upsertMany(
          marketplaceEntityAdapter.getInitialState(),
          response.entities
        );
      }
    }),
    favoriteObject: builder.mutation<FavoriteEntityResponse, FavoriteParams>({
      query: ({ id, type }) => ({
        url: `favorite/${id}`,
        method: "POST",
        body: { type }
      }),
      invalidatesTags: (result, error, args) => {
        const { invalidateCacheId, id } = args;

        return [...invalidateCacheId, { type: TOOL_DETAIL_TAG_TYPE, id: id }];
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Starred"));
        } catch (error) {
          dispatch(setErrorMessage("Unable to star"));
          console.error("Error favoriting tool", error);
        }
      }
    }),
    deleteFavoriteObject: builder.mutation<
      FavoriteEntityResponse,
      FavoriteParams
    >({
      query: ({ id, type }) => ({
        url: `favorite/${id}?type=${type}`,
        method: "DELETE"
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Unstarred"));
        } catch (error) {
          dispatch(setErrorMessage("Unable to unstar"));
          console.error("Error in deleteFavoriteTool", error);
        }
      },
      invalidatesTags: (result, error, args) => {
        const { invalidateCacheId, id } = args;
        return [...invalidateCacheId, { type: TOOL_DETAIL_TAG_TYPE, id: id }];
      }
    })
  })
});

export const favoriteToolApiEndpoints = favoritesApi.endpoints;

export const {
  useFavoriteObjectMutation,
  useDeleteFavoriteObjectMutation,
  useGetProfileFavoriteEntitiesQuery
} = favoritesApi;
