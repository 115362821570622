import { useDispatch } from "react-redux";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import type { Connection, Edge, OnConnect } from "reactflow";
import graphlib from "graphlib";
import { setErrorMessage } from "../../../../../stores/actions";
import { useCallback } from "react";

const useOnConnect = () => {
  const { reactflowUtility } = useToolbuilderContext();
  const reduxDispatch = useDispatch();
  const { setEdges } = reactflowUtility;

  // Trying to limit the functions in context for now
  // maybe should add this to context later
  const addEdge = (params: Connection | Edge, eds: Edge[]) => {
    console.log("ADDING EDGE");
    const sourceNode = params.source;
    const targetNode = params.target;

    if (!sourceNode || !targetNode) {
      return eds;
    }
    const graphToUse = new graphlib.Graph({ directed: true });
    eds.forEach((edge) => {
      graphToUse.setEdge(edge.source, edge.target);
    });

    // we set the edge, and then see if it creates a cycle
    // if it does, we remove the edge and return the existing edges
    graphToUse.setEdge(sourceNode, targetNode);

    // Use the Graph utility to check if adding the edge would create a cycle
    if (graphlib.alg.isAcyclic(graphToUse)) {
      // The edge is valid and does not exist, add it to the existing edges
      const newEdge = {
        id: `e${sourceNode}->${targetNode}`, // must include -> for updateAvailableFields
        source: sourceNode,
        target: targetNode,
        data: { deletable: true }
      };
      return eds.concat(newEdge);
    } else {
      graphToUse.removeEdge(sourceNode, targetNode);
      reduxDispatch(
        setErrorMessage(
          "Creating this connection would cause a cycle in your diagram. Please try again."
        )
      );
      // Edge would create a cycle, do not add it
      return eds; // return the existing edges without adding the new one
    }
  };

  const onConnect: OnConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) => {
        const newEdges = addEdge(connection, eds as Edge[]);

        return newEdges;
      });
    },
    [setEdges, addEdge] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return onConnect;
};

export default useOnConnect;
