import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../../stores/actions";
import { getPlainTextFromHtml } from "../../../formatters/strings/sanitizeHtml";

const useCopyFromHtml = () => {
  const reduxDispatch = useDispatch();
  const copyFromHtml = (html: string) => {
    navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Blob([getPlainTextFromHtml(html)], {
          type: "text/plain"
        }),
        "text/html": new Blob([html], { type: "text/html" })
      })
    ]);
    reduxDispatch(setSuccessMessage("Copied"));
  };
  return copyFromHtml;
};

export default useCopyFromHtml;
