import { FormHelperText } from "@mui/material";
import React from "react";
import { useCanRunAssistant } from "../hooks/useCanRunAssistant";

export function UnableToRunAssistantCTA() {
  const { canRunAssistant } = useCanRunAssistant();
  return (
    <>
      {!canRunAssistant && (
        <FormHelperText className="p-h-4px" error>
          Your message limit has been reached. Please upgrade your plan to
          continue.
        </FormHelperText>
      )}
    </>
  );
}
