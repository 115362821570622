import { Typography } from "@mui/material";
import React from "react";
import SettingsDropdownMenuButton from "../../../../../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import SettingsMenuItemWithClose from "../../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import useAddNewStringAsset from "../../../../hooks/assets/useAddNewStringAsset";
import useAddNewSheet from "../../../../hooks/assets/useAddNewSheet";
import {
  AddIcon,
  AssetIcon,
  SheetIcon
} from "../../../../../../../globalTheme/icons/icons";

const AddSheetMenuItem = () => {
  const newSheet = useAddNewSheet();
  return (
    <SettingsMenuItemWithClose
      icon={<SheetIcon size={18} />}
      text="New sheet"
      action={newSheet}
    />
  );
};

const AddAssetMenuItem = () => {
  const newInput = useAddNewStringAsset();
  return (
    <SettingsMenuItemWithClose
      icon={<AssetIcon size={18} />}
      text="New asset"
      action={newInput}
    />
  );
};

function AddAssetButton() {
  const options = [
    <AddSheetMenuItem key="newSheet" />,
    <AddAssetMenuItem key="newAsset" />
  ];
  return (
    <SettingsDropdownMenuButton
      menuItems={options}
      icon={
        <>
          <AddIcon size={14} />
          <Typography
            className="text-transform-none m-l-4px"
            variant="smallButton"
          >
            Add new asset
          </Typography>
        </>
      }
      disableToolTip
    />
  );
}

export default AddAssetButton;
