import React from "react";
import { RootState } from "../../../../stores/store";
import { useSelector, useDispatch } from "react-redux";
import { setSearchToolsTab } from "./slice/searchToolsSlice";
import { Divider, Tab, Tabs } from "@mui/material";
import type {
  TDisabledEntities,
  TMarketplaceEntity,
  TSearchToolsTabType
} from "@toolflow/shared";
import { SearchToolsContainer } from "./SearchToolsContainer";
import { useAllMarketplaceEntities } from "../../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";
import TabPanel from "../../../../utilities/components/tabs/TabPanel";
import { useOnlyUserEntities } from "../../../entities/hooks/useGetAllUserEntities";
import { useOnlyStarredEntities } from "../../../entities/hooks/useStarredEntities";
import styles from "./toolTabs.module.css";
import {
  MarketplaceFillIcon,
  MarketplaceIcon,
  StarFillIcon,
  StarIcon,
  UserFillIcon,
  UserIcon
} from "../../../../globalTheme/icons/icons";
import { a11yProps } from "../../../../utilities/components/tabs/functions/a11yProps";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import {
  MARKETPLACE_TAB,
  STARRED_TAB,
  USER_TOOLS_TAB
} from "./utils/constants";

type TTabValues = 0 | 1;
let tabId: TTabValues = 0;

export type TToolTab = {
  value: TSearchToolsTabType;
  indexValue: number;
  label: string;
  getEntities: () => TMarketplaceEntity[];
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  RemixIconRaw: React.ElementType;
};

const iconSize = 20;

const toolTabs: TToolTab[] = [
  {
    value: USER_TOOLS_TAB,
    indexValue: tabId++,
    label: "My tools",
    getEntities: useOnlyUserEntities,
    icon: <UserIcon size={iconSize} />,
    selectedIcon: <UserFillIcon size={iconSize} />,
    RemixIconRaw: UserIcon
  },
  {
    value: MARKETPLACE_TAB,
    indexValue: tabId++,
    label: "Marketplace",
    getEntities: useAllMarketplaceEntities,
    icon: <MarketplaceIcon size={iconSize} />,
    selectedIcon: <MarketplaceFillIcon size={iconSize} />,
    RemixIconRaw: MarketplaceIcon
  },
  {
    indexValue: tabId++,
    value: STARRED_TAB,
    label: "Starred",
    getEntities: useOnlyStarredEntities,
    icon: <StarIcon size={iconSize} />,
    selectedIcon: <StarFillIcon size={iconSize} />,
    RemixIconRaw: StarIcon
  }
];

const useEntityCategory = () => {
  const dispatch = useDispatch();
  const reduxTab = useSelector((state: RootState) => state.searchTools.tab);

  const getTabIndex = (category: TSearchToolsTabType) =>
    toolTabs.findIndex((tab) => tab.value === category);

  const selectedTabIndex = getTabIndex(reduxTab);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    const newCategory = toolTabs[newIndex]?.value as TSearchToolsTabType;
    if (newCategory) {
      dispatch(setSearchToolsTab(newCategory));
    }
  };

  return {
    selectedTabIndex,
    handleChangeTabIndex
  };
};

const ToolTabs = ({
  className,
  disabledEntities
}: {
  className?: string;
  disabledEntities?: TDisabledEntities;
}) => {
  const { selectedTabIndex, handleChangeTabIndex } = useEntityCategory();
  return (
    <div className={classNames("oflow-x-hidden", className)}>
      <Tabs value={selectedTabIndex} onChange={handleChangeTabIndex}>
        {toolTabs.map((tabDetails) => {
          const isTabSelected = selectedTabIndex === tabDetails.indexValue;
          return (
            <Tab
              key={tabDetails.value}
              icon={isTabSelected ? tabDetails.selectedIcon : tabDetails.icon}
              iconPosition="start"
              classes={{
                root: styles["tab-height"]
              }}
              {...a11yProps(tabDetails.indexValue)}
              label={
                <span className={isTabSelected ? styles.selected : ""}>
                  {tabDetails.label}
                </span>
              }
            />
          );
        })}
      </Tabs>
      <Divider />
      {toolTabs.map((tabDetails) => {
        return (
          <TabPanel
            value={selectedTabIndex}
            key={tabDetails.value}
            index={tabDetails.indexValue}
            className="m-t-24px"
          >
            <SearchToolsContainer
              tabDetails={toolTabs[selectedTabIndex]}
              disabledEntities={disabledEntities}
            />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default ToolTabs;
