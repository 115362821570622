import { useMemo } from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import type { ValidatedInput } from "@toolflow/shared";
import { BLOCK_OUTPUT } from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";

const useToolInputFieldsAndAvailableFields = (id: string) => {
  const { state } = useToolbuilderContext();
  const { toolInputFields, availableFields, blocks, toolOutputOptions } =
    state.currentState;

  const fieldNames: ValidatedInput[] = toolInputFields.map(
    ({ id: newId, ...rest }) => rest
  );

  // toolInputFields.forEach((tag: ValidatedInput) => {
  //   fieldNames.push(tag); // Always add the tag name

  //   // If the tag type is "fileUpload" and there are options,
  //   // split them by "," and add them to the array
  //   if (tag.type === "csvFileUpload") {
  //     const additionalFields = tag?.options || [];
  //     fieldNames = [...fieldNames, ...additionalFields];
  //   }
  // });

  const availableFieldsById: ValidatedInput[] = useMemo(() => {
    // we don't want logicBlockNodes to show up in prompts
    const logicBlockNodeLabels = blocks
      .filter((block) => block.type === "logicBlockNode")
      .map((block) => block.data.label);

    const fields = availableFields[id] || [];
    return fields
      .filter((f) => !logicBlockNodeLabels.includes(f))
      .map((f) => {
        return { name: f, type: BLOCK_OUTPUT };
      });
  }, [id, availableFields, toolOutputOptions]);

  return fieldNames.concat(availableFieldsById);
};

export default useToolInputFieldsAndAvailableFields;
