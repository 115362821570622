import type { TWorkspaceDataToSave, WorkflowInput } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_INPUTS_FIELD_LABEL } from "../../../../pages/workstation/helpers/workspaceConstants";

const useReplaceAssetArray = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const addAssetArray = (replacedArray: WorkflowInput[]) => {
    // we can't use replace, because we can't dirty it so that it doesn't update the backend
    queueMicrotask(() => {
      setValue(WORKSPACE_INPUTS_FIELD_LABEL, replacedArray, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false
      });
    });
  };
  return addAssetArray;
};

export default useReplaceAssetArray;
