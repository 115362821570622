import useRteRef from "../../../../../../../../utilities/components/textFields/workspace/hooks/useRteRef";
import {
  getInputNodes,
  getSnippetNodes
} from "../../../../../../../../utilities/components/textFields/workspace/functions/getToolNodes";

function useHandleAddChip() {
  const editorRef = useRteRef();
  const handleAddChip = (type: "input" | "snippet", id: string) => {
    const newContent =
      type === "input" ? getInputNodes(id) : getSnippetNodes(id);
    if (!editorRef.current?.editor) return;
    const editor = editorRef.current?.editor;
    console.log(newContent, editor.view.state.selection.to);
    editor.commands.insertContentAt(
      editor.view.state.selection.to,
      newContent,
      {
        parseOptions: {
          preserveWhitespace: true
        }
      }
    );
    editor.commands.focus();
  };
  return {
    handleAddChip,
    ref: editorRef
  };
}

export default useHandleAddChip;
