import React from "react";
import UtilityDragHandle from "../../../../../../utilities/components/draggables/UtilityDragHandle";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";

function ToolInputFieldDragHandle() {
  const { toolInputFields } = useToolInputFieldsContext();
  return <UtilityDragHandle disabled={toolInputFields.length <= 1} />;
}

export default ToolInputFieldDragHandle;
