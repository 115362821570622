import React, { FC } from "react";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { TPreferredScraperResult } from "@toolflow/shared";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface ISelectpreferredScraperResultTypeProps {
  settings: { preferredScraperResultType: TPreferredScraperResult };
  handleChange: (event: SelectChangeEvent<TPreferredScraperResult>) => void;
}

const SelectpreferredScraperResultType: FC<
  ISelectpreferredScraperResultTypeProps
> = (props) => {
  const scraperResultTypes: TPreferredScraperResult[] = [
    "markdown",
    "html",
    "text"
  ];
  const {
    handleChange,
    settings: { preferredScraperResultType }
  } = props;

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id="scraper-reult-type-label">
        Preferred Result Type
      </InputLabel>
      <Select
        labelId="scraper-reult-type-label"
        id="preferredScraperResultType"
        value={preferredScraperResultType}
        fullWidth
        label="Preferred Result Type"
        onChange={handleChange}
        className="capitalize nowheel nodrag nopan"
      >
        {scraperResultTypes.map((option) => (
          <MenuItem value={option} key={option} className="capitalize">
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectpreferredScraperResultType;
