import { Typography } from "@mui/material";
import React from "react";
import ClickableChip from "../../../../../../../../utilities/components/chips/ClickableChip";
import type { ToolAutocomplete } from "@toolflow/shared";

export const ChipGroup = ({
  name,
  entities,
  type,
  onClickChip
}: {
  name: string;
  entities: ToolAutocomplete[];
  type: "input" | "snippet";
  onClickChip: (type: "input" | "snippet", id: string) => void;
}) => {
  return (
    <>
      <Typography variant="caption" className="p-t-8px capitalize">
        {name}
      </Typography>
      {entities.map((entity) => (
        <ClickableChip
          key={entity.id}
          text={entity.primary}
          type={type}
          onClick={onClickChip}
          id={entity.id}
        />
      ))}
    </>
  );
};
