// workflowSlice.ts
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../stores/store";

interface ISidebarInitialState {
  open: boolean;
  workflowVersionId?: string;
  stepId?: string;
}

const initialState: ISidebarInitialState = {
  open: false,
  workflowVersionId: undefined,
  stepId: undefined
};

export type TSidebarReducerName = "workflowBuilder";
export const WORKFLOW_BUILDER_REDUCER_NAME: TSidebarReducerName =
  "workflowBuilder";

const workflowSlice = createSlice({
  name: WORKFLOW_BUILDER_REDUCER_NAME,
  initialState,
  reducers: {
    openWorkflowSaveDialog(state) {
      state.open = true;
    },
    closeWorkflowSaveDialog(state) {
      state.open = false;
    },
    updateWorkflowVersionId(state, action: PayloadAction<string | undefined>) {
      state.workflowVersionId = action.payload;
    },
    updateStepId(state, action: PayloadAction<string | undefined>) {
      state.stepId = action.payload;
    }
  }
});

export const selectWorkflowSaveDialogOpen = (state: RootState) =>
  state[WORKFLOW_BUILDER_REDUCER_NAME].open;

export const selectWorkflowVersionId = (state: RootState) =>
  state[WORKFLOW_BUILDER_REDUCER_NAME].workflowVersionId;

export const selectStepId = (state: RootState) =>
  state[WORKFLOW_BUILDER_REDUCER_NAME].stepId || "";

export const {
  openWorkflowSaveDialog,
  closeWorkflowSaveDialog,
  updateWorkflowVersionId,
  updateStepId
} = workflowSlice.actions;

export default workflowSlice.reducer;
