import useHandleRunAssistant from "../../hooks/useHandleRunAssistant";
import useThreadRef from "../../hooks/useThreadRef";
import type { JSONContent } from "@tiptap/core";
import useSendChatDisabled from "./useSendChatDisabled";

const useSendChatMessageButton = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const handleRunAssistant = useHandleRunAssistant(setUserTextMessage);
  const disabled = useSendChatDisabled();

  const currentThreadIdRef = useThreadRef();

  return {
    disabled,
    currentThreadIdRef,
    handleRunAssistant
  };
};

export default useSendChatMessageButton;
