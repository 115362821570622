import type { TWorkspaceDataToSave, Workspace } from "@toolflow/shared";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { WorkspaceInputsFormProvider } from "../../contexts/WorkspaceInputsContext";
import getDefaultValues from "../../functions/getDefaultValues";
import useDefaultWorkspaceFormValues from "../../hooks/useDefaultWorkspaceFormValues";
import useGetDefaultWorkspaceFields from "../../hooks/useGetDefaultWorkspaceFields";
import useReset from "../../hooks/useReset";
import useSetDefaultValues from "../../hooks/useSetDefaultValues";
import {
  CHAT_TAB_BASE,
  DEFAULT_WORKSPACE,
  INPUT_SOURCES,
  INPUT_TYPES,
  TOOL_TAB_BASE,
  WORKFLOW_USE_BASE,
  WORKSPACE_ABOUT_FIELD_LABEL,
  WORKSPACE_ENTITY_BASE,
  WORKSPACE_ID_FIELD_LABEL,
  WORKSPACE_INPUTS_FIELD_LABEL,
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_VISIBILITY_FIELD_LABEL,
  WORKSPACE_VISIBLE_ASSET_ID
} from "../../helpers/workspaceConstants";
import { useKeepFormWorkspaceIdInSync } from "../../hooks/useKeepFormWorkspaceIdInSync";
import { useResetWorspaceOnUnmount } from "../../hooks/useResetWorspaceOnUnmount";
import { TOOL, WORKFLOW } from "../../../../../utilities/constants/constants";

const WorkflowInputZodSchema = z.object({
  id: z.string(),
  name: z.string(),
  source: z.enum(INPUT_SOURCES),
  sourceIdentifier: z.string().optional(),
  loading: z.boolean().optional(),
  valueType: z.enum(INPUT_TYPES).optional(),
  data: z
    .object({
      toolId: z.string().optional(),
      tvrId: z.string().optional(),
      creatorId: z.string().optional(),
      userInput: z.record(z.string(), z.any()).optional(),
      outputId: z.string().optional()
    })
    .optional()
});

const EntityTypeSchema = z.object({
  entityId: z.string(),
  componentId: z.string(),
  type: z.enum([WORKFLOW, TOOL])
});

const schema = z.object({
  [WORKSPACE_ENTITY_BASE]: EntityTypeSchema,
  [WORKSPACE_VISIBLE_ASSET_ID]: z.string(),
  [WORKSPACE_INPUTS_FIELD_LABEL]: z.array(WorkflowInputZodSchema),
  [WORKSPACE_ID_FIELD_LABEL]: z.string(),
  [WORKSPACE_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: "Workspace name is required" }),
  [WORKSPACE_ABOUT_FIELD_LABEL]: z.string().min(0),
  [WORKSPACE_VISIBILITY_FIELD_LABEL]: z.enum([
    "public",
    "private",
    "restricted"
  ]),
  [CHAT_TAB_BASE]: z.object({ currentThreadId: z.string() }),
  [WORKFLOW_USE_BASE]: z.object({
    workflowId: z.string(),
    componentId: z.string(),
    activeStep: z.number(),
    workflowProgress: z.record(
      z.object({
        inputs: z.record(z.string(), z.any()),
        outputs: z.record(z.string(), z.string()),
        componentId: z.string()
      })
    )
  }),
  [TOOL_TAB_BASE]: z.object({
    toolId: z.string(),
    componentId: z.string(),
    userInput: z.record(z.string(), z.any())
  })
});

const WorkspaceInner = ({
  children,
  workspace,
  isFetching
}: {
  children: React.ReactNode;
  workspace: TWorkspaceDataToSave;
  isFetching: boolean;
}) => {
  const { parsedObject, type } = useDefaultWorkspaceFormValues();

  const methods = useForm<Workspace>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(workspace),
    mode: "onChange"
  });

  useSetDefaultValues({ type, methods, parsedObject });
  useReset(methods, workspace, isFetching);
  useKeepFormWorkspaceIdInSync(methods);

  return (
    <FormProvider {...methods}>
      <WorkspaceInputsFormProvider>{children}</WorkspaceInputsFormProvider>
    </FormProvider>
  );
};

const WorkspaceFormProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading, isFetching } = useGetDefaultWorkspaceFields();
  useResetWorspaceOnUnmount();

  if (isLoading) {
    return null;
  }
  return (
    <WorkspaceInner
      workspace={data?.workspace || DEFAULT_WORKSPACE}
      isFetching={isFetching}
    >
      {children}
    </WorkspaceInner>
  );
};

export default WorkspaceFormProvider;
