import type { ToolAssetValue } from "@toolflow/shared";
import React from "react";
import { useToolContext } from "../../../../../../../tools/contexts/ToolContext";
import ToolContextComponentContainer from "../../../../../../../tools/contexts/ToolContextContainer";
import AssetGroupWrapperContainer from "./AssetGroupWrapper";

const ToolAssetGroupInner = ({ value }: { value: ToolAssetValue }) => {
  const { tool } = useToolContext();

  return (
    <AssetGroupWrapperContainer
      text={tool.name}
      badgeCount={value.toolCount}
      assets={value.assets}
    />
  );
};

const ToolAssetGroup = ({ value }: { value: ToolAssetValue }) => {
  return (
    <ToolContextComponentContainer toolId={value.toolId}>
      <ToolAssetGroupInner value={value} />
    </ToolContextComponentContainer>
  );
};

export default ToolAssetGroup;
