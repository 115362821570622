import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import React from "react";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import { useDispatch } from "react-redux";
import { setSidebarClose } from "../../layout/sidebar/sidebarSlice";
import useCloseDrawer from "./toolBuilder/store/hooks/useCloseDrawer";

interface IBuilderCardBase {
  title: string;
  description: string;
  Icon: React.ElementType;
}

interface IBuilderCardProps extends IBuilderCardBase {
  route: string;
}

interface IBuilderCardBaseProps extends IBuilderCardBase {
  onClick: () => void;
}

function BuilderCardBase({
  title,
  description,
  Icon,
  onClick
}: Readonly<IBuilderCardBaseProps>) {
  return (
    <Card variant="elevation" className="h-100-percent flex flex-column">
      <CardActionArea className="h-100-percent" onClick={onClick}>
        <CardContent className="h-100-percent">
          <CardHeader title={title} avatar={<Icon fontSize="small" />} />
          <Typography>{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function BuilderCard({
  title,
  description,
  Icon,
  route
}: Readonly<IBuilderCardProps>) {
  const navigate = useToolflowNavigate();
  const dispatch = useDispatch();
  const closeDrawer = useCloseDrawer();

  const onClick = () => {
    closeDrawer();
    dispatch(setSidebarClose());
    navigate(route);
  };

  return (
    <BuilderCardBase
      title={title}
      description={description}
      Icon={Icon}
      onClick={onClick}
    />
  );
}

export default BuilderCard;
