import type {
  BlockDataTypesWithDefault,
  TDeepgramSettings,
  TScraperSettings
} from "@toolflow/shared";
import type { Edge } from "reactflow";

const getDependencyArray = (data: BlockDataTypesWithDefault, edges: Edge[]) => {
  const { type } = data;
  if (type === "constant") {
    return [data.constant];
  }
  if (type === "DeepgramTranscribe") {
    return [(data.settings as TDeepgramSettings).file];
  }
  if (type === "WebsiteContentCrawler") {
    return [(data.settings as TScraperSettings).urlFieldInputKey];
  }
  if (type === "toolWithinTool") {
    return [data.inputMap];
  }
  if (type === "logic") {
    return [JSON.stringify(data.logicArray), JSON.stringify(edges)];
  }
  return [];
};

export default getDependencyArray;
