import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useGetCurrentUser from "../../../../../features/user/hooks/useGetCurrentUser";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import useGetInitialWorkspace from "./useGetInitialWorkspace";
import useConvertStringToTipTap from "../../../../formatters/strings/tiptap/hooks/useConvertStringToJSON";
import type { JSONContent } from "@tiptap/core";
import useValidateObject from "../../../../hooks/useValidateObject";
import type { RichTextEditorRef } from "mui-tiptap";

const useGetValidatedInitialContent = ({
  rteRef
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
}) => {
  const { isLoading } = useGetInitialWorkspace();
  const { muiTipTapProps } = useMuiTipTapContext();
  const { initialContent, routeType, page } = muiTipTapProps;
  const { externalId = "" } = useParams();
  const user = useGetCurrentUser();
  const convertToTipTap = useConvertStringToTipTap();
  const { isLoading: hasValidated } = useValidateObject();
  const validatedInitialContent = useMemo(() => {
    // if the page is still being validated, or the workflow is loading, or the initial state hasn't been fetched return ""
    // this is hacky with the user -> we should refactor
    if ((!hasValidated && page) || isLoading) return "";
    if (
      externalId &&
      routeType === "workspace" &&
      typeof initialContent === "string"
    ) {
      return {
        type: "doc",
        content: convertToTipTap(initialContent) as JSONContent
      };
    }
    return initialContent as string;
  }, [externalId, initialContent, hasValidated, isLoading, page, user]);
  const content =
    typeof validatedInitialContent === "string"
      ? validatedInitialContent
      : validatedInitialContent.content;

  // https://github.com/sjdemartini/mui-tiptap/blob/main/README.md#re-rendering-richtexteditor-when-content-changes
  useEffect(() => {
    queueMicrotask(() => {
      rteRef.current?.editor?.commands.setContent(content);
      // we scroll to the bottom of the bounding ref when the content changes
    });
  }, [validatedInitialContent, rteRef.current?.editor]);

  return content;
};

export default useGetValidatedInitialContent;
