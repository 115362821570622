import React from "react";
import { useToolbuilderContext } from "../../../../features/pages/builder/toolBuilder/context/ToolBuilderContext";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { VisibilityTypes } from "@toolflow/shared";
import type { FormControlProps } from "@mui/material/FormControl";
import { useUpdateToolMutation } from "../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { UpdateVisibilitySelectBase } from "./UpdateVisibilitySelectBase";

function UpdateVisibilitySelect({
  noMargin,
  toolId,
  formProps
}: {
  noMargin?: boolean;
  toolId: string;
  formProps?: FormControlProps;
}) {
  const { dispatch, state } = useToolbuilderContext();
  const [updateTool, isLoading] = useUpdateToolMutation();
  const { visibility } = state.currentState;

  const handleSelectChange = async (
    event: SelectChangeEvent<VisibilityTypes>
  ) => {
    await updateTool({
      body: { visibility: event.target.value as VisibilityTypes },
      toolId
    })
      .unwrap()
      .then((resp) => {
        dispatch({
          type: "SAVE_VISIBILITY",
          visibility: resp.tool.visibility
        });
      });
  };

  return (
    <UpdateVisibilitySelectBase
      noMargin={noMargin}
      formProps={formProps}
      disabled={!!isLoading}
      handleSelectChange={handleSelectChange}
      visibility={visibility}
    />
  );
}

export default UpdateVisibilitySelect;
