import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { useRunAssistantMutation } from "../../../../../../../../ToolflowAPI/rtkRoutes/assistantApi";
import {
  setThreadMessagesLoading,
  updateMessagesWithNewUserMessage
} from "../../chatSlice";
import useSetThreadId from "../../hooks/useSetThreadId";
import useThreadRef from "./useThreadRef";
import type { JSONContent } from "@tiptap/core";

const useHandleRunAssistant = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const [runAssistant] = useRunAssistantMutation();
  const setThreadId = useSetThreadId();
  const dispatch = useDispatch();
  const currentThreadIdRef = useThreadRef();
  const handleRunAssistant = async ({
    threadId,
    message
  }: {
    threadId: string;
    message: string;
  }) => {
    const userMessageId = v4();
    dispatch(setThreadMessagesLoading(true));
    dispatch(
      updateMessagesWithNewUserMessage({
        id: userMessageId,
        message,
        role: "user"
      })
    );
    setUserTextMessage({});
    await runAssistant({
      threadId,
      message
    })
      .unwrap()
      .then((resp) => {
        if (currentThreadIdRef.current !== resp.result.dbThreadId) {
          setThreadId(resp.result.dbThreadId);
        }
      });
  };
  return handleRunAssistant;
};

export default useHandleRunAssistant;
