import type { WorkflowInputString } from "@toolflow/shared";
import type { JSONContent } from "@tiptap/core";
import { useAuthStore } from "../../../../../../../auth/hooks/useAuthStore";
import { useInputContext } from "../../../../../../../../utilities/contexts/InputContext";
import { getHtmlFromText } from "../../../../../../../../utilities/formatters/strings/sanitizeHtml";
import { useWorkspaceInputsFieldArrayContext } from "../../../../../contexts/WorkspaceInputsContext";
import useGetWorkspaceInputIndexById from "../../../../../hooks/useGetWorkspaceInputIndexById";

const useAssetTextField = () => {
  const { isAuthenticated } = useAuthStore();

  const { input } = useInputContext<WorkflowInputString>();
  const { update } = useWorkspaceInputsFieldArrayContext();

  const getInputIndex = useGetWorkspaceInputIndexById();
  const onUpdate = (s: string | JSONContent) => {
    const newInput = {
      ...input,
      value: s
    };
    update(getInputIndex(input.id), newInput);
  };

  const initialContent = getHtmlFromText(input.value as string);

  return {
    id: input.id,
    onUpdate,
    disableEditing: !isAuthenticated,
    initialContent
  };
};

export default useAssetTextField;
