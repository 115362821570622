import React, { createContext, useContext } from "react";
import type { TMarketplaceEntity } from "@toolflow/shared";

interface SearchToolsContextType {
  EntityElement: React.FC<{ entity: TMarketplaceEntity }>;
}

const SearchToolsContext = createContext<SearchToolsContextType | undefined>(
  undefined
);

export function SearchToolsContextComponent({
  children,
  EntityElement
}: Readonly<{
  children: React.ReactNode;
  EntityElement?: React.FC<{ entity: TMarketplaceEntity }>;
}>) {
  if (!EntityElement) {
    return null;
  }
  return (
    <SearchToolsContext.Provider value={{ EntityElement }}>
      {children}
    </SearchToolsContext.Provider>
  );
}

export const useSearchToolsContext = () => {
  const context = useContext(SearchToolsContext);
  if (context === undefined) {
    throw new Error(
      "useSearchToolsContext must be used within a SearchToolsProvider"
    );
  }
  return context;
};

export default SearchToolsContext;
