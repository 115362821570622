import type {
  TBlock,
  CustomEdge,
  IntegrationPrice,
  ReactFlowUtilityType
} from "@toolflow/shared";
import { useState } from "react";
import type { OnConnectStartParams } from "reactflow";
import useOnDeleteNode from "./useOnDeleteNode";
import useSetNodes from "./useSetNodes";
import useSetEdges from "./useSetEdges";
import useHandleEdgeDelete from "./useHandleEdgeDelete";
import useOnNodesDelete from "./useOnNodesDelete";
import useHandleNodeDelete from "./useHandleNodeDelete";
import useHandleConfirm from "./useHandleConfirm";
import { Action } from "../toolBuilderReducer";

const useGetReactflowUtilty = ({
  dispatch,
  blocks,
  edges,
  payload
}: {
  dispatch: React.Dispatch<Action>;
  blocks: TBlock[];
  edges: CustomEdge[];
  payload: {
    integrationPrices: IntegrationPrice[];
  };
}): ReactFlowUtilityType => {
  const [blocksToDelete, setBlocksToDelete] = useState<TBlock[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [dragParams, setDragParams] = useState<OnConnectStartParams | null>(
    null
  );
  const onDeleteNode = useOnDeleteNode({
    blocks,
    setBlocksToDelete,
    setIsConfirmationDialogOpen
  });

  const setNodes = useSetNodes({ dispatch, blocks, payload });
  const setEdges = useSetEdges({ dispatch, blocks, edges });

  const handleEdgeDelete = useHandleEdgeDelete({
    edges,
    blocks,
    setEdges,
    dispatch
  });
  const onNodesDelete = useOnNodesDelete({
    blocks,
    edges: edges,
    setEdges,
    dispatch
  });
  const handleNodeDelete = useHandleNodeDelete({ setNodes, setEdges });
  // Define what happens when the dialog is closed without confirmation
  const handleClose = () => {
    setBlocksToDelete([]);
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirm = useHandleConfirm({
    onNodesDelete,
    blocksToDelete,
    setIsConfirmationDialogOpen,
    setBlocksToDelete,
    handleNodeDelete
  });

  return {
    isConfirmationDialogOpen,
    onDeleteNode,
    handleEdgeDelete,
    handleConfirm,
    handleClose,
    setNodes,
    setEdges,
    dragParams,
    setDragParams
  };
};

export default useGetReactflowUtilty;
