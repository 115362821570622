import type {
  TDeepgramSettings,
  TScraperSettings,
  TSerpSettings
} from "@toolflow/shared";
import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
type TBlockSettings = TDeepgramSettings | TScraperSettings | TSerpSettings;

const useSyncCurrentAndPrevSettings = (settings: TBlockSettings) => {
  const [prevSettings, setPrevSettings] = useState(settings);

  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(settings, prevSettings)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevSettings(settings);
    }
  }, [settings]); //eslint-disable-line react-hooks/exhaustive-deps
};

export default useSyncCurrentAndPrevSettings;
