import React, { createContext } from "react";
import type { ButtonSizeTypes } from "@toolflow/shared";
import FavoritedObject from "./FavoritedObject";
import UnfavoritedObject from "./UnfavoritedObject";

export interface IToggleFavoriteTool {
  id: string;
  type: "tool" | "workflow";
  favorited?: boolean;
  className?: string;
  creatorId: string;
  inMarketplace?: boolean;
  disableBorder?: boolean;
  size?: number;
  iconButtonSize?: ButtonSizeTypes;
}

export const FavoriteContext = createContext<IToggleFavoriteTool | undefined>(
  undefined
);

function ToggleFavorite({
  id,
  type,
  favorited,
  className = "",
  iconButtonSize = "small",
  creatorId,
  inMarketplace,
  disableBorder,
  size = 20
}: IToggleFavoriteTool) {
  return (
    <FavoriteContext.Provider
      value={{
        id,
        type,
        favorited,
        creatorId,
        inMarketplace
      }}
    >
      {favorited ? (
        <FavoritedObject
          className={className}
          iconButtonSize={iconButtonSize}
          size={size}
          disableBorder={disableBorder}
        />
      ) : (
        <UnfavoritedObject
          className={className}
          iconButtonSize={iconButtonSize}
          size={size}
          disableBorder={disableBorder}
        />
      )}
    </FavoriteContext.Provider>
  );
}

export default ToggleFavorite;
