import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_VISIBLE_ASSET_ID } from "../helpers/workspaceConstants";

const useSetVisibleAsset = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const setVisibleAsset = (assetId: string) => {
    setValue(WORKSPACE_VISIBLE_ASSET_ID, assetId, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  return setVisibleAsset;
};

export default useSetVisibleAsset;
