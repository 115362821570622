import React, { useState } from "react";
import {
  CREATE_USER_INPUT_LABEL,
  commonFieldTypes,
  inputTypeArray
} from "../../../tools/components/editorToolCard/inputs/helpers/constants";
import useUpdateField from "./useUpdateField";
import iconDict from "../../../../utilities/constants/iconDict";
import AddInputDialog from "./AddInputDialog";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import type {
  CustomToolInputField,
  InputTypeValue,
  MapperObject
} from "@toolflow/shared";
import {
  FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

function OutputListItem({
  field,
  value,
  inputMap,
  id
}: {
  field: string;
  value: InputTypeValue;
  inputMap: MapperObject | null;
  id: string;
}) {
  const [openInputModal, setOpenInputModal] = useState(false);
  const updateField = useUpdateField(id);
  function updateMapper(newFieldSelected: string, f: string) {
    const m: MapperObject = { ...inputMap };
    m[f] = newFieldSelected;

    updateField(m, "inputMap");
  }

  const updateFromAdd = (f: string) => (newField: CustomToolInputField) => {
    if (f !== "") {
      updateMapper(newField.name, f);
    }
  };

  const stringOptions = value.availableOptions.map((o) => o.name);

  if (!inputMap) {
    return null;
  }
  return (
    <>
      <ListItem>
        <Grid2 container xs={12}>
          <Grid2 className="flex justify-space-between align-i-center" xs={12}>
            <div className="flex align-i-center max-w-50-percent">
              <ListItemIcon>{iconDict[value.type]}</ListItemIcon>
              <ListItemText
                primary={field}
                primaryTypographyProps={{ className: "text-wrap" }}
              />
            </div>
            <FormControl
              size="small"
              fullWidth
              margin="dense"
              className="m-l-8px max-w-50-percent"
            >
              <InputLabel>Mapped Field</InputLabel>
              <Select
                name="Mapped Field"
                label="Mapped Field"
                fullWidth
                id="Mapped Field"
                // hack to not have warning show up when changing input names
                value={
                  stringOptions.includes(inputMap[field]) ? inputMap[field] : ""
                }
                onChange={(e) => updateMapper(e.target.value, field)}
                startAdornment={
                  inputMap[field] === "" ? undefined : iconDict[value.type]
                }
                renderValue={() => (
                  <Typography className="m-l-8px" noWrap>
                    {inputMap[field]}
                  </Typography>
                )}
              >
                {value.availableOptions.map((option, idx) => (
                  <MenuItem value={option.name} key={idx}>
                    <ListItemIcon>{iconDict[option.type]}</ListItemIcon>
                    <ListItemText>{option.name}</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem
                  value=""
                  onClick={() => setOpenInputModal(true)}
                  className="notSelected"
                >
                  {CREATE_USER_INPUT_LABEL}
                </MenuItem>
                {inputMap[field] !== "" && (
                  <MenuItem value="" className="notSelected">
                    Unmap input
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 xs={12}>
            {value.dropdownOptions && value.dropdownOptions.length > 0 && (
              <>
                <Typography variant="caption">{`Option${
                  value.dropdownOptions.length === 1 ? " is: " : "s include: "
                }`}</Typography>
                {value.dropdownOptions.map((option, idx) => (
                  <Typography variant="caption" key={idx}>
                    {`${option}${
                      value.dropdownOptions &&
                      value.dropdownOptions.length - 1 !== idx
                        ? `, `
                        : ""
                    }`}
                  </Typography>
                ))}
              </>
            )}
          </Grid2>
        </Grid2>
      </ListItem>
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={updateFromAdd(field)}
        initialType={value.type}
        initialName={field}
        initialOptions={value.dropdownOptions}
        hideTypeDropdown={commonFieldTypes.includes(value.type)}
        allowedFields={
          commonFieldTypes.includes(value.type)
            ? [value.type]
            : inputTypeArray.filter((f) => !commonFieldTypes.includes(f))
        }
      />
    </>
  );
}

export default OutputListItem;
