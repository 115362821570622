import * as React from "react";
import { IconButton, InputAdornment, TextField, styled } from "@mui/material";
import type { AutocompleteRenderInputParams } from "@mui/material";
import styles from "./searchBox.module.css";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";
export const StyledInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-root": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: "#E4DFD4",
    boxShadow: "none",
    width: "100%",
    outline: "none",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    fontFamily: "Konnect",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px solid black",
      outline: "none"
    }
  },
  "& .MuiInputBase-input": {
    background: "white",
    boxShadow: "none",
    borderRadius: 8,
    border: "none",
    outline: "none"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    boxShadow: `0px 6px 20px 0px rgba(101, 91, 71, 0.40) !important`
  },
  "& .Mui-focused": {
    boxShadow: `0px 6px 20px 0px rgba(101, 91, 71, 0.40) !important`,
    border: "none",
    outline: "none"
  },
  ".MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
    position: "relative",
    right: -40
  }
}));
interface IconTextFieldProps {
  iconStart: React.ReactNode;
  focus: boolean;
  setFocus: (focus: boolean) => void;
  placeholder?: string; // Add placeholder to the props interface
  onClear?: () => void;
  params: AutocompleteRenderInputParams;
  autoFocus?: boolean;
}
const SearchBox = ({
  iconStart,
  focus,
  setFocus,
  params,
  onClear,
  ...props
}: IconTextFieldProps) => {
  return (
    <StyledInput
      {...props}
      {...params}
      size="small"
      className={!focus ? "" : styles["search-box-hover"]}
      autoComplete={"on"}
      fullWidth
      InputProps={{
        ...params.InputProps,
        onFocus: (e) => {
          e.stopPropagation();
          setFocus(true);
        },

        onBlur: (e) => {
          e.stopPropagation();
          setFocus(false);
        },
        startAdornment: iconStart ? (
          <InputAdornment position="start" color="primary">
            {iconStart}
          </InputAdornment>
        ) : null,
        endAdornment: params.inputProps.value && (
          <InputAdornment position="end">
            <IconButton onClick={onClear}>
              <CloseIcon color="primary" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchBox;
