import { useToolbuilderContext } from "./context/ToolBuilderContext";
import type { UpdateArrayItem, UpdateSType, TBlock } from "@toolflow/shared";
import { useGetIntegrationPricesQuery } from "../../../../ToolflowAPI/rtkRoutes/integrationPriceApi";

const deepUpdateObject = (obj: $TSFixMe, path: string[], value: $TSFixMe) => {
  if (path.length === 1) {
    obj[path[0]] = value;
    return;
  }

  const [first, ...rest] = path;
  if (!obj[first]) obj[first] = {};

  deepUpdateObject(obj[first], rest, value);
};

const updateNode = (
  blocks: TBlock[],
  id: string,
  updates: UpdateArrayItem[]
) => {
  const index = blocks.findIndex((node) => node.id === id);
  if (index === -1) return blocks;

  const nodeToUpdate =
    blocks[index].type === "logicBlockNode"
      ? JSON.parse(JSON.stringify(blocks[index]))
      : { ...blocks[index] };

  updates.forEach((update) => {
    const { updateValue, dataProperty } = update;
    const properties = dataProperty.split(".");
    let nestedObject = nodeToUpdate.data;

    if (dataProperty.startsWith("nestedLogic")) {
      deepUpdateObject(nestedObject, properties, updateValue);
    } else {
      // Your existing logic to update other properties
      for (let i = 0; i < properties.length - 1; i++) {
        const property = properties[i];
        nestedObject[property] = Array.isArray(nestedObject[property])
          ? [...nestedObject[property]]
          : { ...nestedObject[property] };
        nestedObject = nestedObject[property];
      }

      const lastProperty = properties[properties.length - 1];

      if (
        typeof update.nestedArrayIndex === "number" &&
        update.nestedArrayProp
      ) {
        nestedObject[lastProperty][update.nestedArrayIndex][
          update.nestedArrayProp
        ] = updateValue;
      } else {
        nestedObject[lastProperty] = updateValue;
      }
    }
  });

  return [...blocks.slice(0, index), nodeToUpdate, ...blocks.slice(index + 1)];
};

const useUpdateField = (id: string) => {
  const { state, dispatch } = useToolbuilderContext();
  const { blocks } = state.currentState;
  const { data = { integrationPrices: [] } } = useGetIntegrationPricesQuery();
  const integrationPrices = data.integrationPrices;
  const payload = { integrationPrices };

  const updateField = (
    updateValue: UpdateSType,
    dataProperty: string,
    nestedArrayIndex?: number,
    nestedArrayProp?: string
  ) => {
    const newState = updateNode(blocks, id, [
      { updateValue, dataProperty, nestedArrayIndex, nestedArrayProp }
    ]);
    dispatch({
      type: "SET_STATE",
      key: "blocks",
      value: [...newState],
      payload
    });
  };

  return updateField;
};

export const useBatchUpdateField = (id: string) => {
  const { state, dispatch } = useToolbuilderContext();
  const { blocks } = state.currentState;
  const { data = { integrationPrices: [] } } = useGetIntegrationPricesQuery();
  const integrationPrices = data.integrationPrices;
  const payload = { integrationPrices };

  const batchUpdateField = (updates: UpdateArrayItem[]) => {
    const newState = updateNode(blocks, id, updates);
    dispatch({
      type: "SET_STATE",
      key: "blocks",
      value: [...newState],
      payload
    });
  };

  return batchUpdateField;
};

export default useUpdateField;
