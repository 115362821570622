import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import type { ToolAutocomplete } from "@toolflow/shared";
import { MenuControlsContainer, useRichTextEditorContext } from "mui-tiptap";
import { useGetProfileFavoriteEntitiesQuery } from "../../../../../../ToolflowAPI/rtkRoutes/favoritesApi";
import useGetCurrentUser from "../../../../../../features/user/hooks/useGetCurrentUser";
import useOpenOrCloseToolMenu from "../../hooks/useOpenOrCloseToolMenu";
import useUpdateSearchPrompt from "../../hooks/useUpdateSearchPrompt";
import useHandleInsertInputs from "../../hooks/richTextEditorHooks/useHandleInsertInputs";
import useHandleInsertConstant from "../../hooks/richTextEditorHooks/useHandleInsertConstant";
import ToolMenuListOption from "./ToolMenuListOption";
import useFilteredOptions from "../../hooks/useFilteredOptions";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import { VARIABLE_NAME } from "../../../../../../features/pages/workstation/helpers/workspaceConstants";
import useHandleClickOutsideMenuControls from "../../hooks/useHandleClickOutsideMenuControls";

function ToolMenu() {
  const { setShouldRenderBubble, muiTipTapProps } = useMuiTipTapContext();
  const { disableFormatting } = muiTipTapProps;
  const editor = useRichTextEditorContext();
  const slashHasRenderedRef = useRef<boolean>(false);
  const [slashHasRendered, setSlashHasRendered] = useState(false);

  const { handleInsertInput, insertNewInput } = useHandleInsertInputs({
    slashHasRendered
  });

  const selectFunctions: { [key: string]: () => void } = {
    insertNewInput: insertNewInput
  };

  const handleInsertConstant = useHandleInsertConstant({
    slashHasRendered
  });

  const user = useGetCurrentUser();
  const userId = user?._id || "";
  useGetProfileFavoriteEntitiesQuery(userId);

  const updateSearchPrompt = useUpdateSearchPrompt();

  useEffect(() => {
    slashHasRenderedRef.current = slashHasRendered;
  }, [slashHasRendered]);

  const onKeyDownTextFieldEscape: React.KeyboardEventHandler<HTMLDivElement> = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const charAtCursor = e.key;
    if (charAtCursor === "Escape") {
      setShouldRenderBubble(false);
      editor?.commands.focus();
    }
  };

  useOpenOrCloseToolMenu({
    slashHasRenderedRef,
    setSlashHasRendered
  });

  const [highlighted, setHighlighted] = useState<ToolAutocomplete | null>(null);

  const handleHighlightChange = (
    event: React.SyntheticEvent<Element, Event>,
    option: ToolAutocomplete | null
  ) => {
    setHighlighted(option);
  };

  const select = (value: ToolAutocomplete) => {
    if (value.group === "Snippet") {
      handleInsertConstant(value);
    } else if (value.group === VARIABLE_NAME) {
      handleInsertInput(value);
    } else {
      const func = selectFunctions[value?.id];
      func();
    }
  };
  const filteredOptions = useFilteredOptions();
  const ref = useRef<HTMLDivElement>(null);
  useHandleClickOutsideMenuControls(ref);

  return (
    // we need menu controls container debounced so that the flicker of the dropdown doesnt occur
    <MenuControlsContainer debounced>
      <Autocomplete
        options={filteredOptions}
        ref={ref}
        size="small"
        fullWidth
        autoHighlight
        className={disableFormatting ? "w-300px" : ""}
        openOnFocus
        groupBy={(option) => option.group}
        getOptionLabel={(option: ToolAutocomplete) => option.primary}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <ToolMenuListOption props={props} option={option} key={option.id} />
          );
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === "Tab" && highlighted?.id) {
            e.stopPropagation();
            e.preventDefault();
            select(highlighted);
          }
          return false;
        }}
        onHighlightChange={handleHighlightChange}
        onChange={(
          event: React.SyntheticEvent,
          value: ToolAutocomplete | null,
          reason: string
        ) => {
          if (reason === "selectOption" && value?.id) {
            select(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            autoFocus
            {...params}
            className="toolMenuTextField" // textfield has a default id, so need to use className to stop it from closing on click
            placeholder={updateSearchPrompt()}
            onKeyDown={onKeyDownTextFieldEscape}
          />
        )}
      />
    </MenuControlsContainer>
  );
}

export default ToolMenu;
