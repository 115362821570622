import { useEffect } from "react";
import type {
  TWorkspacePartial,
  ValidateType,
  Workspace
} from "@toolflow/shared";
import type { UseFormReturn } from "react-hook-form";
import { DEFAULT_WORKSPACE } from "../helpers/workspaceConstants";

// We do this instead of reseting the form with default values
// because we need to have a default state based on a default workspace,
// and not an object that has been set on it (like tvr or tools)
const useSetDefaultValues = ({
  type,
  methods,
  parsedObject
}: {
  type?: ValidateType;
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>;
  parsedObject?: TWorkspacePartial;
}) => {
  useEffect(() => {
    if (type) {
      Object.entries(DEFAULT_WORKSPACE).forEach(([name, value]) => {
        let parsedValue;
        if (
          parsedObject &&
          ["tool", "toolVersionResponse", "workflow"].includes(type || "")
        ) {
          parsedValue = parsedObject[name as keyof TWorkspacePartial];
        }
        const val = parsedValue || value;

        // it is best to register before setting values directly
        methods.register(name as keyof Workspace);

        // only dirty the object if the parsedObject is from a tool or a tvr
        methods.setValue(name as keyof Workspace, val, {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false
        });
      });
    }
    // }
  }, [JSON.stringify(parsedObject)]);
};

export default useSetDefaultValues;
