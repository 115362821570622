import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import type { SelectInputProps } from "@mui/material/Select/SelectInput";
import { ChangeEventHandler, Fragment, useState } from "react";
import {
  CREATE_USER_INPUT_LABEL,
  CUSTOM_INPUT
} from "../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import AddInputDialog from "../../AddInputDialog";
import {
  SELECT,
  TEXTFIELD
} from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import type { CustomToolInputField } from "@toolflow/shared";

interface Props {
  id: string;
  label: string;
  customLabel: string;
  customInputValue: string;
  inputTypeValue: string;
  customErrorText: string;
  selectOptions: { value: string; label: string }[];
  updateSelect: SelectInputProps<string>["onChange"];
  updateInputValue: ChangeEventHandler<HTMLTextAreaElement>;
  updateFromFieldName: (fieldName: string) => void;
}

const useAddInputDialog = (
  updateFromFieldName: (fieldName: string) => void
) => {
  const [openInputModal, setOpenInputModal] = useState(false);

  const updateFromAdd = (field: CustomToolInputField) => {
    updateFromFieldName(field.name);
  };

  return { openInputModal, setOpenInputModal, updateFromAdd };
};

export default function SelectInputSetting({
  id,
  label,
  customLabel,
  selectOptions,
  updateSelect,
  updateInputValue,
  customInputValue,
  inputTypeValue,
  customErrorText,
  updateFromFieldName
}: Props) {
  const { openInputModal, setOpenInputModal, updateFromAdd } =
    useAddInputDialog(updateFromFieldName);

  return (
    <Fragment>
      <FormControl fullWidth margin="none">
        <InputLabel id="user-select-input">{label}</InputLabel>
        <Select
          fullWidth
          labelId="user-select-input"
          id="userKeywords"
          label={label}
          onChange={updateSelect}
          className="nowheel nodrag nopan"
          value={inputTypeValue}
        >
          <MenuItem value="" onClick={() => setOpenInputModal(true)}>
            {CREATE_USER_INPUT_LABEL}
          </MenuItem>
          {selectOptions.map((option: { label: string; value: string }) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {inputTypeValue === CUSTOM_INPUT && (
        <FormControl fullWidth margin="normal">
          <TextField
            key={id}
            label={customLabel}
            value={customInputValue}
            onChange={updateInputValue}
          />
          {customErrorText && !customInputValue.length && (
            <FormHelperText>{customErrorText}</FormHelperText>
          )}
        </FormControl>
      )}
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={updateFromAdd}
        initialType={TEXTFIELD}
        allowedFields={[TEXTFIELD, SELECT]}
      />
    </Fragment>
  );
}
