import React from "react";
import RunToolCard from "./RunToolCard";
import RunWorkflowCard from "./RunWorkflowCard";

export function EntityBody({
  entityType
}: {
  entityType: "tool" | "workflow" | "";
}) {
  if (entityType === "tool") {
    return <RunToolCard />;
  }
  if (entityType === "workflow") {
    return <RunWorkflowCard />;
  }
  return null;
}
