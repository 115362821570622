import { FC } from "react";

import type { TSerpSettings } from "@toolflow/shared";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";

import { Grid } from "@mui/material";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import SelectInputSetting from "../../common/selectInputSetting/SelectInputSetting";
import useSerpSelectInputUpdate from "../../common/selectInputSetting/hooks/useSerpSelectInputUpdate";
import SubSettingsLayout from "../deepgram/SubSettingsLayout";
import ModelDemographicSettings from "./ModelDemographicSettings";
import ModelOutputSettings from "./ModelOutputSettings";
import ModelPaginationSettings from "./ModelPaginationSettings";

interface ISerpBlockSettingsProps {
  id: string;
  settings: TSerpSettings;
}
const SerpBlockSettings: FC<ISerpBlockSettingsProps> = (props) => {
  const { id, settings } = props;
  useSyncCurrentAndPrevSettings(settings);
  const selectInputProps = useSerpSelectInputUpdate(id, settings);

  return (
    <>
      <AccordionWrapper
        title="Configuration"
        className="marginBottom16"
        startsExpanded
        elevation={0}
      >
        <SubSettingsLayout
          title="Query Keywords"
          subheader="Keywords are used to get results from google search result. Each keyword will be separately searched in parallel. Keywords must be comma delimited."
        >
          <SelectInputSetting
            {...selectInputProps}
            id={id}
            label="Keywords source"
            customLabel="Query Keywords"
            customErrorText="No keywords to query."
          />
        </SubSettingsLayout>
        <SubSettingsLayout title="Model Settings">
          <Grid container spacing={2}>
            <ModelOutputSettings
              id={id}
              allowedOutputs={settings.allowedOutputs}
            />
            <ModelDemographicSettings id={id} settings={settings} />
            <ModelPaginationSettings id={id} settings={settings} />
          </Grid>
        </SubSettingsLayout>
      </AccordionWrapper>
    </>
  );
};

export default SerpBlockSettings;
