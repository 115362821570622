import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import useGetAssetId from "../../../../hooks/assets/useGetAssetId";
import useSetVisibleAsset from "../../../../hooks/useSetVisibleAsset";
import { ArrowDownIcon } from "../../../../../../../globalTheme/icons/icons";
import useGetCurrentAssetIndex from "../hooks/useGetAssetIndex";

function MoveDownAnAssetButton() {
  const inputs = useWorkspaceInputs();
  const selectedAsset = useGetAssetId();
  const assetIndex = useGetCurrentAssetIndex();

  const isLastAsset = inputs[inputs.length - 1].id === selectedAsset;

  const setSelectedAsset = useSetVisibleAsset();

  const setSelectedAssetDown = () => {
    setSelectedAsset(inputs[assetIndex + 1].id);
  };

  return (
    <RemixButton
      icon={ArrowDownIcon}
      onClick={setSelectedAssetDown}
      providedColor={!isLastAsset ? "black" : undefined}
      disabled={isLastAsset}
    />
  );
}

export default MoveDownAnAssetButton;
