import { Button, TextField } from "@mui/material";
import type { WorkflowInput } from "@toolflow/shared";
import React, { useState } from "react";
import useWorkspaceInputById from "../hooks/useWorkspaceInputById";
import useHandleInputNameChange from "../../../../utilities/components/textFields/workspace/hooks/useHandleInputNameChange";

function useUpdateInputNameForm({
  input,
  closeDialog
}: {
  input: WorkflowInput;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const [name, setName] = useState(input.name);
  const handleNameChange = useHandleInputNameChange({ input });

  const handleResetName = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    setName(input.name);
    closeDialog(e);
  };

  const updateName = (e: React.SyntheticEvent<Element, Event>) => {
    handleNameChange(name);
    closeDialog(e);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return {
    name,
    handleResetName,
    updateName,
    onChange
  };
}

export function UpdateInputNameForm({
  input,
  closeDialog
}: {
  input: WorkflowInput;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const { name, handleResetName, updateName, onChange } =
    useUpdateInputNameForm({
      input,
      closeDialog
    });

  return (
    <>
      <TextField
        fullWidth
        autoFocus
        size="small"
        margin="normal"
        onChange={onChange}
        value={name}
        placeholder="Asset name"
      />
      <div className="flex">
        <Button
          aria-label="edit-name"
          onClick={handleResetName}
          size="small"
          variant="text"
          className="m-l-auto"
          fullWidth={false}
        >
          Cancel
        </Button>
        <Button
          aria-label="edit-name"
          onClick={updateName}
          size="small"
          className="m-l-8px"
          variant="contained"
          fullWidth={false}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export function UpdateInputNameFormContainer({
  inputId,
  closeDialog
}: {
  inputId: string;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const input = useWorkspaceInputById({ id: inputId });

  if (!input) return null;

  return <UpdateInputNameForm input={input} closeDialog={closeDialog} />;
}

export default UpdateInputNameForm;
