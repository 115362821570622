import type { TEntityTypes } from "@toolflow/shared";
import useSetSelectedTool from "../../../../hooks/useSetSelectedTool";
import useSetSelectedWorkspaceWorkflow from "../../../../hooks/useSetSelectedWorkflow";
import {
  TOOL,
  WORKFLOW
} from "../../../../../../../utilities/constants/constants";

const useSelectEntityCard = (disableSave = false) => {
  const setSelectedTool = useSetSelectedTool(disableSave);
  const setSelectedWorkflow = useSetSelectedWorkspaceWorkflow(disableSave);

  const handleSelectEntity = (id: string, type: TEntityTypes) => {
    if (type === TOOL) {
      setSelectedTool(id);
    } else if (type === WORKFLOW) {
      setSelectedWorkflow(id);
    }
  };

  return {
    handleSelectEntity
  };
};

export default useSelectEntityCard;
