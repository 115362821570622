import { Button } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import type { CustomToolOutputField } from "@toolflow/shared";
import { COPYABLE_LARGE_TEXTFIELD } from "../../../../tools/components/editorToolCard/inputs/helpers/outputConstants";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import { AddIcon } from "../../../../../globalTheme/icons/icons";

function AddOutputButton() {
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  const handleAddToolOutputField = () => {
    const updatedFields: CustomToolOutputField[] = [
      ...toolOutputFields,
      {
        name: "",
        type: COPYABLE_LARGE_TEXTFIELD,
        id: uuidv4() // Add a unique id
      }
    ];
    dispatch({
      type: "SET_TOOL_OUTPUT_FIELDS",
      toolOutputFields: updatedFields
    });
  };
  return (
    <Button
      endIcon={<AddIcon />}
      variant="outlined"
      onClick={handleAddToolOutputField}
    >
      Add Output
    </Button>
  );
}

export default AddOutputButton;
