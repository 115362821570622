import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import type { ToolAutocomplete } from "@toolflow/shared";
import React from "react";
import { StarIcon } from "../../../../../../globalTheme/icons/icons";

export default function ToolMenuListOption({
  props,
  option
}: Readonly<{
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ToolAutocomplete;
}>) {
  return (
    <ListItem
      {...props} //need this before the key prop because props has a key param we want to overwrite
      key={option.id}
      data-id={option.id}
      dense
      disableGutters
      disablePadding
      id="toolMenuListItem"
    >
      <ListItemText
        primary={
          <div className="flex align-i-center">
            <span className="m-r-8px">{option.primary}</span>
          </div>
        }
        // secondary={option.secondary}
      />
      {!!option.favoriteType && (
        <ListItemSecondaryAction>
          <IconButton disabled className="m-r-8px">
            <StarIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
