import { FormControl, IconButton } from "@mui/material";
import React from "react";
import useGetCurrentToolOutputFieldIndex from "./hooks/useGetCurrentToolOutputFieldIndex";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import { DeleteIcon } from "../../../../../globalTheme/icons/icons";

function DeleteToolOutputFieldButton({ disabled }: { disabled?: boolean }) {
  const { dispatch } = useToolbuilderContext();
  const index = useGetCurrentToolOutputFieldIndex();
  const handleDeleteToolOutputField = () => {
    dispatch({
      type: "DELETE_TOOL_OUTPUT_FIELD",
      index
    });
  };

  return (
    <FormControl margin="normal" disabled={disabled}>
      <IconButton onClick={handleDeleteToolOutputField} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    </FormControl>
  );
}

export default DeleteToolOutputFieldButton;
