import { Typography } from "@mui/material";
import pluralize from "../../../../../utilities/formatters/strings/pluralize";
import { parsedClassNames } from "../../../../../utilities/functions/parsedClassNames";
import { InputOutputPosition } from "./helpers/constants";
import styles from "./inputOutputBase.module.css";
import { AddFillIcon } from "../../../../../globalTheme/icons/icons";
import { toolflowPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";

const cx = parsedClassNames.bind(styles);

export enum InputType {
  INPUT = "input",
  OUTPUT = "output"
}

const InputOutputBase = ({
  position,
  type,
  onClick,
  entityCount,
  opened,
  Icon
}: {
  position: InputOutputPosition;
  type: InputType;
  onClick: () => void;
  entityCount: number;
  opened: boolean;
  Icon: React.ElementType;
}) => {
  const hasEntities = entityCount > 0;
  const text = hasEntities
    ? `${entityCount} ${pluralize(type, entityCount)}`
    : `Add ${type}s`;
  return (
    <div
      onClick={onClick}
      className={cx("flex flex-column w-100-percent", styles.base, {
        [styles.top]: position === InputOutputPosition.TOP,
        [styles.bottom]: position === InputOutputPosition.BOTTOM,
        [styles["has-entities"]]: hasEntities,
        [styles["no-entities"]]: !hasEntities,
        [styles.opened]: opened
      })}
    >
      <div className="flex flex-row justify-center m-t-8px">
        <div
          className={cx("icon", {
            [styles["no-entities-icon"]]: !hasEntities
          })}
        >
          {hasEntities ? (
            <Icon color={toolflowPrimaryColor} size={20} />
          ) : (
            <AddFillIcon color={toolflowPrimaryColor} size={20} />
          )}
        </div>
      </div>
      <Typography
        variant="badgeLabel"
        className="w-100-percent m-v-8px"
        align="center"
      >
        {text}
      </Typography>
    </div>
  );
};

export default InputOutputBase;
