import React from "react";
import BlockWrapper from "../BlockWrapper";
import type { ScraperBlockProps } from "@toolflow/shared";
import ScraperBlockInner from "./ScraperBlockInner";
import useCurrentAndPreviousBlocksSync from "../../hooks/useCurrentAndPreviousBlocksSync";

const ScraperBlockDrawer: React.FC<ScraperBlockProps> = ({
  data,
  selected,
  id
}) => {
  useCurrentAndPreviousBlocksSync();
  return (
    <BlockWrapper id={id} data={data} selected={selected} noPadding>
      <ScraperBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default ScraperBlockDrawer;
