import React from "react";
import UtilityDraggable from "../../../../../../utilities/components/draggables/UtilityDraggable";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { CustomToolInputFieldContextComponent } from "../../../../../../utilities/contexts/CustomToolInputFieldContext";
import type { CustomToolInputField } from "@toolflow/shared";

const DraggableToolInputFieldWrapper = ({
  children,
  index,
  toolInputField
}: {
  children: React.ReactNode;
  index: number;
  toolInputField: CustomToolInputField;
}) => {
  const { toolInputFields } = useToolInputFieldsContext();

  return (
    <UtilityDraggable
      key={toolInputField.id}
      id={toolInputField.id}
      index={index}
      type="toolInput"
      isDragDisabled={toolInputFields.length <= 1}
    >
      <CustomToolInputFieldContextComponent
        customToolInputField={toolInputField}
      >
        {children}
      </CustomToolInputFieldContextComponent>
    </UtilityDraggable>
  );
};

export default DraggableToolInputFieldWrapper;
