let tabId = 0;

export type TAddChipTab = {
  label: string;
  value: string;
  indexValue: number;
};

export const addChipTabs: TAddChipTab[] = [
  {
    label: "All",
    value: "all",
    indexValue: tabId++
  },
  {
    label: "Assets",
    value: "assets",
    indexValue: tabId++
  },
  {
    label: "Snippets",
    value: "snippets",
    indexValue: tabId++
  }
];
