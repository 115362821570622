import React from "react";
import { useDuplicateObject } from "../../../../../../utilities/hooks/useDuplicateObject";
import {
  TOOL,
  WORKFLOW
} from "../../../../../../utilities/constants/constants";
import SettingsMenuItemWithClose from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import RemixButton from "../../../../../../utilities/components/icons/RemixButton";
import { CopyIcon, ForkIcon } from "../../../../../../globalTheme/icons/icons";

function DuplicateHomePageEntityMenuItem({
  id,
  type,
  iconOnly = false
}: {
  id: string;
  type: "tool" | "workflow";
  iconOnly?: boolean;
}) {
  const { loading, duplicateObject } = useDuplicateObject();

  if (type === WORKFLOW) return null;

  const duplicate = () => {
    if (type === "tool") {
      duplicateObject(id, TOOL);
    }
  };

  return !iconOnly ? (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      text={`Duplicate`}
      icon={<CopyIcon size={16} />}
    />
  ) : (
    <RemixButton text="Duplicate" icon={ForkIcon} onClick={duplicate} />
  );
}

export default DuplicateHomePageEntityMenuItem;
