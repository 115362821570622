import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../globalTheme/muiUtils/appTheme";

import { IconButton } from "@mui/material";
import RemixWrapperIconWithTheme from "../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import useDeleteAsset from "../../../hooks/assets/useDeleteAsset";
import { DeleteIcon } from "../../../../../../globalTheme/icons/icons";

/**
 * Only should be used in the Asset List
 * Shows icon on hover
 */
const DeleteAssetButtonFromList = ({ assetId }: { assetId: string }) => {
  const deleteAsset = useDeleteAsset();
  const onClick = () => deleteAsset(assetId);
  return (
    <IconButton onClick={onClick} className="asset-button" disableTouchRipple>
      <RemixWrapperIconWithTheme
        Icon={DeleteIcon}
        size={16}
        providedColor={toolflowTextDisabledColor}
      />
    </IconButton>
  );
};

export default DeleteAssetButtonFromList;
