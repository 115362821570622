import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import type { ValidateType } from "@toolflow/shared";
import IconWithCircle from "../../../../../globalTheme/icons/logos/IconWithCircle";
import { IconButton, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CloseIcon,
  DeleteFillIcon
} from "../../../../../globalTheme/icons/icons";

const DeleteConfirmationDialog = ({
  deleteObject,
  open,
  handleClose,
  type,
  name = ""
}: {
  deleteObject: (e: React.SyntheticEvent<Element, Event>) => void;
  open: boolean;
  handleClose: (
    e?: $TSFixMe,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => void;
  type: ValidateType;
  name?: string;
}) => {
  const deleteNames = {
    tool: "tool",
    workspace: "session",
    toolVersionResponse: "tool response",
    workflow: "workflow"
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="p-16px">
        <IconButton
          onClick={handleClose}
          sx={{ marginLeft: "auto", marginRight: "5px", display: "block" }}
        >
          <CloseIcon size={14} />
        </IconButton>
        <div className="p-h-16px p-t-16px">
          <IconWithCircle
            icon={<DeleteFillIcon color="red" />}
            width={92}
            className="m-b-16px"
          />
          <Typography variant="h6">Delete this {deleteNames[type]}?</Typography>
        </div>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            aria-label="delete-tool"
            onClick={handleClose}
            size="large"
            variant="text"
            className="m-l-auto"
            fullWidth={false}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            aria-label="edit-name"
            onClick={deleteObject}
            size="large"
            className="m-l-8px"
            variant="contained"
            fullWidth={false}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
