import React from "react";
import {
  AllFillIcon,
  AllIcon,
  GrowthFillIcon,
  GrowthIcon,
  MarketingFillIcon,
  MarketingIcon,
  PopularFillIcon,
  PopularIcon,
  SalesFillIcon,
  SalesIcon,
  UtilitiesIcon,
  UtilitiesFillIcon
} from "../../../../../globalTheme/icons/icons";
import { STARRED_TAB, USER_TOOLS_TAB } from "../utils/constants";
export type TUseCaseType = "useCase";
export type TCategoryType = "category";
export type TTabType = TUseCaseType | TCategoryType;
export type TEntityTab = {
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  label: string;
  value: string;
  RemixIconRaw: React.ElementType;
  disabledTabs?: string[];
};
const iconSize = 20;
export const marketplaceCategoryTabs: TEntityTab[] = [
  {
    label: "Popular",
    icon: <PopularIcon size={iconSize} />,
    selectedIcon: <PopularFillIcon size={iconSize} />,
    RemixIconRaw: PopularIcon,
    value: "popular",
    disabledTabs: [USER_TOOLS_TAB, STARRED_TAB]
  },
  {
    label: "All",
    icon: <AllIcon size={iconSize} />,
    selectedIcon: <AllFillIcon size={iconSize} />,
    RemixIconRaw: AllIcon,
    value: "all"
  },
  {
    label: "Utilities",
    icon: <UtilitiesIcon size={iconSize} />,
    selectedIcon: <UtilitiesFillIcon size={iconSize} />,
    RemixIconRaw: UtilitiesIcon,
    value: "utilities"
  },
  {
    label: "Sales",
    icon: <SalesIcon size={iconSize} />,
    selectedIcon: <SalesFillIcon size={iconSize} />,
    RemixIconRaw: SalesIcon,
    value: "sales"
  },
  {
    label: "Marketing",
    icon: <MarketingIcon size={iconSize} />,
    selectedIcon: <MarketingFillIcon size={iconSize} />,
    RemixIconRaw: MarketingIcon,
    value: "marketing"
  },
  {
    label: "Growth",
    icon: <GrowthIcon size={iconSize} />,
    selectedIcon: <GrowthFillIcon size={iconSize} />,
    RemixIconRaw: GrowthIcon,
    value: "growth"
  }
];
