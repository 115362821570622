import { useSelector } from "react-redux";
import { selectAllMarketplaceEntities } from "../../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { useGetMarketplaceEntitiesQuery } from "../../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";

export const useAllMarketplaceEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  return useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
};

export const useAllMarketplaceEntities = () => {
  useAllMarketplaceEntitiesWithLoading(); // needed to subscribe to the query
  return useSelector(selectAllMarketplaceEntities);
};
