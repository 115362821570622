import { useBlockContext } from "../BlockContext";
import { blockHeaderIconDict } from "../constants/blockConfig";

const BlockIcon = () => {
  const { blockData: data } = useBlockContext();
  const { type } = data;
  if (type === "default") {
    return null;
  }

  const Icon = blockHeaderIconDict[type];
  return <Icon />;
};

export default BlockIcon;
