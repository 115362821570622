import type {
  ToolOutputOption,
  UniqueNameOutput,
  CustomToolOutputField,
  CustomToolInputField,
  TBlock
} from "@toolflow/shared";
import {
  COPYABLE_IMAGE,
  COPYABLE_LARGE_TEXTFIELD
} from "../../../../tools/components/editorToolCard/inputs/helpers/outputConstants";

export const getUniqueOutputNames = (
  customToolInputFields: CustomToolInputField[],
  nodes: TBlock[]
): UniqueNameOutput => {
  // Extract names from customToolInputFields
  const inputFieldNames = customToolInputFields
    .filter((field) => field.name && field.name.trim() !== "")
    .map((field) => {
      return {
        value: field.name,
        type: COPYABLE_LARGE_TEXTFIELD
      } as ToolOutputOption;
    });

  const blockFieldNames: ToolOutputOption[] = [];

  nodes.forEach((node) => {
    if (
      node.type === "promptBlockNode" ||
      node.type === "toolWithinToolBlockNode" ||
      node.type === "constantBlockNode" ||
      node.type === "deepgramBlockNode" ||
      node.type === "scraperBlockNode" ||
      node.type === "serpBlockNode"
    ) {
      if (
        node.type === "promptBlockNode" ||
        node.type === "constantBlockNode" ||
        node.type === "deepgramBlockNode" ||
        node.type === "scraperBlockNode" ||
        node.type === "serpBlockNode"
      ) {
        if (node.data?.type === "Dall-E2") {
          blockFieldNames.push({
            value: node.data.label,
            type: COPYABLE_IMAGE
          });
        } else {
          blockFieldNames.push({
            value: node.data.label,
            type: COPYABLE_LARGE_TEXTFIELD
          });
        }
      } else {
        // i think there is a bug where
        node.data.tool?.main.toolOutputFields.forEach(
          (field: CustomToolOutputField) => {
            blockFieldNames.push({
              value: `${node.data.label} - ${field.name}`,
              type: field.type
            });
          }
        );
      }
    }
  });

  const filteredInputs = inputFieldNames.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );

  const filteredBlocks = blockFieldNames.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );

  return { inputs: filteredInputs, blocks: filteredBlocks };
};
