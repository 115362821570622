import { Divider, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { FC } from "react";
import useAddChip from "../runButton/hooks/useAddChip";
import {
  classNames,
  parsedClassNames
} from "../../../../../../../../utilities/functions/parsedClassNames";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import styles from "./addChipPaper.module.css";
import { ChipGroup } from "./ChipGroup";

const cx = parsedClassNames.bind(styles);

interface IAddChipPaperProps {
  className?: string;
  onClickChip: (type: "input" | "snippet", id: string) => void;
}

const AddChipPaper: FC<IAddChipPaperProps> = ({ className, onClickChip }) => {
  const {
    tabs,
    handleChangeTabIndex,
    selectedTabIndex,
    entitiesObject,
    setFilteredString,
    filteredString
  } = useAddChip();

  return (
    <Box
      className={classNames(
        "p-t-10px p-b-20px h-100-percent flex flex-grow-1 flex-column",
        className
      )}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        className="p-h-20px"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 }
          }
        }}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              key={tab.value}
              value={tab.indexValue}
              label={<span className={`capitalize`}>{tab.label}</span>}
            />
          );
        })}
      </Tabs>
      <Divider />
      <TextField
        size="small"
        margin="normal"
        fullWidth
        autoComplete="off"
        value={filteredString}
        className="p-h-20px"
        InputProps={{
          startAdornment: <SearchIcon size={20} className="m-r-10px " />
        }}
        onChange={(e) => setFilteredString(e.target.value)}
      />
      <div
        className={cx(
          "scrollable-content h-100-percent w-100-percent flex-grow-1 p-h-20px",
          styles["entity-container"]
        )}
      >
        {Object.entries(entitiesObject).map(
          ([key, { entities, name, type }]) => {
            if (entities.length === 0) return null;
            return (
              <ChipGroup
                key={key}
                name={name}
                type={type}
                entities={entities}
                onClickChip={onClickChip}
              />
            );
          }
        )}
      </div>
    </Box>
  );
};

export default AddChipPaper;
