import React from "react";
import UtilityDragHandle from "../../../../../utilities/components/draggables/UtilityDragHandle";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

function ToolOutputFieldDragHandle() {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  return <UtilityDragHandle disabled={toolOutputFields.length <= 1} />;
}

export default ToolOutputFieldDragHandle;
