import type { ToolAssetValue, TransformedInputValue } from "@toolflow/shared";

function isToolAsset(value: TransformedInputValue): value is ToolAssetValue {
  return (
    value.sourceType === "toolOutput" ||
    value.sourceType === "toolOutputPlaceholder"
  );
}

export default isToolAsset;
