import { useMemo } from "react";
import useGetCurrentWorkflowVersion from "./useGetCurrentWorkflowVersion";
import {
  DEFAULT_VISIBILITY,
  DEFAULT_WORKFLOW_NAME,
  DEFAULT_WORKFLOW_TAG,
  VISIBILITY_FIELD_LABEL,
  WORKFLOW_DESCRIPTION_FIELD_LABEL,
  WORKFLOW_NAME_FIELD_LABEL,
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_TAG_FIELD_LABEL,
  getWorkflowDefaultStep
} from "../../WorkflowBuilderConstants";
import type {
  TWorkflowDataToSave,
  TWorkflowVersionDataToSave
} from "@toolflow/shared";
import useGetWorkflowByParams from "../../hooks/useGetWorkflowByParams";

const getDefaultWorkflowFormValues = (
  workflowVersion?: TWorkflowVersionDataToSave,
  workflow?: TWorkflowDataToSave
) => {
  return {
    [WORKFLOW_TAG_FIELD_LABEL]:
      workflow?.[WORKFLOW_TAG_FIELD_LABEL] || DEFAULT_WORKFLOW_TAG,
    [WORKFLOW_NAME_FIELD_LABEL]:
      workflowVersion?.[WORKFLOW_NAME_FIELD_LABEL] || DEFAULT_WORKFLOW_NAME,
    [WORKFLOW_DESCRIPTION_FIELD_LABEL]:
      workflowVersion?.[WORKFLOW_DESCRIPTION_FIELD_LABEL] || "",
    [VISIBILITY_FIELD_LABEL]:
      workflow?.[VISIBILITY_FIELD_LABEL] || DEFAULT_VISIBILITY,
    [WORKFLOW_STEPS_FIELD_LABEL]: workflowVersion?.steps || [
      getWorkflowDefaultStep(workflowVersion?.steps.length || 0)
    ]
  };
};

const useDefaultWorkflowFormValues = () => {
  const workflowVersion = useGetCurrentWorkflowVersion();
  const { data } = useGetWorkflowByParams();
  return useMemo(
    () => getDefaultWorkflowFormValues(workflowVersion, data?.workflow),
    [workflowVersion]
  );
};

export default useDefaultWorkflowFormValues;
