import type { TRunAssistantRequest } from "@toolflow/shared";
import { setThreadMessagesLoading } from "../../features/pages/workstation/components/chat/chatbox/chatSlice";
import { setErrorMessage } from "../../stores/actions";
import { authenticatedApi } from "../authenticatedAPI";
import { CAN_RUN_ASSISTANT_TAG_TYPE } from "../cacheTagConstants";

export const assistantApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    runAssistant: builder.mutation<
      {
        result: { dbThreadId: string };
        success: boolean;
        error?: string | null;
      },
      TRunAssistantRequest
    >({
      query: (assistantRequest: TRunAssistantRequest) => ({
        url: `runAssistant`,
        method: "POST",
        body: assistantRequest,
        timeout: 30000
      }),
      invalidatesTags: () => {
        return [CAN_RUN_ASSISTANT_TAG_TYPE];
      },
      async onQueryStarted(undefined, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (!response.data.success) {
            dispatch(
              setErrorMessage(
                response.data.error ??
                  "Something went wrong running the assistant"
              )
            );
            dispatch(setThreadMessagesLoading(false));
          }
        } catch (error) {
          dispatch(setErrorMessage("Something went wrong"));
          dispatch(setThreadMessagesLoading(false));
        }
      }
    }),
    canRunAssistant: builder.query({
      query: () => ({
        url: `canRunAssistant`,
        method: "GET",
        timeout: 30000
      }),
      providesTags: [CAN_RUN_ASSISTANT_TAG_TYPE]
    })
  })
});

export const { useRunAssistantMutation, useCanRunAssistantQuery } =
  assistantApi;
