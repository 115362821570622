import React from "react";
import { useBlockContext } from "../BlockContext";
import InOutNodeHandle from "./inOutNodeHandle/InOutNodeHandle";
import DisabledHandleButton from "./disabledHandleButton/DisabledHandleButton";
import { AddIcon } from "../../../../../globalTheme/icons/icons";

const OutHandle = () => {
  const { blockData, id } = useBlockContext();
  const { type } = blockData;
  if (type === "Dall-E2") {
    {
      /* the CSS was acting weird here -> can't use classNames on handles weirdly, so inlined styled for now */
    }
    return <DisabledHandleButton text="Images can&#39;t be inputs" />;
  }
  return <InOutNodeHandle icon={<AddIcon />} handleType="source" id={id} />;
};

export default OutHandle;
