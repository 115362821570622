import React from "react";
import IconButton from "@mui/material/IconButton";
import BlockDrawerHeader from "./BlockDrawerHeader";
import ToolbuilderDrawerToRefactor from "../ToolbuilderDrawerToRefactor";
import useHandleDrawerSizeAndOpen from "./useHandleDrawerSizeAndOpen";
import { CloseIcon, ExpandIcon } from "../../../../../globalTheme/icons/icons";
import useCloseDrawer from "../store/hooks/useCloseDrawer";

const BlockDrawer = ({
  children,
  noPadding
}: {
  children: React.ReactNode;
  noPadding?: boolean;
}) => {
  const closeDrawer = useCloseDrawer();
  const { open, large, setLarge } = useHandleDrawerSizeAndOpen();

  if (!open) return null;

  return (
    <ToolbuilderDrawerToRefactor
      open={open}
      large={large}
      noPadding={noPadding}
    >
      <BlockDrawerHeader
        className={`${noPadding ? "p-16px" : ""}`}
        actionNode={
          <div className="flex align-i-center m-l-8px">
            <IconButton onClick={() => setLarge(!large)}>
              {large ? <CloseIcon /> : <ExpandIcon />}
            </IconButton>
            <IconButton onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      {children}
    </ToolbuilderDrawerToRefactor>
  );
};

export default BlockDrawer;
