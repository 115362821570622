import React, { FC } from "react";
import type { TMarketplaceEntity } from "@toolflow/shared";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import styles from "./suggestedEntityCard.module.css";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";
import EntityCardSubHeader from "../entityCard/EntityCardSubHeader";

const cx = parsedClassNames.bind(styles);

interface IEntityCardProps {
  entity: TMarketplaceEntity;
  onClick: (id: string, type: "tool" | "workflow") => void;
  disableActions?: boolean;
}
const EntityCard: FC<IEntityCardProps> = ({ entity, onClick }) => {
  return (
    <Card
      className={cx(styles["entity-card"])}
      onClick={() => onClick(entity.id, entity.type)}
    >
      <CardHeader
        className={styles["entity-card-header"]}
        classes={{
          content: "max-w-100-percent"
        }}
        title={
          <Typography variant="h7" noWrap>
            {entity.name}
          </Typography>
        }
        subheader={<EntityCardSubHeader entity={entity} />}
      />
      <CardContent className="p-0px h-100px">
        <Box className="h-50px">
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            className="m-0px oflow-two-lines"
          >
            {entity.description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EntityCard;
