import { ChangeEventHandler, useEffect, useState } from "react";
import { CUSTOM_INPUT } from "../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import useToolInputFieldsAndAvailableFields from "../../../useToolInputFieldsAndAvailableFields";
import useUpdateField from "../../../useUpdateField";
import type { TSerpSettings } from "@toolflow/shared";
import { isArray } from "lodash";

export default function useSerpSelectInputUpdate(
  id: string,
  settings: TSerpSettings
) {
  const queries = settings.queries as string;
  const updateField = useUpdateField(id);
  const inputTextFields = useToolInputFieldsAndAvailableFields(id);
  const isURLFieldInputKeyFromTextBlock = inputTextFields.some(
    (field) => field.name === queries
  );
  const [inputTypeValue, setInputTypeValue] = useState(
    isArray(settings.queries) ? CUSTOM_INPUT : settings.inputType
  );
  const [customInputValue, setCustomInputValue] = useState(
    isURLFieldInputKeyFromTextBlock ? "" : queries
  );

  const selectOptions = [
    { label: "+ Insert fixed search query", value: CUSTOM_INPUT },
    ...inputTextFields.map((inputTextField) => ({
      label: inputTextField.name,
      value: inputTextField.name
    }))
  ];

  const updateInputValue: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCustomInputValue(event.target.value);
    if (inputTypeValue === CUSTOM_INPUT) {
      updateField(event.target.value, "settings.queries");
    }
  };

  const updateFromFieldName = (fieldName: string) => {
    setInputTypeValue(fieldName);
    updateField(fieldName, "settings.queries");
    updateField(fieldName, "settings.inputType");
  };

  const updateSelect = (event: { target: { value: string } }) => {
    setInputTypeValue(event.target.value);
    if (event.target.value === CUSTOM_INPUT) {
      setCustomInputValue(customInputValue || "");
      updateField(customInputValue || "", "settings.queries");
      updateField(event.target.value, "settings.inputType");
    } else {
      updateField(event.target.value, "settings.queries");
      updateField(event.target.value, "settings.inputType");
    }
  };

  useEffect(() => {
    // Convert Legacy value to comma delimited string.
    if (isArray(settings.queries)) {
      const convertedQueries = settings.queries.join(", ");
      setInputTypeValue(CUSTOM_INPUT);
      setCustomInputValue(convertedQueries);
      updateField(CUSTOM_INPUT, "settings.inputType");
      updateField(convertedQueries, "settings.queries");
    }
  }, []);

  return {
    selectOptions,
    inputTypeValue,
    customInputValue,
    updateSelect,
    updateInputValue,
    updateFromFieldName
  };
}
