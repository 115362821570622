import React from "react";
import UtilityDraggable from "../../../../../utilities/components/draggables/UtilityDraggable";
import { ToolbuilderOutputFieldContextProvider } from "./ToolbuilderOutputFieldContext";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import type { CustomToolOutputField } from "@toolflow/shared";

const DraggableToolOutputFieldWrapper = ({
  children,
  index,
  toolOutputField
}: {
  children: React.ReactNode;
  index: number;
  toolOutputField: CustomToolOutputField;
}) => {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  return (
    <UtilityDraggable
      key={toolOutputField.id}
      id={toolOutputField.id}
      index={index}
      type="toolOutput"
      isDragDisabled={toolOutputFields.length <= 1}
    >
      <ToolbuilderOutputFieldContextProvider toolOutputField={toolOutputField}>
        {children}
      </ToolbuilderOutputFieldContextProvider>
    </UtilityDraggable>
  );
};

export default DraggableToolOutputFieldWrapper;
