import {
  EXTERNAL_TOOL_BASE_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_BASE_ROUTE_PATH,
  EXTERNAL_WORKSPACE_BASE_ROUTE_PATH
} from "../../../features/navigation/helpers/routePaths";

const getBaseLink = (path: string, id: string) =>
  `${process.env.REACT_APP_FE_URL}${path}${id}`;

export const getTVRLink = (id: string) =>
  getBaseLink(EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH, id);

export const getToolLink = (id: string) =>
  getBaseLink(EXTERNAL_TOOL_BASE_ROUTE_PATH, id);

export const getWorkspaceLink = (id: string) =>
  getBaseLink(EXTERNAL_WORKSPACE_BASE_ROUTE_PATH, id);

export const getWorkflowLink = (id: string) =>
  getBaseLink(EXTERNAL_WORKFLOW_BASE_ROUTE_PATH, id);
