import React from "react";
import TipTapTextField from "../../../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import AuthenticationSplitter from "../../../../../../auth/AuthenticationSplitter";
import LoggedOutCTA from "../../../../../external/LoggedOutCTA";
import useSendThreadTextField from "./hooks/useSendThreadTextField";

import { UnableToRunAssistantCTA } from "./UnableToRunAssistantCTA";
import styles from "./sendThreadTextField.module.css";

function SendThreadTextFieldInner() {
  const { userTextMessage, handleChange, handleKeyDown, additionalActions } =
    useSendThreadTextField();

  return (
    <>
      <TipTapTextField
        initialValue={userTextMessage}
        richTextFieldClassName="p-0px-imp"
        label="Chat with Toolflow"
        onChange={handleChange}
        outerClassName={styles.outline}
        autoFocus={false}
        enableHover={false}
        variant="standard"
        disableOutlineHover
        saveWorkspaceType="json"
        disableFormatting
        preventDefaultEnter
        overrideKeydown={handleKeyDown}
        additionalActions={additionalActions}
      />
      <UnableToRunAssistantCTA />
    </>
  );
}

function SendThreadTextField() {
  return (
    <AuthenticationSplitter
      authenticatedComponent={<SendThreadTextFieldInner />}
      externalComponent={<LoggedOutCTA />}
    />
  );
}

export default SendThreadTextField;
