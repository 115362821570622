import React, { FC, useState } from "react";
import { useSettingsDropdownContext } from "../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import RenameHomePageEntityForm from "../../../entities/components/RenameHomePageEntityForm";
import RenameDialog from "../layout/RenameDialog";
import type { ValidateType } from "@toolflow/shared";
import { RenameIcon } from "../../../../globalTheme/icons/icons";

interface IRenameHomePageEntityMenuItemProps {
  entityId: string;
  entityType: ValidateType;
  entityName: string;
}
const RenameHomePageEntityMenuItem: FC<IRenameHomePageEntityMenuItemProps> = ({
  entityId,
  entityType,
  entityName
}) => {
  const { handleClose } = useSettingsDropdownContext();
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };
  return (
    <>
      <SettingsMenuItem
        icon={<RenameIcon size={16} />}
        text="Rename"
        action={handleOpen}
      />
      <RenameDialog open={open} closeDialog={close} title={"Rename session"}>
        <RenameHomePageEntityForm
          initialName={entityName}
          id={entityId}
          entityType={entityType}
          completeAction={close}
        />
      </RenameDialog>
    </>
  );
};

export default RenameHomePageEntityMenuItem;
