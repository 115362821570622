import { ReadyState } from "react-use-websocket";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores/store";
import useUpdatePercentCompleted from "./useUpdatePercentCompleted";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import { useWebsocketContext } from "../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";
import useToolStillLoading from "./useToolStillLoading";

const useStartCheckingFile = () => {
  const { sendMessage, readyState } = useWebsocketContext();
  const { componentId, tvrId } = useEditorToolCardContext();
  const intervalId = useRef<$TSFixMe>(null);
  const [cleared, setCleared] = useState(false);
  const toolStillLoading = useToolStillLoading();

  // we use this as a backup so that we make sure to get the full response
  // if we miss the other emitting for some reason
  const startCheckingFile = (responseId: string) => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => {
        if (readyState === ReadyState.OPEN && responseId && sendMessage) {
          sendMessage(
            JSON.stringify({
              type: "checkToolStatus",
              payload: {
                componentId,
                toolVersionResponseId: responseId
              }
            })
          );
        }
      }, 2000); // every 2 seconds
    }
  };

  // we only want this to run when the tool rerenders because of close tool
  // we set cleared to indicated that a tool output interval has already been cleared
  useEffect(() => {
    if (
      tvrId &&
      !intervalId.current &&
      readyState === ReadyState.OPEN &&
      tvrId &&
      componentId &&
      !cleared
    ) {
      startCheckingFile(tvrId);
    }
  }, [readyState]);

  const updatePercentCompleted = useUpdatePercentCompleted();

  const message = useSelector(
    (state: RootState) => state.layout.alertMessage.message
  );
  const messageType = useSelector(
    (state: RootState) => state.layout.alertMessage.messageType
  );

  const clear = () => {
    setCleared(true);
    clearInterval(intervalId.current);
    intervalId.current = null;
  };

  useEffect(() => {
    // we only want to update percent to 100 if the tool is actually loading,
    // otherwise other error messages (like uploading a file that is too big) will
    // make toolStillLoading in a weird state where the RunButton doesn't change state

    // We probably should limit this message to a specific message type, otherwise any message can make this a problem
    if (message && messageType !== "success" && toolStillLoading) {
      updatePercentCompleted(100);
    }
    // Cleanup function that will run when the component unmounts
    return () => {
      // Clear the interval when the component is unmounted
      if (intervalId.current) {
        clear();
      }
    };
  }, [message]);

  return { startCheckingFile, clear };
};

export default useStartCheckingFile;
