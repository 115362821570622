import { Table, TableBody, TableContainer } from "@mui/material";
import type { Workspace } from "@toolflow/shared";
import React, { FC } from "react";
import BaseSettingsDropdown from "../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import WorkspaceTableRow from "./WorkspaceTableRow";
import { convertWorkspaceTableData } from "../utlils/tableUtils";

interface IWorkspaceTableProps {
  workspaces: Workspace[];
}

const WorkspaceTable: FC<IWorkspaceTableProps> = (props) => {
  const { workspaces } = props;
  return (
    <BaseSettingsDropdown>
      <div className="p-h-16px">
        <TableContainer>
          <Table>
            <TableBody>
              {workspaces.map((workspace, index) => {
                return (
                  <WorkspaceTableRow
                    key={index}
                    row={convertWorkspaceTableData(workspace)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </BaseSettingsDropdown>
  );
};

export default WorkspaceTable;
