import { FormControl, IconButton } from "@mui/material";
import React from "react";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { DeleteIcon } from "../../../../../../globalTheme/icons/icons";

function DeleteInputButton({ disabled }: { disabled?: boolean }) {
  const index = useGetCurrentToolInputFieldIndex();
  const { deleteInputField } = useToolInputFieldsContext();

  return (
    <FormControl disabled={disabled}>
      <IconButton onClick={() => deleteInputField(index)} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    </FormControl>
  );
}

export default DeleteInputButton;
