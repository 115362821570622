import type { CustomToolInputField } from "@toolflow/shared";
import { isInputWithOptions } from "../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { getToolInputFieldIndexById } from "../../../../../../tools/components/editorToolCard/inputs/inputBuilders/hooks/useGetToolInputFieldIndexById";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";
import useSetToolInputFieldsWorkflow from "./useSetToolInputFieldsWorkflow";

const useAddInputOptionToolbuilder = () => {
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const setToolInputFields = useSetToolInputFieldsWorkflow();

  const handleAddOption = (toolInputField: CustomToolInputField) => {
    if (!isInputWithOptions(toolInputField)) {
      console.log("Input field does not support options.");
      return;
    }

    const options = toolInputField.options || [];
    const updatedToolInputField = {
      ...toolInputField,
      options: [...options, ""]
    };
    const index = getToolInputFieldIndexById(
      inputsToCollect,
      toolInputField.id
    );
    const updatedToolInputFields = [...inputsToCollect];
    updatedToolInputFields[index] = updatedToolInputField;

    setToolInputFields(updatedToolInputFields);
  };

  return handleAddOption;
};

export default useAddInputOptionToolbuilder;
