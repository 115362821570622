import React from "react";
import type {
  MarketplaceEntityAutocomplete,
  TDisabledFields,
  TMarginTypes,
  TSelectAutocomplete
} from "@toolflow/shared";
import { Autocomplete, FormControl } from "@mui/material";
import SelectorInput from "./SelectorInput";
import OptionSplitter from "./OptionSplitter";
import getFilterToolOptions from "../functions/getFilterToolOptions";
import EntityNotFoundBase from "./EntityNotFoundBase";
import FolderCircle from "../../../../globalTheme/icons/logos/FolderCircle";
import useMarketplaceSearch from "./hooks/useMarketplaceSearch";
import useGetFilteredOptionsForAutocomplete from "./hooks/useGetFilteredOptionsForAutocomplete";
import { CloseIcon } from "../../../../globalTheme/icons/icons";

// see index.css for some MuiAutocomplete styling
function UniversalAutocomplete({
  handleSelectEntity,
  disabledFields,
  margin = "none",
  className = "",
  formControlClassName = "",
  placeholder = "Search for tools or use cases",
  autoFocus = false
}: Readonly<{
  handleSelectEntity: TSelectAutocomplete;
  disabledFields?: TDisabledFields;
  margin?: TMarginTypes;
  className?: string;
  formControlClassName?: string;
  placeholder?: string;
  autoFocus?: boolean;
}>) {
  const filteredOptions = useGetFilteredOptionsForAutocomplete(disabledFields);

  const {
    open,
    handleClearSearch,
    userSearchValue,
    setOpen,
    userSelectedValue,
    handleChangeUserSelectedValue,
    setUserSearchValue
  } = useMarketplaceSearch({
    handleSelectEntity
  });

  return (
    <FormControl margin={margin} fullWidth className={formControlClassName}>
      <Autocomplete
        autoHighlight
        autoComplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        inputValue={userSearchValue}
        className={className}
        size="small"
        value={userSelectedValue}
        id="searchMarketplaceEntities-id"
        options={filteredOptions}
        groupBy={(option) => option.group}
        filterOptions={(options, params) => {
          return getFilterToolOptions(options, params);
        }}
        onChange={handleChangeUserSelectedValue}
        onInputChange={(_, value) => {
          setUserSearchValue(value);
        }}
        getOptionLabel={(option: MarketplaceEntityAutocomplete) =>
          option.primary
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { inputValue }) => (
          <OptionSplitter
            props={props}
            option={option}
            key={option.id}
            inputValue={inputValue}
          />
        )}
        noOptionsText={
          <EntityNotFoundBase
            className="flex align-i-center justify-center flex-column h-450px m-h-auto"
            icon={<FolderCircle />}
            header="Unfortunately, there are no results"
            subHeader="Please try another phrase"
          />
        }
        componentsProps={{
          clearIndicator: <CloseIcon fontSize={"small"} />
        }}
        fullWidth
        renderInput={(params) => {
          console.log(params);
          return (
            <SelectorInput
              params={{
                ...params
              }}
              placeholder={placeholder}
              onClear={handleClearSearch}
              autoFocus={autoFocus}
            />
          );
        }}
      />
    </FormControl>
  );
}

export default UniversalAutocomplete;
