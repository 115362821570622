import React from "react";
import { InputContextComponent } from "../../../../../../utilities/contexts/InputContext";
import useWorkspaceInputById from "../../../hooks/useWorkspaceInputById";
import {
  DALLE_INPUT,
  SHEET_INPUT,
  STRING_INPUT
} from "../../../helpers/workspaceConstants";
import DallEAsset from "./dallEAsset/DallEAsset";
import SheetWorkspaceContainer from "./sheetAsset/sheet/SheetWorkspaceContainer";
import TextAsset from "./textAsset/TextAsset";

const componentsMap = {
  [STRING_INPUT]: TextAsset,
  [SHEET_INPUT]: SheetWorkspaceContainer,
  [DALLE_INPUT]: DallEAsset
};

function AssetSwitch({ assetId }: { assetId: string }) {
  const input = useWorkspaceInputById({ id: assetId });

  if (!input) {
    return null;
  }

  const Component = componentsMap[input.valueType];

  return (
    <>
      {Component ? (
        <InputContextComponent input={input}>
          <div className="pos-relative w-100-percent h-100-percent flex">
            <Component />
          </div>
        </InputContextComponent>
      ) : null}
    </>
  );
}

export default AssetSwitch;
