import React from "react";
import useHandleAddToolInputFieldWorkflow from "./hooks/useHandleAddToolInputFieldWorkflow";
import useAddInputOptionWorkflow from "./hooks/useAddInputOptionWorkflow";
import useChangeInputDescriptionToolbuiler from "./hooks/useChangeInputFieldDescriptionWorkflow";
import useChangeInputNameWorkflow from "./hooks/useChangeInputNameWorkflow";
import useDeleteToolInputFieldWorkflow from "./hooks/useDeleteToolInputFieldWorkflow";
import useChangeInputTypeWorkflow from "./hooks/useChangeInputTypeWorkflow";
import useDeleteOptionWorkflow from "./hooks/useDeleteOptionWorkflow";
import useChangeOptionNameWorkflow from "./hooks/useChangeOptionNameWorkflow";
import useSetToolInputFieldsWorkflow from "./hooks/useSetToolInputFieldsWorkflow";
import { ToolInputFieldsContextProvider } from "../../../../../tools/components/editorToolCard/inputs/inputBuilders/contexts/ToolInputFieldsContext";
import CustomToolInputFields from "../../../../../tools/components/editorToolCard/inputs/inputBuilders/CustomToolInputFields";
import useGetSelectedStepInputsToCollect from "./hooks/useGetSelectedStepInputToCollect";

function CustomToolInputFieldWorkflowProvider({
  className = ""
}: {
  className?: string;
}) {
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const addToolInputFieldWorkflow = useHandleAddToolInputFieldWorkflow();
  const addToolInputOption = useAddInputOptionWorkflow();
  const changeInputDescription = useChangeInputDescriptionToolbuiler();
  const changeInputName = useChangeInputNameWorkflow();
  const deleteInputField = useDeleteToolInputFieldWorkflow();
  const changeInputType = useChangeInputTypeWorkflow();
  const deleteOption = useDeleteOptionWorkflow();
  const changeOptionName = useChangeOptionNameWorkflow();
  const setToolInputFields = useSetToolInputFieldsWorkflow();

  return (
    <ToolInputFieldsContextProvider
      toolInputFields={inputsToCollect}
      addToolInputField={addToolInputFieldWorkflow}
      addToolInputOption={addToolInputOption}
      changeInputDescription={changeInputDescription}
      changeInputName={changeInputName}
      deleteInputField={deleteInputField}
      changeInputType={changeInputType}
      deleteOption={deleteOption}
      changeOptionName={changeOptionName}
      setToolInputFields={setToolInputFields}
    >
      <CustomToolInputFields className={className} />
    </ToolInputFieldsContextProvider>
  );
}

export default CustomToolInputFieldWorkflowProvider;
