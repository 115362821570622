import { type JSONContent, generateText } from "@tiptap/core";
import useExtensions from "./useExtension";

const useGenerateTextFromJSON = () => {
  const extensions = useExtensions();
  const getInputHtml = (jsonToConvert: JSONContent) => {
    return generateText(jsonToConvert, extensions);
  };
  return getInputHtml;
};

export default useGenerateTextFromJSON;
