import React from "react";
import { useGetSecretsQuery } from "../../../../../../ToolflowAPI/rtkRoutes/secretApi";
import getBlockIcon from "../../../../../blocks/getBlockIcon";
import type { TSecretType } from "@toolflow/shared";

export type TLLMModelRow = {
  id: string;
  apiKey: string;
  name: string;
  hasValue: boolean;
  type: TSecretType;
  logo: React.ReactNode;
};
const useGetLLMTableData = () => {
  const { data = { openAI: [], deepgram: [], anthropic: [] } } =
    useGetSecretsQuery();
  console.log(data);
  let openAIRows: TLLMModelRow[] = data?.openAI?.map((secret) => ({
    id: secret._id,
    apiKey: "sk-...".concat(secret.keyRef),
    name: "OpenAI",
    hasValue: true,
    type: "openAI",
    logo: getBlockIcon("OpenAI")
  }));

  openAIRows =
    openAIRows.length > 0
      ? openAIRows
      : [
          {
            id: "openAI",
            apiKey: "Not added",
            name: "OpenAI",
            hasValue: false,
            type: "openAI",
            logo: getBlockIcon("OpenAI")
          }
        ];

  let deepgramRows: TLLMModelRow[] = data?.deepgram?.map((secret) => ({
    id: secret._id,
    apiKey: "...".concat(secret.keyRef),
    name: "Deepgram",
    hasValue: true,
    type: "deepgram",
    logo: getBlockIcon("Deepgram")
  }));

  deepgramRows =
    deepgramRows.length > 0
      ? deepgramRows
      : [
          {
            id: "deepgram",
            apiKey: "Not added",
            name: "Deepgram",
            hasValue: false,
            type: "deepgram",
            logo: getBlockIcon("Deepgram")
          }
        ];
  const tableRows = [...openAIRows, ...deepgramRows];
  return {
    tableRows
  };
};

export default useGetLLMTableData;
