import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { security } from "./security";
import { RootState } from "../stores/store";
import { isLoginOrConsentRequiredError } from "../features/auth/functions/isLoginOrConsentRequiredError";

const EXTERNAL_API_REDUCER_NAME: TExternalApiReducerName = "externalApi";
type TExternalApiReducerName = "externalApi";

export const externalApi = createApi({
  reducerPath: EXTERNAL_API_REDUCER_NAME,
  baseQuery: fetchBaseQuery({
    baseUrl:
      `${process.env.REACT_APP_API_URL}/external/` ||
      "https://api.toolflow.ai/external/",
    prepareHeaders: async (headers, apiBase) => {
      try {
        const state = apiBase.getState() as RootState;
        headers.set("x-client-id", state.auth.clientId);
        return headers;
      } catch (error) {
        if (isLoginOrConsentRequiredError(error)) {
          const loginFunc = security.getLoginBackToPage();
          if (loginFunc) {
            loginFunc();
          }
        } else {
          console.error("Error in prepareHeaders", error);
        }
      }
      return headers;
    }
  }),
  endpoints: () => ({})
});
