import {
  StarFillIcon,
  NoToolsIcon
} from "../../../../../globalTheme/icons/icons";

export const tabsContentDict: Record<string, $TSFixMe> = {
  userTools: {
    header: "No tools found",
    subheader: `Build your own tools and they will appear here`,
    actionButtonText: "Build a tool",
    icon: NoToolsIcon,
    className: "primary-purple"
  },
  starred: {
    header: "All your starred tools will appear here",
    subheader: `Check out the marketplace to discover some of the best tools`,
    icon: StarFillIcon,
    actionButtonText: "Explore the marketplace",
    className: "primary-purple"
  },
  marketplace: {
    header: "No tools found",
    subheader: `Check out the "All" section to discover some of the best tools`,
    actionButtonText: "Explore all tools",
    icon: NoToolsIcon
  }
};
export const defaultContentValues = {
  header: "No tools found",
  subheader: `Check out the marketplace to discover some of the best tools`,
  actionButtonText: "Explore marketplace tools",
  icon: NoToolsIcon
};

export const getTabsContent = (tab: string) => {
  return tabsContentDict[tab] || defaultContentValues;
};
