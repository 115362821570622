import { type JSONContent, generateJSON } from "@tiptap/core";
import { getHtmlFromText } from "../../sanitizeHtml";
import useExtensions from "../../../../components/textFields/workspace/hooks/useExtension";

const useConvertStringToTipTap = () => {
  const extensions = useExtensions();
  const convertTextToTipTap = (value: string): JSONContent => {
    return generateJSON(getHtmlFromText(value as string), extensions);
  };

  return convertTextToTipTap;
};

export default useConvertStringToTipTap;
