import React, { useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  ToolBuilderContextComponent,
  useToolbuilderContext
} from "./context/ToolBuilderContext";
import type { GetToolVersionResponseFE } from "@toolflow/shared";
import Typography from "@mui/material/Typography";
import { getErrorMessage } from "../../../../ToolflowAPI/errors/getErrorMessage";
import FlowBuilder from "./FlowBuilder";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../../layout/alertMessageSnackbar/alertMessageSlice";
import { RootState } from "../../../../stores/store";
import { useLazyGetToolVersionsQuery } from "../../../../ToolflowAPI/rtkRoutes/toolVersionApi";
import { HOME_ROUTE_PATH_BASE } from "../../../navigation/helpers/routePaths";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";

function ToolBuilderInner() {
  const { toolId = "" } = useParams();
  const { state, dispatch } = useToolbuilderContext();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const parseResponse = (resp: GetToolVersionResponseFE) => {
    dispatch({
      type: "RECEIVE_TOOL_VERSIONS",
      toolAbout: resp.tool.about || "",
      toolName: resp.tool.name,
      toolVersions: resp.toolVersions,
      main: resp.tool.main,
      creator: resp.tool.creator,
      toolId: resp.tool._id,
      tempViewport: null,
      visibility: resp.tool.visibility,
      tag: resp.tool.tag
    });
  };

  const message = useSelector((s: RootState) => s.layout.alertMessage.message);

  useEffect(() => {
    if (message) {
      dispatch({ type: "SET_TOOL_LOADING_FALSE" });
    }
  }, [message]);

  const navigateHome = (e: unknown) => {
    reduxDispatch(setErrorMessage(getErrorMessage(e, "Tool not found")));
    dispatch({ type: "SET_TOOL_LOADING_FALSE" });
    navigate(HOME_ROUTE_PATH_BASE);
  };

  const [getToolVersions] = useLazyGetToolVersionsQuery();
  const { isAuthenticated } = useAuthStore();

  // this needs to be refactored when moving toolbuilder to store
  useEffect(() => {
    // in the case of going from external tool to the same tool internally, the
    // toolid will stay the sample. However the internal tool wont have
    // main set, so dispatch it

    const fetchData = async () => {
      if (toolId) {
        if (state.toolId !== toolId) {
          try {
            await getToolVersions(toolId)
              .unwrap()
              .then((resp) => {
                parseResponse(resp);
              });
          } catch (e) {
            navigateHome(e);
          }
        }
      } else {
        dispatch({ type: "NEW_TOOL" });
      }
    };
    if (isAuthenticated) {
      fetchData();
    }
  }, [toolId, state.toolId, isAuthenticated]);

  if (isMediumScreen) {
    return (
      <div className="w-100-percent justify-center align-i-center flex h-100vh">
        <Typography>Please use a bigger screen</Typography>
      </div>
    );
  }

  return (
    <div className="oflow-hidden">
      <FlowBuilder />
    </div>
  );
}

function ToolBuilderWrapper() {
  return (
    <ToolBuilderContextComponent>
      <ToolBuilderInner />
    </ToolBuilderContextComponent>
  );
}

export default ToolBuilderWrapper;
