import type { Content } from "@tiptap/core";
import useConvertContentToHtml from "./useConvertContentToHtml";
import { convertHtmlToMarkdown } from "../../convertHtmlToMarkdown";

export const useConvertContentToMarkdown = () => {
  const convertContentToHtml = useConvertContentToHtml();
  const convertContentToMarkdown = (content: Content) => {
    const html = convertContentToHtml(content);
    const markdown = convertHtmlToMarkdown(html);
    return markdown;
  };
  return convertContentToMarkdown;
};
