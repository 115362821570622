import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatePaths } from "../navigationSlice";

const useRouterListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updatePaths({
        path: location.pathname,
        replaced: location.state?.replaced
      })
    );
  }, [location, dispatch]);

  return null;
};

export default useRouterListener;
