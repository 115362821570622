import useWorkspaceInputById from "../../features/pages/workstation/hooks/useWorkspaceInputById";
import React from "react";
import { InputContextComponent } from "./InputContext";

function InputContextComponentContainer({
  inputId,
  children
}: {
  inputId: string;
  children: React.ReactNode;
}) {
  const input = useWorkspaceInputById({ id: inputId });
  return (
    <InputContextComponent input={input}>{children}</InputContextComponent>
  );
}

export default InputContextComponentContainer;
