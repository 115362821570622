import type { TScraperSettings } from "@toolflow/shared";
import { ChangeEventHandler, useState } from "react";
import { CUSTOM_INPUT } from "../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import useToolInputFieldsAndAvailableFields from "../../../useToolInputFieldsAndAvailableFields";
import useUpdateField from "../../../useUpdateField";

export default function useScraperSelectInputUpdate(
  id: string,
  settings: TScraperSettings
) {
  const { inputType, urlFieldInputKey } = settings;
  const updateField = useUpdateField(id);
  const inputTextFields = useToolInputFieldsAndAvailableFields(id);
  const isURLFieldInputKeyFromTextBlock = inputTextFields.some(
    (field) => field.name === urlFieldInputKey
  );
  const [inputTypeValue, setInputTypeValue] = useState(inputType);
  const [customInputValue, setCustomInputValue] = useState(
    isURLFieldInputKeyFromTextBlock ? "" : urlFieldInputKey
  );

  const selectOptions = [
    { label: "+ Insert fixed URL", value: CUSTOM_INPUT },
    ...inputTextFields.map((inputTextField) => ({
      label: inputTextField.name,
      value: inputTextField.name
    }))
  ];

  const updateFromFieldName = (fieldName: string) => {
    setInputTypeValue(fieldName);
    updateField(fieldName, "settings.urlFieldInputKey");
    updateField(fieldName, "settings.inputType");
  };

  const updateInputValue: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCustomInputValue(event.target.value);
    if (inputTypeValue === CUSTOM_INPUT) {
      updateField(event.target.value, "settings.urlFieldInputKey");
    }
  };

  const updateSelect = (event: { target: { value: string } }) => {
    setInputTypeValue(event.target.value);
    if (event.target.value === CUSTOM_INPUT) {
      setCustomInputValue(customInputValue || "");
      updateField(customInputValue || "", "settings.urlFieldInputKey");
      updateField(event.target.value, "settings.inputType");
    } else {
      updateField(event.target.value, "settings.urlFieldInputKey");
      updateField(event.target.value, "settings.inputType");
    }
  };

  return {
    selectOptions,
    inputTypeValue,
    customInputValue,
    updateSelect,
    updateInputValue,
    updateFromFieldName
  };
}
