import { TOnDrop } from "../../../../../../../utilities/components/draggables/helpers/draggableConstants";
import { useToolInputFieldsContext } from "../contexts/ToolInputFieldsContext";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";

const useInputDrop = () => {
  const { toolInputFields, setToolInputFields } = useToolInputFieldsContext();

  const handleDragEnd: TOnDrop = ({ source, location }) => {
    const target = location.current.dropTargets[0];
    if (!target || target.data.type !== "toolInput") {
      return;
    }
    const updatedToolInputFields = [...toolInputFields];
    const sourceData = source.data;
    const startIndex = sourceData.index as number;
    const targetData = target.data;
    const indexOfTarget = targetData.index as number;
    const closestEdgeOfTarget = extractClosestEdge(targetData);
    const finishIndex = getReorderDestinationIndex({
      startIndex,
      closestEdgeOfTarget,
      indexOfTarget,
      axis: "vertical"
    });
    const [removed] = updatedToolInputFields.splice(startIndex, 1);
    updatedToolInputFields.splice(finishIndex, 0, removed);
    setToolInputFields(updatedToolInputFields);
  };

  return handleDragEnd;
};

export default useInputDrop;
