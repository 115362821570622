import type { SelectChangeEvent } from "@mui/material/Select";
import type { TScraperSettings } from "@toolflow/shared";
import { FC } from "react";
import useScraperSelectInputUpdate from "../../common/selectInputSetting/hooks/useScraperSelectInputUpdate";
import SelectInputSetting from "../../common/selectInputSetting/SelectInputSetting";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../useUpdateField";
import SelectPreferredScraperResultType from "./SelectPreferredScraperResultType";

interface IScraperSettingsProps {
  id: string;
  settings: TScraperSettings;
}

const ScraperSettings: FC<IScraperSettingsProps> = (props) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  useSyncCurrentAndPrevSettings(settings);

  const selectInputUpdateProps = useScraperSelectInputUpdate(id, settings);

  const updateStateValue =
    (kString: keyof TScraperSettings) => (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `settings.${kString}`);
    };

  return (
    <>
      <div className="p-h-16px">
        <SelectInputSetting
          {...selectInputUpdateProps}
          id={id}
          label="URL source"
          customLabel="URL"
          customErrorText="No mapped url input to scrape."
        />
        <SelectPreferredScraperResultType
          settings={settings}
          handleChange={updateStateValue("preferredScraperResultType")}
        />
      </div>
    </>
  );
};

export default ScraperSettings;
