import React from "react";
import SnippetFormCard from "./SnippetForm/SnippetFormCard";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../stores/store";
import { closeSnippetFormDialog } from "./slice/snippetSlice";

function AddSnippetFormDialog() {
  const dispatch = useDispatch();

  const { open, initialName, initialValue } = useSelector(
    (state: RootState) => state.snippet.snippetFormDialog
  );
  const closeDialog = () => {
    dispatch(closeSnippetFormDialog());
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        PaperProps={{
          className: `w-100-percent`
        }}
      >
        <DialogTitle>Add new snippet</DialogTitle>
        <DialogContent>
          <SnippetFormCard
            initialName={initialName}
            initialValue={initialValue}
            className="no-border p-0px"
            initialStateIsDirty
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddSnippetFormDialog;
