import type {
  InputNodeTypes,
  ToolAutocomplete,
  WorkflowInput
} from "@toolflow/shared";
import {
  STRING_INPUT,
  VARIABLE_NAME
} from "../../../../features/pages/workstation/helpers/workspaceConstants";

function formatInputsForAutocomplete(
  inputs: WorkflowInput[],
  inputType: InputNodeTypes,
  id?: string
): ToolAutocomplete[] {
  return inputs
    .filter(
      (i) =>
        i.valueType === STRING_INPUT &&
        i.id !== id && // we don't want inputs to be able to reference themselves
        (inputType === "chip" ? true : i.source !== "toolOutputPlaceholder")
    )
    .map((i) => {
      return {
        primary: i.name || VARIABLE_NAME,
        id: i.id || "",
        secondary: (i.value as string) || "",
        group: VARIABLE_NAME
      };
    });
}

export default formatInputsForAutocomplete;
