import type { ProfileWorkflow, TMarketplaceEntity } from "@toolflow/shared";

function convertProfileWorkflowToMarketplaceEntity(
  workflow: ProfileWorkflow
): TMarketplaceEntity {
  return {
    id: workflow.id,
    name: workflow.name,
    description: workflow.description || "",
    userId: workflow.userId,
    isFeatured: !!workflow.isFeatured,
    isPopular: !!workflow.isPopular,
    visibility: workflow.visibility,
    userFavorited: !!workflow.userFavorited,
    tag: workflow.tag,
    updatedAt: workflow.updatedAt,
    type: "workflow"
  };
}

export default convertProfileWorkflowToMarketplaceEntity;
