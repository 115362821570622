import React, { useState, useEffect } from "react";
import ToolWithinToolBlockInner from "./ToolWithinToolBlockInner";
import BlockWrapper from "../BlockWrapper";
import { isEqual } from "lodash";
import type { ToolWithinToolBlockProps } from "@toolflow/shared";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const ToolWithinToolBlockDrawer: React.FC<ToolWithinToolBlockProps> = ({
  data,
  selected,
  id
}) => {
  const { state } = useToolbuilderContext();
  const { blocks: blocks } = state.currentState;
  const [prevBlocks, setPrevBlocks] = useState(blocks);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(blocks, prevBlocks)) {
      // Nodes have changed, trigger re-render
      // Update the previous blocks value
      setPrevBlocks(blocks);
    }
  }, [blocks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BlockWrapper id={id} data={data} selected={selected}>
      <ToolWithinToolBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default ToolWithinToolBlockDrawer;
