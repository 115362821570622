import type {
  CustomToolInputFieldTypes,
  CustomToolOutputFieldTypes
} from "@toolflow/shared";
import { LARGE_TEXTFIELD, TEXTFIELD } from "./inputConstants";
import { COPYABLE_LARGE_TEXTFIELD } from "./outputConstants";

export const textFieldTypeArray: Array<
  CustomToolInputFieldTypes | CustomToolOutputFieldTypes
> = [LARGE_TEXTFIELD, TEXTFIELD, COPYABLE_LARGE_TEXTFIELD];
