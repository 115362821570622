import type { WorkflowInput } from "@toolflow/shared";
import { useWorkspaceInputsFieldArrayContext } from "../../../../../features/pages/workstation/contexts/WorkspaceInputsContext";
import useWorkspaceInputs from "../../../../../features/pages/workstation/hooks/useWorkspaceInputs";

const useHandleInputNameChange = ({ input }: { input: WorkflowInput }) => {
  const inputs = useWorkspaceInputs();
  const { update } = useWorkspaceInputsFieldArrayContext();
  const handleNameChange = (s: string) => {
    inputs.map((i, index) => {
      if (i.id === input.id) {
        update(index, {
          ...i,
          name: s
        });
      }
    });
  };
  return handleNameChange;
};

export default useHandleInputNameChange;
