import useApiHandlerWithAuth from "../axios/useApiHandlerWithAuth";
import type {
  UploadFileResponseFE,
  UserInputResponseWithHandledError
} from "@toolflow/shared";
import type { AxiosProgressEvent } from "axios";
import { useDispatch } from "react-redux";
import { authenticatedApi } from "../authenticatedAPI";

const useFileRoutes = () => {
  const { apiHandlerWithAuth, cancel } = useApiHandlerWithAuth();
  const reduxDispatch = useDispatch();

  const cancelUpload = async () => {
    try {
      await cancel();
      await apiHandlerWithAuth({ endpoint: `/partialFiles`, method: "DELETE" });
      reduxDispatch(authenticatedApi.util.invalidateTags(["File"]));
    } catch (error) {
      console.error("Error cancelling file:", error);
    }
  };

  const generateZip = async (
    dataToSend: UserInputResponseWithHandledError,
    handleError: () => void,
    parseResponse: (res: Blob) => void
  ) => {
    try {
      const result = await apiHandlerWithAuth({
        endpoint: `/generate-zip`,
        method: "POST",
        data: dataToSend,
        errorCallback: handleError,
        axiosOptions: { responseType: "blob" }
      });
      parseResponse(new Blob([result]));
    } catch (error) {
      console.error("Error cancelling file:", error);
    }
  };

  const uploadFile = async (
    params: { file: File; duration?: number },
    parseResponse: (resp: UploadFileResponseFE) => void,
    loadingFalse: () => void,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  ) => {
    try {
      const data = new FormData();
      const { file, duration } = params;
      data.append("file", file);
      if (duration) {
        data.append("duration", duration.toString());
      }
      const result: UploadFileResponseFE = await apiHandlerWithAuth({
        endpoint: "/uploadFile",
        method: "POST",
        data,
        onUploadProgress
      });
      parseResponse(result);
    } catch (error) {
      loadingFalse();
      console.error("Error uploading file:", error);
    }
  };

  return {
    uploadFile,
    cancelUpload,
    generateZip
  };
};

export default useFileRoutes;
