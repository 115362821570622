import React from "react";
import useHandleAddToolInputFieldToolbuilder from "./hooks/useHandleAddToolInputFieldToolbuilder";
import useAddInputOptionToolbuilder from "./hooks/useAddInputOptionToolbuilder";
import useChangeInputDescriptionToolbuiler from "./hooks/useChangeInputFieldDescriptionToolbuilder";
import useChangeInputNameToolbuilder from "./hooks/useChangeInputNameToolbuilder";
import useDeleteToolInputFieldToolbuilder from "./hooks/useDeleteToolInputFieldToolbuilder";
import useChangeInputTypeToolbuilder from "./hooks/useChangeInputTypeToolbuilder";
import useDeleteOptionToolbuilder from "./hooks/useDeleteOptionToolbuilder";
import useChangeOptionNameToolbuilder from "./hooks/useChangeOptionNameToolbuilder";
import { ToolInputFieldsContextProvider } from "../../../../tools/components/editorToolCard/inputs/inputBuilders/contexts/ToolInputFieldsContext";
import CustomToolInputFields from "../../../../tools/components/editorToolCard/inputs/inputBuilders/CustomToolInputFields";
import useSetToolInputFieldsToolbuilder from "./hooks/useSetToolInputFieldsToolbuilder";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

function CustomToolInputFieldToolbuilderProvider({
  className = ""
}: {
  className?: string;
}) {
  const { state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;
  const addToolInputFieldToolbuilder = useHandleAddToolInputFieldToolbuilder();
  const addToolInputOption = useAddInputOptionToolbuilder();
  const changeInputDescription = useChangeInputDescriptionToolbuiler();
  const changeInputName = useChangeInputNameToolbuilder();
  const deleteInputField = useDeleteToolInputFieldToolbuilder();
  const changeInputType = useChangeInputTypeToolbuilder();
  const deleteOption = useDeleteOptionToolbuilder();
  const changeOptionName = useChangeOptionNameToolbuilder();
  const setToolInputFields = useSetToolInputFieldsToolbuilder();

  return (
    <ToolInputFieldsContextProvider
      toolInputFields={toolInputFields}
      addToolInputField={addToolInputFieldToolbuilder}
      addToolInputOption={addToolInputOption}
      changeInputDescription={changeInputDescription}
      changeInputName={changeInputName}
      deleteInputField={deleteInputField}
      changeInputType={changeInputType}
      deleteOption={deleteOption}
      changeOptionName={changeOptionName}
      setToolInputFields={setToolInputFields}
    >
      <CustomToolInputFields className={className} />
    </ToolInputFieldsContextProvider>
  );
}

export default CustomToolInputFieldToolbuilderProvider;
