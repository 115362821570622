import React from "react";
import { CardContent, CardHeader, IconButton } from "@mui/material";
import CustomToolInputFieldToolbuilderProvider from "./customToolInputFields/CustomToolInputFieldToolbuilderProvider";
import { CloseIcon } from "../../../../globalTheme/icons/icons";
import useCloseDrawer from "./store/hooks/useCloseDrawer";

function ToolInputDrawer() {
  const closeDrawer = useCloseDrawer();
  return (
    <>
      <CardHeader
        title="User Inputs"
        action={
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <CustomToolInputFieldToolbuilderProvider />
      </CardContent>
    </>
  );
}

export default ToolInputDrawer;
