import { useMemo } from "react";
import type { TDisabledEntities, TMarketplaceEntity } from "@toolflow/shared";
import { useGetMemoizedEntityFilters } from "./useGetMemoizedEntityFilters";
import { filterMarketplaceEntities } from "../../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { useAllMarketplaceEntities } from "./useAllMarketplaceEntities";

export const useFilteredEntities = (
  entities: TMarketplaceEntity[],
  disabledEntities?: TDisabledEntities
): TMarketplaceEntity[] => {
  const memoizedFilters = useGetMemoizedEntityFilters();

  return useMemo(() => {
    return filterMarketplaceEntities(
      entities,
      memoizedFilters,
      disabledEntities
    ).sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });
  }, [
    entities,
    memoizedFilters.selectedCategory,
    memoizedFilters.selectedUseCases,
    JSON.stringify(disabledEntities)
  ]);
};

export const useFilteredMarketplaceEntities = (): TMarketplaceEntity[] => {
  const entities = useAllMarketplaceEntities();
  return useFilteredEntities(entities);
};
