import { useDispatch } from "react-redux";
import { setDrawer } from "../toolBuilderSlice";

const useCloseDrawer = () => {
  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(setDrawer(null));
  };
  return closeDrawer;
};

export default useCloseDrawer;
