import { useWorkspaceInputsFieldArrayContext } from "../contexts/WorkspaceInputsContext";
import useWorkspaceInputs from "./useWorkspaceInputs";

function useDeleteInput() {
  const inputs = useWorkspaceInputs();
  const { remove } = useWorkspaceInputsFieldArrayContext();

  return (id: string) => {
    inputs.map((input, index) => {
      if (input.id === id) {
        remove(index);
      }
    });
  };
}

export default useDeleteInput;
