import type {
  ButtonVariantTypes,
  TFreePlan,
  THyperlineOrganizationCustomer,
  THyperlineOrganizationPlanIdDevOrStaging,
  THyperlineOrganizationPlanIdProduction,
  THyperlineProPlanIdDevOrStaging,
  THyperlineProPlanIdProduction,
  THyperlineUserCustomer,
  TOrganizationPlan,
  TProPlan,
  TProductionEnvironment
} from "@toolflow/shared";

// I don't love redeclaring the types here, but shared can't have consts
// right now. We make sure to declare types here to make sure
// backend and frontend are in sync

export const hyperlineProPlanIdProduction: THyperlineProPlanIdProduction =
  "plan_LUE5hrnk1P5BRJ";
export const hyperlineOrganizationPlanIdProduction: THyperlineOrganizationPlanIdProduction =
  "plan_AeTOJvuLZNsTne";

export const hyperlineProPlanIdDevOrStaging: THyperlineProPlanIdDevOrStaging =
  "plan_Sq95HJqIiF-fua";
export const hyperlineOrganizationPlanIdDevOrStaging: THyperlineOrganizationPlanIdDevOrStaging =
  "plan_zZutDxM35ni71a";

export const ORGANIZATION_PLAN_FE: TOrganizationPlan = "organization";
export const PRO_PLAN_FE: TProPlan = "pro";
export const FREE_PLAN_FE: TFreePlan = "free";

export const HYPERLINE_CUSTOMER_ORGANIZATION: THyperlineOrganizationCustomer =
  "organization";
export const HYPERLINE_CUSTOMER_USER: THyperlineUserCustomer = "user";

export const PRODUCTION_ENVIRONMENT: TProductionEnvironment = "production";
export const HYPERLINE_PRODUCTION = "production";
export const HYPERLINE_SANDBOX = "sandbox";

export const DOWNGRADE = "downgrade";
export const UPGRADE = "upgrade";
export const CURRENT_SUBSCRIPTION = "currentSubscription";
export const PRO_UPGRADE = "proUpgrade";
export const ORGANIZATION_UPGRADE = "organizationUpgrade";

export type TDowngrade = typeof DOWNGRADE;
export type TUpgrade = typeof UPGRADE;
export type TUpgradeToOrganization = typeof ORGANIZATION_UPGRADE;
export type TUpgradeToPro = typeof PRO_UPGRADE;
export type TCurrentSubscription = typeof CURRENT_SUBSCRIPTION;

export const tierStatusParameters: {
  [key in
    | TDowngrade
    | TUpgrade
    | TCurrentSubscription
    | TUpgradeToOrganization
    | TUpgradeToPro]: {
    text: string;
    disabled: boolean;
    variant: ButtonVariantTypes;
  };
} = {
  [DOWNGRADE]: {
    text: "Downgrade",
    disabled: false,
    variant: "outlined"
  },
  [UPGRADE]: {
    text: "Upgrade",
    disabled: false,
    variant: "contained"
  },
  [CURRENT_SUBSCRIPTION]: {
    text: "Current Subscription",
    disabled: true,
    variant: "contained"
  },
  [ORGANIZATION_UPGRADE]: {
    text: "Upgrade To Organization",
    disabled: false,
    variant: "contained"
  },
  [PRO_UPGRADE]: {
    text: "Upgrade To Pro",
    disabled: false,
    variant: "contained"
  }
};
