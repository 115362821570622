import useGetWorkflowFromQuery from "../../../workflow/hooks/useGetWorkflowFromQuery";
import { WORKFLOW } from "../../../../utilities/constants/constants";
import { useGetSelectedEntityType } from "./useGetSelectedEntityType";
import { useGetSelectedEntityId } from "./useGetSelectedEntityId";

const useGetSelectedWorkspaceWorkflow = () => {
  const entityId = useGetSelectedEntityId();
  const type = useGetSelectedEntityType();
  return useGetWorkflowFromQuery(entityId, { skip: type !== WORKFLOW });
};

export default useGetSelectedWorkspaceWorkflow;
