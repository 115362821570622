import React from "react";
import InputContextComponentContainer from "../../../../../../../../utilities/contexts/InputContextContainer";
import AssetListItem from "../assetListItem/AssetListItem";

function AssetIterator({ assets }: { assets: string[] }) {
  return (
    <>
      {assets.map((assetId) => (
        <InputContextComponentContainer key={assetId} inputId={assetId}>
          <AssetListItem />
        </InputContextComponentContainer>
      ))}
    </>
  );
}

export default AssetIterator;
