import { useEffect, useState } from "react";
import { useBlockContext } from "../BlockContext";
import useGetDrawerNodeId from "../store/hooks/useGetDrawerNodeId";

// should refactor to redux
const useHandleDrawerSizeAndOpen = () => {
  const [open, setOpen] = useState(false);
  const [large, setLarge] = useState(false);
  const { id } = useBlockContext();
  const openNodeId = useGetDrawerNodeId();

  useEffect(() => {
    if (id === openNodeId) {
      setOpen(true);
    } else {
      setOpen(false);
      setLarge(false);
    }
  }, [openNodeId]);
  return { open, setOpen, large, setLarge };
};

export default useHandleDrawerSizeAndOpen;
