import type { WorkflowInputString } from "@toolflow/shared";
import React from "react";
import { useInputContext } from "../../../../../../../utilities/contexts/InputContext";
import ToolAndAssetNameContainer from "./ToolAndAssetName";
import TextAssetIcons from "./textAssetIcons/TextAssetIcons";

function AssetHeader({ toolId }: { toolId?: string }) {
  return (
    <div className="flex justify-space-between align-i-center p-v-24px asset-max-width m-h-auto">
      <ToolAndAssetNameContainer toolId={toolId} />
      <TextAssetIcons />
    </div>
  );
}

function AssetHeaderContainer() {
  const { input } = useInputContext<WorkflowInputString>();
  return <AssetHeader toolId={input.data?.toolId} />;
}

export default AssetHeaderContainer;
