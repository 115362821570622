import React, { useMemo, useState } from "react";
import { Typography, IconButton, Collapse } from "@mui/material";
import OutputLoadingWrapper from "../../../../../utilities/components/loading/HideWhenLoadingWrapper";
import { getHtmlFromText } from "../../../../../utilities/formatters/strings/sanitizeHtml";
import { GenericRichHtml } from "../../../../../utilities/components/textFields/workspace/GenericRichText";
import { useHandleCopyText } from "../../../../../utilities/components/copyContent/hooks/useHandleCopy";
import {
  ArrowUpSmallIcon,
  ArrowDownSmallIcon,
  CopyIcon
} from "../../../../../globalTheme/icons/icons";

const CopyableLargeTextField = ({
  disabledCopy,
  showLoading,
  value,
  name,
  tvrId
}: {
  disabledCopy?: boolean;
  showLoading?: boolean;
  value: string;
  name: string;
  tvrId?: string;
}) => {
  const [open, setOpen] = useState(true);
  const memoizedHtml = useMemo(() => getHtmlFromText(value), [value]);
  const handleCopy = useHandleCopyText();

  const handleCopyClick = () => {
    handleCopy(value, tvrId);
  };

  return (
    <OutputLoadingWrapper showLoading={showLoading}>
      <div>
        <div className="flex justify-space-between m-b-8px m-t-16px align-i-center">
          <div>
            <Typography variant="caption" color="GrayText">
              {name}
            </Typography>
            {open ? (
              <IconButton onClick={() => setOpen(false)}>
                <ArrowUpSmallIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setOpen(true)}>
                <ArrowDownSmallIcon fontSize="small" />
              </IconButton>
            )}
          </div>
          <div>
            <IconButton
              onClick={handleCopyClick}
              size="small"
              disabled={disabledCopy}
            >
              <CopyIcon />
            </IconButton>
          </div>
        </div>
        <Collapse in={open}>
          <GenericRichHtml
            html={memoizedHtml}
            toolVersionResponseId={tvrId}
            rerender
          />
        </Collapse>
      </div>
    </OutputLoadingWrapper>
  );
};

export default CopyableLargeTextField;
