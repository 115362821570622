import React from "react";
import BlockWrapper from "../BlockWrapper";
import type { SerpBlockProps } from "@toolflow/shared";
import useCurrentAndPreviousBlocksSync from "../../hooks/useCurrentAndPreviousBlocksSync";
import SerpBlockInner from "./SerpBlockInner";

const SerpBlockDrawer: React.FC<SerpBlockProps> = ({ data, selected, id }) => {
  useCurrentAndPreviousBlocksSync();
  return (
    <BlockWrapper id={id} data={data} selected={selected} noPadding>
      <SerpBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default SerpBlockDrawer;
