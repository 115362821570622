import type {
  CustomToolOutputField,
  UserInputDictType,
  WorkflowInput
} from "@toolflow/shared";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useToolContext } from "../../../tools/contexts/ToolContext";
import useAddToolInputsToWorkspace from "../../../tools/components/editorToolCard/hooks/useAddToolInputsToWorkspace";
import useSetVisibleAsset from "./useSetVisibleAsset";
import { toggleRefreshInputs } from "../state/workspaceSlice";

const useUpdateInputsOnToolOutputChange = ({
  currentInputs,
  toolOutput,
  componentId,
  addInputsCallback
}: {
  currentInputs: React.MutableRefObject<WorkflowInput[]>;
  toolOutput: UserInputDictType | null;
  componentId: string;
  addInputsCallback?: (id: string, t: CustomToolOutputField) => void;
}) => {
  const dispatch = useDispatch();
  const setVisibleAsset = useSetVisibleAsset();
  const { tool } = useToolContext();
  const addToolInputsToWorkspace = useAddToolInputsToWorkspace();

  const addedInputs = useRef(new Set());

  useEffect(() => {
    if (toolOutput) {
      const inputsToUpdate = currentInputs.current.filter(
        (input) =>
          (input.data?.outputId || "" in (toolOutput || {})) && input.loading // we only want to update inputs that are loading
      );
      addToolInputsToWorkspace({ componentId, tool, addInputsCallback });

      inputsToUpdate.map((i) => {
        if (!!i.value && !addedInputs.current.has(i.id)) {
          setVisibleAsset(i.id);
          // we only want to add inputs once, because people may drag and drop them elsewhere
          addedInputs.current.add(i.id);
        }
        dispatch(toggleRefreshInputs());
      });
    } else {
      addedInputs.current = new Set();
    }
  }, [toolOutput]);
};

export default useUpdateInputsOnToolOutputChange;
