import type { TBlocksTagsLabels } from "@toolflow/shared";
import React from "react";
import DalleLogo from "../../globalTheme/icons/logos/DalleLogo";
import OpenAILogo from "../../globalTheme/icons/logos/OpenAILogo";
import DeepgramLogo from "../../globalTheme/icons/logos/DeepgramLogo";
import AnthropicLogo from "../../globalTheme/icons/logos/AnthropicLogo";
import WebScraperLogo from "../../globalTheme/icons/logos/WebScraperLogo";
import DalleLogoSvg from "../../globalTheme/icons/images/openai-logomark.svg";
import WebScraperLogoSvg from "../../globalTheme/icons/images/Web_scraping_fill.svg";
import AnthropicLogoSvg from "../../globalTheme/icons/images/Anthropic.svg";
import DeepgramLogoSvg from "../../globalTheme/icons/images/Deepgram.svg";
import OpenAILogoSvg from "../../globalTheme/icons/images/ChatGptLogo.svg";

const blockLabelAndIconsDict: Record<TBlocksTagsLabels, JSX.Element> = {
  "Dall-E": <DalleLogo />,
  Serp: <DalleLogo />,
  "Web Scraper": <WebScraperLogo />,
  Anthropic: <AnthropicLogo />,
  Deepgram: <DeepgramLogo />,
  OpenAI: <OpenAILogo />
};
const blockLabelImageDict: Record<TBlocksTagsLabels, string> = {
  "Dall-E": DalleLogoSvg,
  Serp: DalleLogoSvg,
  "Web Scraper": WebScraperLogoSvg,
  Anthropic: AnthropicLogoSvg,
  Deepgram: DeepgramLogoSvg,
  OpenAI: OpenAILogoSvg
};
const getBlockIcon = (blockLabel: TBlocksTagsLabels) => {
  return blockLabelAndIconsDict[blockLabel];
};

export const getBlockIconImageSvg = (blockLabel: TBlocksTagsLabels) => {
  return blockLabelImageDict[blockLabel];
};
export default getBlockIcon;
