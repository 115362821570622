import React from "react";
import { useUtilityDraggableContext } from "./contexts/UtilityDraggableContext";
import { Box, type SxProps } from "@mui/material";
import useIsDragging from "./hooks/useIsDragging";
import { DraggableIcon } from "../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";

function UtilityDragHandle({
  className,
  disabled,
  sx
}: {
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
}) {
  const isDragging = useIsDragging();
  const { dragHandleRef } = useUtilityDraggableContext();
  // const { provided } = useUtilityDraggableContext();
  return (
    <Box
      ref={dragHandleRef}
      // {...provided.dragHandleProps}
      className={`flex align-i-center ${className}`}
      sx={sx}
    >
      <DraggableIcon
        style={
          disabled || isDragging
            ? {
                color: toolflowTextDisabledColor,
                cursor: "auto"
              }
            : { cursor: "grab" }
        }
      />
    </Box>
  );
}

export default UtilityDragHandle;
