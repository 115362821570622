import React from "react";
import useCategoryTags, { THandleChangeTags } from "./hooks/useCategoryTags";
import AssignTags from "./AssignTags";

import { useCaseTags } from "./constants";
import type { TUseCase } from "@toolflow/shared";

const AssignUseCases = ({
  handleSave,
  disabled,
  fetchedUseCasesTags,
  type,
  className = ""
}: {
  handleSave: (useCases: TUseCase[]) => void;
  disabled?: boolean;
  type: "workflow" | "tool";
  fetchedUseCasesTags: { id: string; selected: boolean; value: TUseCase }[];
  className?: string;
}) => {
  const initialSelectedTags = fetchedUseCasesTags.map((tag) => tag.value);

  const {
    handleChange,
    selectedTags,
    chipTags,
    handleRemoveSelectedTag,
    initialTags
  } = useCategoryTags({
    initialTags: useCaseTags,
    initialSelectedTags
  });

  const handleChangeTags: THandleChangeTags = ({ event, tagToBeRemoved }) => {
    if (tagToBeRemoved) {
      handleRemoveSelectedTag(tagToBeRemoved);
      const tagsToBeSaved = chipTags
        .filter((tag) => tag.id !== tagToBeRemoved.id)
        .map((tag) => tag.value) as TUseCase[];
      handleSave(tagsToBeSaved);
    } else if (event) {
      handleChange(event);
      const tagsToBeSaved = event.target.value as TUseCase[];
      handleSave(tagsToBeSaved);
    }
  };

  return (
    <AssignTags
      initialTags={initialTags}
      selectedTags={selectedTags}
      chipTags={chipTags}
      handleChangeTags={handleChangeTags}
      disableHelperText={false}
      helperText={`These use cases will help users find your ${type} in marketplace.`}
      label={`Add use cases to ${type}`}
      placeholder="Select use cases"
      disabled={disabled}
      className={className}
    />
  );
};

export default AssignUseCases;
