import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { AddIcon } from "../../../../../../../globalTheme/icons/icons";
import { type Content } from "@tiptap/core";
import useAddNewStringAsset from "../../../../hooks/assets/useAddNewStringAsset";
import useConvertContentToHtml from "../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertContentToHtml";

function AddThreadAsAssetButton({ content }: { content: Content }) {
  const addAssetContent = useAddNewStringAsset();
  const convertContentToHtml = useConvertContentToHtml();
  const handleClick = () => {
    const stringContent = convertContentToHtml(content);
    // there are extra new lines in the content, so we remove them
    // otherwise tiptap will add a lot of extra paragraphs
    addAssetContent(stringContent.replaceAll("\n", ""));
  };
  return (
    <RemixButton
      text="Add as asset"
      icon={AddIcon}
      onClick={handleClick}
      disabled={!content}
    />
  );
}

export default AddThreadAsAssetButton;
