import { Button } from "@mui/material";
import React from "react";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { AddIcon } from "../../../../../../globalTheme/icons/icons";

function AddInputButton() {
  const { addToolInputField } = useToolInputFieldsContext();

  return (
    <Button
      endIcon={<AddIcon />}
      variant="contained"
      onClick={() => {
        addToolInputField();
      }}
    >
      Request new input
    </Button>
  );
}

export default AddInputButton;
