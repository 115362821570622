import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_INPUTS_FIELD_LABEL } from "../helpers/workspaceConstants";

const useWorkspaceInputs = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_INPUTS_FIELD_LABEL) || [];
};

export default useWorkspaceInputs;
