import useGetToolFromQuery from "../../../tools/hooks/useGetToolFromQuery";
import { TOOL } from "../../../../utilities/constants/constants";
import { useGetSelectedEntityType } from "./useGetSelectedEntityType";
import { useGetSelectedEntityId } from "./useGetSelectedEntityId";

const useGetSelectedWorkspaceTool = (refetch = false) => {
  const entityId = useGetSelectedEntityId();
  const type = useGetSelectedEntityType();
  return useGetToolFromQuery(entityId, {
    refetchOnMountOrArgChange: refetch,
    skip: type !== TOOL
  });
};

export default useGetSelectedWorkspaceTool;
