import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSnippetById } from "../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import { RootState } from "../../../../../stores/store";
import SnippetChip from "../../../chips/SnippetChip";

const SnippetViewerComponent = (props: NodeViewProps) => {
  const snippet = useSelector((state: RootState) =>
    selectSnippetById(state, props.node.attrs.snippetId)
  );

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        const snippetExists = !!snippet;
        if (snippetExists) {
          props.updateAttributes({
            textValue: snippet.snippetValue,
            name: snippet.name
          });
        } else {
          props.deleteNode(); // when an snippet gets deleted (like if the tool was deleted), we want to delete the snippets
        }
      } catch {}
    });
  }, [snippet?.name, snippet?.snippetValue]);

  return (
    <NodeViewWrapper className="inline-flex tip-tap-snippet-chip-component dontTriggerBubble">
      {snippet && (
        <SnippetChip
          text={snippet.name}
          onDelete={props.deleteNode}
          type="snippet"
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default SnippetViewerComponent;
