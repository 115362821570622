import React from "react";
import useGetCurrentAsset from "../../../../../hooks/assets/useGetCurrentAsset";
import useGetInputHtml from "../../../../../../../../utilities/components/textFields/workspace/hooks/useGetInputHtml";
import RemixButton from "../../../../../../../../utilities/components/icons/RemixButton";
import { CopyIcon } from "../../../../../../../../globalTheme/icons/icons";
import useCopyFromHtml from "../../../../../../../../utilities/components/copyContent/hooks/useCopyFromHtml";

// This doesn't copy tools, tvrs, need to update parseHtml, renderHtml in extensions
const CopyInputText = () => {
  const input = useGetCurrentAsset();
  const getInputHtml = useGetInputHtml();
  const copyFromHtml = useCopyFromHtml();

  const handleCopyClick = () => {
    if (input?.valueType === "string") {
      const html = getInputHtml(input);
      copyFromHtml(html);
    }
  };

  return <RemixButton text="Copy" icon={CopyIcon} onClick={handleCopyClick} />;
};

export default CopyInputText;
