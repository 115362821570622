import type { ExternalToolFE, TMarketplaceEntity } from "@toolflow/shared";

function convertExternalToolFEToMarketplaceEntity(
  tool: ExternalToolFE
): TMarketplaceEntity {
  return {
    id: tool._id.toString(),
    name: tool.name,
    description: tool.about || "",
    userId: tool.creator?._id.toString(),
    isFeatured: !!tool.isFeatured,
    isPopular: !!tool.isPopular,
    visibility: tool.visibility,
    userFavorited: !!tool.userFavorited,
    tag: tool.tag,
    estimatedCreditCost: tool.estimatedCreditCost,
    runCount: tool.runCount,
    updatedAt: tool.updatedAt,
    type: "tool"
  };
}

export default convertExternalToolFEToMarketplaceEntity;
