import {
  type BlockDataTypes,
  BlockTypes,
  type PromptBlockData,
  type ScraperBlockData,
  type SerpBlockData,
  type DeepgramBlockData,
  type TBlock,
  Block,
  type BlockDataTypesWithDefault
} from "@toolflow/shared";
import {
  dallEPromptInitialState,
  deepgramTranscribePromptInitialState,
  promptInitialState,
  scraperSettingsInitialState,
  serpSettingsInitialState
} from "../context/initialStates";
import {
  ConstantIcon,
  CrawlerIcon,
  ImageGenerationIcon,
  LogicIcon,
  SearchIcon,
  SpeechToTextFillIcon,
  TextGenerationIcon,
  ToolIcon
} from "../../../../../globalTheme/icons/icons";

type Item = Omit<TBlock, "id" | "position" | "data" | "parentNode"> & {
  data: BlockDataTypes;
};

export const blockConfig: Record<
  Block,
  { draggableItem: Item; icon: React.ElementType }
> = {
  [Block.logic]: {
    icon: LogicIcon,
    draggableItem: {
      type: BlockTypes.LogicBlockNode,
      data: {
        label: "Logic",
        type: Block.logic,
        logicArray: []
      }
    }
  },
  [Block.constant]: {
    icon: ConstantIcon,
    draggableItem: {
      type: BlockTypes.ConstantBlockNode,
      data: {
        label: "Constant",
        type: Block.constant,
        constant: ""
      }
    }
  },
  [Block.textGeneration]: {
    icon: TextGenerationIcon,
    draggableItem: {
      type: BlockTypes.PromptBlockNode,
      data: {
        type: "ChatGPT",
        label: "Text Output Name",
        ...promptInitialState
      } as PromptBlockData
    }
  },
  [Block.imageGeneration]: {
    icon: ImageGenerationIcon,
    draggableItem: {
      type: BlockTypes.PromptBlockNode,
      data: {
        type: "Dall-E2",
        label: "Image Output Name",
        ...dallEPromptInitialState
      } as PromptBlockData
    }
  },
  [Block.speechToText]: {
    icon: SpeechToTextFillIcon,
    draggableItem: {
      type: BlockTypes.DeepgramBlockNode,
      data: {
        ...deepgramTranscribePromptInitialState,
        type: "DeepgramTranscribe",
        label: "Deepgram transcript"
      } as DeepgramBlockData
    }
  },
  [Block.scraper]: {
    icon: CrawlerIcon,
    draggableItem: {
      type: BlockTypes.ScraperBlockNode,
      data: {
        ...scraperSettingsInitialState,
        type: "WebsiteContentCrawler",
        label: "Web Scraper"
      } as ScraperBlockData
    }
  },
  [Block.serp]: {
    icon: SearchIcon,
    draggableItem: {
      type: BlockTypes.SerpBlockNode,
      data: {
        ...serpSettingsInitialState,
        type: "serp",
        label: "Google Search Results"
      } as SerpBlockData
    }
  },
  [Block.embedded]: {
    icon: ToolIcon,
    draggableItem: {
      type: BlockTypes.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: "toolWithinTool",
        tool: null,
        inputMap: null
      }
    }
  }
};

export const blockHeaderIconDict: Record<
  BlockDataTypesWithDefault["type"],
  React.ElementType
> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.data.type,
      value.icon
    ])
  ),
  Anthropic: TextGenerationIcon, // we need to fix this -> the promptblock, anthropic, chatgpt, dall-e should be structured better
  default: TextGenerationIcon
} as Record<BlockDataTypesWithDefault["type"], React.ElementType>;
