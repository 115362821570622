import React from "react";
import UseCaseChipsContainer from "./useCases/UseCaseChipsContainer";
import EntityNotFound from "./EntityNotFound";
import ElementGrid from "./ElementGrid";
import type { TMarketplaceEntity } from "@toolflow/shared";
import CategoryTabs from "./categories/CategoryTabs";

export function SearchTools({
  entities,
  EntityElement
}: {
  entities: TMarketplaceEntity[];
  EntityElement: React.FC<{ entity: TMarketplaceEntity }>;
}) {
  return (
    <div>
      <CategoryTabs />
      <UseCaseChipsContainer />
      {entities?.length === 0 ? (
        <EntityNotFound />
      ) : (
        <ElementGrid
          marketplaceEntities={entities}
          EntityElement={EntityElement}
        />
      )}
    </div>
  );
}
