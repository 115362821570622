import type {
  RowUserInputDictType,
  WorkflowInputSheet
} from "@toolflow/shared";
import { useEffect } from "react";
import { useInputContext } from "../../../../../../../../../../utilities/contexts/InputContext";
import { useWorkspaceInputsFieldArrayContext } from "../../../../../../../contexts/WorkspaceInputsContext";
import useGetWorkspaceInputIndexById from "../../../../../../../hooks/useGetWorkspaceInputIndexById";

const useUpdateFormWithSheetData = ({
  sheetData
}: {
  sheetData: RowUserInputDictType[];
}) => {
  const { input } = useInputContext<WorkflowInputSheet>();
  const getInputIndex = useGetWorkspaceInputIndexById();
  const { update } = useWorkspaceInputsFieldArrayContext();
  const updateSheetDataInForm = (t: RowUserInputDictType[]) => {
    const newInput = {
      ...input,
      value: {
        ...input.value,
        sheetData: t
      }
    };
    update(getInputIndex(input.id), newInput);
  };

  useEffect(() => {
    updateSheetDataInForm(sheetData);
  }, [sheetData]);
};

export default useUpdateFormWithSheetData;
