import React, { useRef, useState } from "react";
import { MenuControlsContainer, MenuButton } from "mui-tiptap";
import ToolMenu from "./ToolMenu";
import FormattingMenuButtons from "./FormattingMenuButtons";
import useHandleClickOutsideMenuControls from "../../hooks/useHandleClickOutsideMenuControls";
import useHandleClickMenuControl from "../../hooks/richTextEditorHooks/useHandleClickMenuControl";
import useSetIsSlashWhenBubble from "../../hooks/useSetIsSlashWhenBubble";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import { CommandIcon } from "../../../../../../globalTheme/icons/icons";

function EditorMenuControls() {
  const { isSlash, setIsSlash, muiTipTapProps } = useMuiTipTapContext();

  const [hasDeletedSlash, setHasDeletedSlash] = useState(false);
  const { disableFormatting } = muiTipTapProps;

  const ref = useRef<HTMLDivElement>(null);
  const handleClick = useHandleClickMenuControl({
    hasDeletedSlash,
    setHasDeletedSlash
  });

  useSetIsSlashWhenBubble();
  useHandleClickOutsideMenuControls(ref);

  return (
    <>
      {!disableFormatting && (
        <div
          id="dontFocusOnClickId"
          ref={ref}
          onClick={handleClick}
          className={`${disableFormatting ? "w-300px" : ""}`}
        >
          {/* The flicker happens because of React StrictMode. Should be fine in prod */}

          <MenuControlsContainer className="justify-center" debounced>
            <MenuButton
              disabled={isSlash}
              tooltipLabel="Use command"
              onClick={(event) => {
                event.stopPropagation();
                setIsSlash(true);
                setHasDeletedSlash(true); // we need this in case someone highlights, then clicks use command -> dont want to delete the character
              }}
            >
              <CommandIcon />
            </MenuButton>

            <FormattingMenuButtons />
          </MenuControlsContainer>
        </div>
      )}
      {isSlash && <ToolMenu />}
    </>
  );
}

export default EditorMenuControls;
