import { Box, useMediaQuery, type Theme } from "@mui/material";
import React from "react";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";
import AssetTOCPositioningWrapper from "../../assetTableOfContents/AssetTOCPositioningWrapper";
import AssetHeaderContainer from "./AssetHeader";
import AssetTextFieldScrollWrapper from "./AssetTextFieldScrollWrapper";
import useGetElementHeight from "./hooks/useGetElementHeight";

function SmTextAssetInner({ className }: { className?: string }) {
  const { ref, height } = useGetElementHeight();
  return (
    <div className={classNames("w-100-percent", className)}>
      <div ref={ref}>
        <AssetHeaderContainer />
      </div>
      <Box
        className="flex pos-relative h-100-percent"
        sx={{ maxHeight: `calc(100% - ${height}px)` }}
      >
        <Box className="pos-relative p-v-16px">
          <AssetTextFieldScrollWrapper />
        </Box>
        <AssetTOCPositioningWrapper />
      </Box>
    </div>
  );
}

function LgTextAssetInner({ className }: { className?: string }) {
  const { ref, height } = useGetElementHeight();
  return (
    <div className={classNames("w-100-percent flex", className)}>
      <div className={"w-100-percent m-h-auto parent-hover"}>
        <div ref={ref}>
          <AssetHeaderContainer />
        </div>
        <Box
          className="pos-relative p-v-16px h-100-percent h-m-auto"
          sx={{
            maxHeight: `calc(100% - ${height}px)`
          }}
        >
          <AssetTextFieldScrollWrapper />
        </Box>
      </div>
      <AssetTOCPositioningWrapper />
    </div>
  );
}

export function TextAssetSizeSwitch({ className }: { className?: string }) {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return isSmall ? (
    <SmTextAssetInner className={className} />
  ) : (
    <LgTextAssetInner className={className} />
  );
}
